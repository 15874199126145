import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function confirmDialog(titulo, positivo = () => {}, negativo = () => {}) {

	/*confirmAlert({
		customUI: ({ onClose }) => {
			return (
				<div className='custom-ui'>
					<h4>{titulo}</h4>
					<button onClick={() => {
						negativo();
						onClose();
					}}>Não</button>
					<button onClick={() => {
						positivo();
						onClose();
					}}>Sim</button>
				</div>
			);
		}
	});*/

	if (window.confirm(titulo)) {
		positivo();
	} else {
		negativo();
	}

}
