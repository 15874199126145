import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";

import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInputNumber from "../common/form/labelAndInputNumber";
import LabelAndInputMask from "../common/form/labelAndInputMask";
import Select from "../common/form/select";

import { setModoTela, initFormAgendamento as initForm } from "./demandaContratoActions";

class DemandaAgendamentoForm extends Component {
    state = {};

    componentWillMount() {}

    render() {
        let readOnly = this.props.excluir || this.props.visualizar ? "readOnly" : "";

        let mes = parseInt(this.props.formularioValues.mes_inicio);
        let listaCicloRural = [];
        if (mes) {
            const predicado = (item) => item.id == mes;
            for (let i = 0; i < 12; i++) {
                const objetoMes = this.props.listaMeses.find(predicado);
                listaCicloRural.push(`${objetoMes.valor}`);
                mes = mes == 12 ? 1 : mes + 1;
            }
        }

        return (
            <>
                <ContentCard>
                    <Form event={this.props.handleSubmit}>
                        <ContentCardBody>
                            <br />
                            <Row>
                                <Field
                                    name="id_cliente"
                                    component={Select}
                                    options={this.props.listaCliente}
                                    label="Cliente"
                                    cols="12 12 12 12"
                                    placeholder="Selecione o cliente"
                                    readOnly={"readOnly"}
                                />
                            </Row>
                            <Row>
                                <Field
                                    name="id_unidade_consumidora"
                                    component={Select}
                                    options={this.props.listaUnidadeConsumidora.filter(
                                        (item) =>
                                            item.id_cliente == this.props.formularioValues.id_cliente &&
                                            [1,4].includes(parseInt(item.id_grupo_faturamento))
                                    )}
                                    label="Unidade Consumidora"
                                    cols="12 12 12 12"
                                    placeholder="Selecione a unidade consumidora"
                                    readOnly={"readOnly"}
                                />
                            </Row>
                            <Row>
                                <Field
                                    name="competencia_inicio_vigencia"
                                    component={LabelAndInputMask}
                                    label="Início de Vigência"
                                    placeholder="Informe a competência"
                                    cols="12 12 12 12"
                                    mask="99/9999"
                                    readOnly={readOnly}
                                    autoFocus={true}
                                />
                            </Row>

                            <label>Alteração de Contrato</label>

                            <Row>
                                {listaCicloRural.map((cicloRural, i) => {
                                    return (
                                        <Field
                                            key={i}
                                            name={`valor_${i + 1}`}
                                            component={LabelAndInputNumber}
                                            label={cicloRural}
                                            placeholder="Informe a demanda"
                                            cols="12 6 4 3"
                                            casas={2}
                                            readOnly={readOnly}
                                        />
                                    );
                                })}
                            </Row>
                        </ContentCardBody>
                        <ContentCardFooter>
                            <Row alignCenter>
                                <Grid cols="6 6 4 3">
                                    {this.props.excluir ? (
                                        <Button text="Excluir" submit type={"danger"} icon={"fa fa-trash"} />
                                    ) : (
                                        <Button
                                            text={"Salvar"}
                                            submit
                                            type={"success"}
                                            icon={"fa fa-check"}
                                            disabled={this.props.visualizar}
                                        />
                                    )}
                                </Grid>
                                <Grid cols="6 6 4 3">
                                    <Button
                                        text="Voltar"
                                        type={"warning"}
                                        icon={"fa fa-chevron-left"}
                                        event={() => this.props.setModoTela("cadastro")}
                                    />
                                </Grid>
                            </Row>
                        </ContentCardFooter>
                    </Form>
                </ContentCard>
            </>
        );
    }
}

DemandaAgendamentoForm = reduxForm({
	form: "demandaAgendamentoForm",
	destroyOnUnmount: false,
})(DemandaAgendamentoForm);
const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues("demandaAgendamentoForm")(state),
	registroAgendamento: state.demandaContrato.registro,
	listaCliente: state.demandaContrato.listaCliente,
	listaUnidadeConsumidora: state.demandaContrato.listaUnidadeConsumidora,
	listaMeses: state.demandaContrato.listaMeses,
	listaDemandaCompetencia: state.demandaContrato.listaDemandaCompetencia,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setModoTela,
			initForm,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(DemandaAgendamentoForm);
