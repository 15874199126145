import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";

import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import ButtonTable from "../common/table/buttonTable";
import Button from "../common/button/button";
import Form from "./clienteForm";

import { setModoTela, initFormAnexo, getListaAnexo } from "./clienteActions";

class ClienteAnexo extends Component {
	state = {
		linhaSelecionada: null,
	};

	componentWillMount() {
		this.props.getListaAnexo(this.props.registro.id);
	}

	render() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols="6 6 4 2">
							<Button
								text="Adicionar"
								type={"success"}
								icon={"fa fa-plus"}
								event={() => {
									this.props.setModoTela("cadastroAnexo", {
										...this.props.registro,
									});
									this.props.initFormAnexo({
										id_cliente: this.props.registro.id,
									});
								}}
							/>
						</Grid>
						<Grid cols="6 6 4 2">
							<Button text="Voltar" type={"warning"} icon={"fa fa-chevron-left"} event={() => this.props.setModoTela("lista")} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th colSpan={1}>Nome</Th>
								<Th colSpan={1}></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.listaAnexo.map((item) => (
								<Tr
									key={item.id}
									className={`${this.state.linhaSelecionada == item.id ? "hover" : ""}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
								>
									<Td>{item.nome}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={"primary"}
											icon={"fas fa-file-download"}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												const linkSource = item.arquivo;
												const downloadLink = document.createElement("a");
												const fileName = `${item.nome}.${item.arquivo.split("/")[1].split(";")[0]}`;
												downloadLink.href = linkSource;
												downloadLink.download = fileName;
												downloadLink.click();
											}}
										/>
										<ButtonTable
											type={"danger"}
											icon={"fas fa-trash-alt"}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela("exclusaoAnexo", {
													...item,
												});
												this.props.initFormAnexo({
													...item,
												});
											}}
										/>
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}
}

const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	registro: state.cliente.registro,
	modoTela: state.cliente.modoTela,
	listaAnexo: state.cliente.listaAnexo,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ setModoTela, initFormAnexo, getListaAnexo }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ClienteAnexo);
