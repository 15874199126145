import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ContentCard from "../../common/template/contentCard";
import ContentCardHeader from "../../common/template/contentCardHeader";
import ContentCardBody from "../../common/template/contentCardBody";
import Row from "../../common/layout/row";
import Grid from "../../common/layout/grid";
import Button from "../../common/button/button";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { setError } from "../../common/toast/toast.jsx";
import {
    getListaGraficoCustoDemandaEnergiaConsumida,
    getListaGraficoConsumoForaPonta,
    getListaGraficoConsumoPonta,
    getListaGraficoConsumoPorHorarioMensal,
    getListaGraficoConsumoPorHorarioAnual,
    getListaGraficoCustoPorHorarioAnual,
    getListaGraficoConsumoReservado,
    setFiltro,
} from "../analiseActions";
import { montarGraficoPieAnual } from "../../common/graficos/montarGraficoPieAnual.js";
import { montarGraficoClusteredColumnMensal } from "../../common/graficos/montarGraficoClusteredColumnMensal.js";
import { montarGraficoGroupColumn } from "../../common/graficos/montarGraficoGroupColumn.js";
import { montarGraficoClusteredColumnMensalSafra } from "../../common/graficos/montarGraficoClusteredColumnMensalSafra.js";
import * as am4core from "@amcharts/amcharts4/core";
import { adicionarPontosMilhar } from "../../utils/adicionarPontosMilhar.js";

function analiseAproveitamentoConsumoHorario(props) {
    const {
        state,
        filtro,
        setAguardando,
        listar,
        buscarPropriedadeUcFiltrada,
        listaUnidadeConsumidora,
        listaGraficoCustoDemandaEnergiaConsumida,
        listaGraficoConsumoReservado,
        listaGraficoConsumoForaPonta,
        listaGraficoConsumoPonta,
        listaGraficoConsumoHorarioMensal,
        listaGraficoCustoHorarioAnual,
        listaGraficoConsumoHorarioAnual,
        getListaGraficoCustoDemandaEnergiaConsumida,
        getListaGraficoConsumoReservado,
        getListaGraficoConsumoPonta,
        getListaGraficoConsumoPorHorarioMensal,
        getListaGraficoConsumoPorHorarioAnual,
        getListaGraficoCustoPorHorarioAnual,
        getListaGraficoConsumoForaPonta,
        setFiltro,
    } = props;

    const [graficoCustoHorarioAnual, setGraficoCustoHorarioAnual] = useState(null);
    const [graficoConsumoHorarioAnual, setGraficoConsumoHorarioAnual] = useState(null);
    const [graficoConsumoHorarioMensal, setGraficoConsumoHorarioMensal] = useState(null);
    const [graficoConsumoPontaMensal, setGraficoConsumoPontaMensal] = useState(null);
    const [graficoConsumoForaPontaMensal, setGraficoConsumoForaPontaMensal] = useState(null);
    const [graficoConsumoReservadoMensal, setGraficoConsumoReservadoMensal] = useState(null);
    const [graficoCustoDemandaEnergiaConsumidaAnual, setGraficoCustoDemandaEnergiaConsumidaAnual] = useState(null);

    useEffect(() => {
        atualizarTodosGraficos();
        return desmontarTodosGraficos;
    }, []);

    useEffect(() => {
        const buscarDados = async () => {
            if (!state.aguardando) return;

            await Promise.all([
                getListaGraficoCustoPorHorarioAnual(),
                getListaGraficoConsumoPorHorarioAnual(),
                getListaGraficoConsumoPorHorarioMensal(),
                getListaGraficoConsumoPonta(),
                getListaGraficoConsumoForaPonta(),
                getListaGraficoConsumoReservado(),
                getListaGraficoCustoDemandaEnergiaConsumida(),
            ]);

            setAguardando(false);
        };

        buscarDados();
        if (!state.aguardando) {
            atualizarTodosGraficos();
        }
    }, [state.aguardando]);

    function atualizarTodosGraficos() {
        atualizarListaGraficoCustoPorHorarioAnual();
        atualizarListaGraficoConsumoPorHorarioAnual();
        atualizarListaGraficoConsumoPorHorarioMensal();
        atualizarListaGraficoConsumoPonta();
        atualizarListaGraficoConsumoForaPonta();
        atualizarListaGraficoConsumoReservado();
        atualizarListaGraficoCustoDemandaEnergiaConsumida();
    }

    function desmontarTodosGraficos() {
        if (graficoCustoHorarioAnual) graficoCustoHorarioAnual.dispose();
        if (graficoConsumoHorarioAnual) graficoConsumoHorarioAnual.dispose();
        if (graficoConsumoHorarioMensal) graficoConsumoHorarioMensal.dispose();
        if (graficoConsumoPontaMensal) graficoConsumoPontaMensal.dispose();
        if (graficoConsumoForaPontaMensal) graficoConsumoForaPontaMensal.dispose();
        if (graficoConsumoReservadoMensal) graficoConsumoReservadoMensal.dispose();
        if (graficoCustoDemandaEnergiaConsumidaAnual) graficoCustoDemandaEnergiaConsumidaAnual.dispose();
    }
    function mapearListaGraficoColunasAgrupadas(lista) {
        const listaMapeada = [];
        const nomeColuna = filtro.horario_ano_safra ? "ano_inicio_fim_safra" : "ano";
        const anos = [];

        try {
            //monta uma lista com todos os anos existentes em todos meses
            for (const registro of lista) {
                const listaAnoValor = registro.itens.filter((item) => item[nomeColuna]); //remove registro com ano nulo
                for (const item of listaAnoValor) {
                    const ano = item[nomeColuna];
                    if (!anos.includes(ano)) {
                        anos.push(ano);
                    }
                }
            }
            anos.sort((a, b) => {
                return filtro.horario_ano_safra ? parseInt(a.split("/")[0]) - parseInt(b.split("/")[0]) : parseInt(a) - parseInt(b);
            });
            //percorre todos os meses
            for (const registro of lista) {
                //se encontrar o ano do mes na lista de anos, pega o respectivo valor, senao define o valor zero para o ano
                const colunas = { mes: registro.mes };
                for (const ano of anos) {
                    const anoExisteNoMes = registro.itens.find((item) => item[nomeColuna] == ano);
                    if (anoExisteNoMes && parseFloat(anoExisteNoMes.valor)) {
                        colunas[ano] = parseFloat(anoExisteNoMes.valor);
                    } else {
                        colunas[ano] = 0;
                    }
                }
                listaMapeada.push(colunas);
            }
            return {
                dados: listaMapeada,
                valuesAndNames: anos.map((item) => ({ value: item, name: item })),
            };
        } catch (erro) {
            console.log(erro);
            setError("Erro ao gerar gráficos de consumo por horário");
            return {
                dados: [],
                valuesAndNames: [],
            };
        }
    }

    function atualizarListaGraficoCustoPorHorarioAnual() {
        const graficoCustoHorarioAnualAtualizado = montarGraficoPieAnual(state, graficoCustoHorarioAnual, "graficoCustoHorarioAnual", listaGraficoCustoHorarioAnual, "Custo Total por Horário (R$)", "R$", "");
        setGraficoCustoHorarioAnual(graficoCustoHorarioAnualAtualizado);
    }

    function atualizarListaGraficoConsumoPorHorarioAnual() {
        const graficoConsumoHorarioAnualAtualizado = montarGraficoPieAnual(state, graficoConsumoHorarioAnual, "graficoConsumoHorarioAnual", listaGraficoConsumoHorarioAnual, "Consumo Total por Horário (kWh)", "", "kWh");
        setGraficoConsumoHorarioAnual(graficoConsumoHorarioAnualAtualizado);
    }

    function atualizarListaGraficoConsumoPorHorarioMensal() {
        const funcaoMontarGrafico = filtro.horario_ano_safra ? montarGraficoClusteredColumnMensalSafra : montarGraficoClusteredColumnMensal;
        const graficoConsumoHorarioMensalAtualizado = funcaoMontarGrafico(
            state,
            graficoConsumoHorarioMensal,
            "graficoConsumoHorarioMensal",
            listaGraficoConsumoHorarioMensal.map((item) => ({
                mes: item.mes,
                anos: item.tipos.map((tipo) => ({
                    ano: tipo.tipo,
                    valor: tipo.valor,
                })),
            })),
            "Consumo Mensal por Horário (kWh)",
            "",
            "kWh"
        );
        setGraficoConsumoHorarioMensal(graficoConsumoHorarioMensalAtualizado);
    }

    function atualizarListaGraficoConsumoPonta() {
        const { dados, valuesAndNames } = mapearListaGraficoColunasAgrupadas(listaGraficoConsumoPonta);
        const graficoConsumoPontaMensalAtualizado = montarGraficoGroupColumn({
            state,
            component: graficoConsumoPontaMensal,
            nome: "graficoConsumoPontaMensal",
            dados,
            category: "mes",
            valuesAndNames,
            titulo: "Consumo Ponta Mensal (kWh)",
            prefixo: "",
            sufixo: "kWh",
            dimensions: "3D",
            mostrarLegenda: true,
            chartBackground: "#30303d",
        });
        setGraficoConsumoPontaMensal(graficoConsumoPontaMensalAtualizado);
    }

    function atualizarListaGraficoConsumoForaPonta() {
        const { dados, valuesAndNames } = mapearListaGraficoColunasAgrupadas(listaGraficoConsumoForaPonta);
        const graficoConsumoForaPontaMensalAtualizado = montarGraficoGroupColumn({
            state,
            component: graficoConsumoForaPontaMensal,
            nome: "graficoConsumoForaPontaMensal",
            dados,
            category: "mes",
            valuesAndNames,
            titulo: "Consumo Fora Ponta Mensal (kWh)",
            prefixo: "",
            sufixo: "kWh",
            dimensions: "3D",
            mostrarLegenda: true,
            chartBackground: "#30303d",
        });
        setGraficoConsumoForaPontaMensal(graficoConsumoForaPontaMensalAtualizado);
    }

    function atualizarListaGraficoConsumoReservado() {
        const { dados, valuesAndNames } = mapearListaGraficoColunasAgrupadas(listaGraficoConsumoReservado);
        const graficoConsumoReservadoMensalAtualizado = montarGraficoGroupColumn({
            state,
            component: graficoConsumoReservadoMensal,
            nome: "graficoConsumoReservadoMensal",
            dados,
            category: "mes",
            valuesAndNames,
            titulo: "Consumo Reservado Mensal (kWh)",
            prefixo: "",
            sufixo: "kWh",
            dimensions: "3D",
            mostrarLegenda: true,
            chartBackground: "#30303d",
        });
        setGraficoConsumoReservadoMensal(graficoConsumoReservadoMensalAtualizado);
    }

    function atualizarListaGraficoCustoDemandaEnergiaConsumida() {
        const creditos = { "Crédito Solar": 0, "Devoluções e Ressarcimentos": 0, "Energia Consumida (sem descontar)": 0 };
        let listaFiltrada = [...listaGraficoCustoDemandaEnergiaConsumida].map((item) => {
            const tiposFiltrados = item.tipos.filter((itemTipo) => {
                //separa esses itens para informar que foram descontados do total da Energia Consumida
                if (itemTipo.tipo === "Devoluções e Ressarcimentos" || itemTipo.tipo === "Crédito Solar") {
                    creditos[itemTipo.tipo] += parseFloat(itemTipo.valor) || 0;
                    return false;
                } else if (itemTipo.tipo === "Energia Consumida") {
                    creditos["Energia Consumida (sem descontar)"] += parseFloat(itemTipo.valor_original) || 0;
                    return true;
                } else {
                    return true;
                }
            });
            return {
                ...item,
                tipos: tiposFiltrados,
            };
        });
        if (filtro.unidades_consumidoras.length == 1) {
            const ucSelecionada = listaUnidadeConsumidora.find((uc) => uc.id == filtro.unidades_consumidoras[0].value);

            const grupoA = [1,4].includes(parseInt(ucSelecionada.id_grupo_faturamento));
            const modalidadeTarifariaVerde = ucSelecionada.id_modalidade_tarifaria == 1;
            const modalidadeTarifariaAzul = ucSelecionada.id_modalidade_tarifaria == 2;
            const geradora = ucSelecionada.geracao;

            listaFiltrada = listaFiltrada.map((item) => {
                const tiposFiltrados = item.tipos.filter((itemTipo) => {
                    if (grupoA && modalidadeTarifariaVerde && !geradora) {
                        // Unidade Grupo A Verde sem Geraçao: Demanda Fora ponta
                        return !itemTipo.tipo.includes("Demanda") || itemTipo.tipo === "Demanda Fora Ponta";
                    } else if (grupoA && modalidadeTarifariaAzul && !geradora) {
                        // Unidade Grupo A azul Sem Geraçao: Demanda fora Ponta e demanda Ponta
                        return !itemTipo.tipo.includes("Demanda") || itemTipo.tipo.includes("Ponta");
                    } else if (grupoA && modalidadeTarifariaVerde && geradora) {
                        // Unidade Grupo A Verde com Geraçao: Demanda Fora ponta e Demanda Geraçao
                        return !itemTipo.tipo.includes("Demanda") || itemTipo.tipo === "Demanda Fora Ponta" || itemTipo.tipo === "Demanda de Geração";
                    } else if (grupoA && modalidadeTarifariaAzul && geradora) {
                        // Unidade Grupo A azul com Geraçao: Demanda fora Ponta e demanda Ponta e Demanda Geraçao
                        return true; //retorna todos tipos
                    } else {
                        return true; //retorna todos tipos
                    }
                });
                return {
                    ...item,
                    tipos: tiposFiltrados,
                };
            });
        }

        const cores = {
            "Energia Consumida": am4core.color("#0bbd4f"),
            "Demanda Ponta": am4core.color("#f2ff40"),
            "Demanda Fora Ponta": am4core.color("#3357FF"),
            "Demanda de Geração": am4core.color("#ff8838"),
            "Demais Custos": am4core.color("#e43d3d"),
            Bandeiras: am4core.color("#5afd78"),
        };

        const msgValorOriginal = ` R$ ${adicionarPontosMilhar(creditos["Energia Consumida (sem descontar)"])}`;
        const msgCreditos = creditos["Crédito Solar"] ? ` - R$ ${adicionarPontosMilhar(creditos["Crédito Solar"])} (Crédito Solar)` : "";
        const msgDevolucoes = creditos["Devoluções e Ressarcimentos"] ? ` - R$ ${adicionarPontosMilhar(creditos["Devoluções e Ressarcimentos"])} (Devoluções e Ressarcimentos)` : "";

        const existeDescontos = msgCreditos || msgDevolucoes;
        const graficoCustoDemandaEnergiaConsumidaAnualAtualizado = montarGraficoPieAnual(
            state,
            graficoCustoDemandaEnergiaConsumidaAnual,
            "graficoCustoDemandaEnergiaConsumidaAnual",
            listaFiltrada,
            "Demanda X Energia Consumida (R$)",
            "R$",
            "",
            true, //ocultarLegenda
            85,
            cores,
            { "Energia Consumida": existeDescontos ? `\n Cálculo: ${msgValorOriginal}${msgCreditos}${msgDevolucoes}` : "" } //sufixo para tipo específico, para exibir o cálculo
        );
        setGraficoCustoDemandaEnergiaConsumidaAnual(graficoCustoDemandaEnergiaConsumidaAnualAtualizado);
    }

    return (
        <div>
            <ContentCard
                style={{
                    color: "#fff",
                    padding: 8,
                    display: true /*!buscarPropriedadeUcFiltrada() || buscarPropriedadeUcFiltrada("irrigante")*/ ? "" : "none",
                }}
            >
                <ContentCardHeader style={{ backgroundColor: "#fff" }}>
                    <Row>
                        <Grid cols="6 4 3 2">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                <DatePicker
                                    variant="inline"
                                    openTo="year"
                                    views={["year", "month"]}
                                    label="De"
                                    autoOk
                                    value={
                                        filtro.competencia_inicial_horario
                                            ? `${String(filtro.competencia_inicial_horario).substring(0, 4)}/${String(filtro.competencia_inicial_horario).substring(
                                                  4,
                                                  6
                                              )}/01 12:00:00`
                                            : null
                                    }
                                    onChange={(value) => {
                                        setFiltro({
                                            ...filtro,
                                            competencia_inicial_horario: value ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`) : null,
                                        });
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid cols="6 4 3 2">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                <DatePicker
                                    variant="inline"
                                    openTo="year"
                                    views={["year", "month"]}
                                    label="Até"
                                    autoOk
                                    value={
                                        filtro.competencia_final_horario
                                            ? `${String(filtro.competencia_final_horario).substring(0, 4)}/${String(filtro.competencia_final_horario).substring(4, 6)}/01 12:00:00`
                                            : null
                                    }
                                    onChange={(value) => {
                                        setFiltro({
                                            ...filtro,
                                            competencia_final_horario: value ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`) : null,
                                        });
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid cols="12 4 3 2" style={{ paddingTop: 10 }}>
                            <Button
                                text="Limpar Período"
                                type={"primary"}
                                icon={"fa fa-minus"}
                                event={() => {
                                    setFiltro({
                                        ...filtro,
                                        competencia_inicial_horario: null,
                                        competencia_final_horario: null,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid cols="6 4 3 2" style={{ paddingTop: 10 }}>
                            <Button text="Atualizar" type={"primary"} icon={"fa fa-sync"} event={listar} />
                        </Grid>
                    </Row>
                </ContentCardHeader>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            overflowX: "scroll",
                            justifyContent: "space-around",
                        }}
                    >
                        <div id="graficoCustoHorarioAnual" style={{ minWidth: 550, paddingBlock: 20, height: "300px" }}></div>

                        <div id="graficoConsumoHorarioAnual" style={{ minWidth: 550, paddingBlock: 20, height: "300px" }}></div>

                        <div id="graficoCustoDemandaEnergiaConsumidaAnual" style={{ minWidth: 550, paddingBlock: 20, height: "300px" }}></div>
                    </div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <Row>
                        <Grid cols="12 12 12 12">
                            <div id="graficoConsumoHorarioMensal" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                        </Grid>
                    </Row>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoConsumoPontaMensal" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoConsumoForaPontaMensal" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard
                style={{
                    color: "#fff",
                    padding: 8,
                    display: !buscarPropriedadeUcFiltrada() || buscarPropriedadeUcFiltrada("irrigante") ? "" : "none",
                }}
            >
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoConsumoReservadoMensal" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                </ContentCardBody>
            </ContentCard>
        </div>
    );
}
const mapStateToProps = (state) => ({
	filtro: state.analise.filtro,
	listaGraficoCustoDemandaEnergiaConsumida: state.analise.listaGraficoCustoDemandaEnergiaConsumida,
	listaGraficoConsumoReservado: state.analise.listaGraficoConsumoReservado,
	listaGraficoConsumoForaPonta: state.analise.listaGraficoConsumoForaPonta,
	listaGraficoConsumoPonta: state.analise.listaGraficoConsumoPonta,
	listaGraficoConsumoHorarioMensal: state.analise.listaGraficoConsumoHorarioMensal,
	listaGraficoCustoHorarioAnual: state.analise.listaGraficoCustoHorarioAnual,
	listaUnidadeConsumidora: state.analise.listaUnidadeConsumidora,
	listaGraficoConsumoHorarioAnual: state.analise.listaGraficoConsumoHorarioAnual,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getListaGraficoCustoDemandaEnergiaConsumida,
			getListaGraficoConsumoReservado,
			getListaGraficoConsumoForaPonta,
			getListaGraficoConsumoPonta,
			getListaGraficoConsumoPorHorarioMensal,
			getListaGraficoConsumoPorHorarioAnual,
			getListaGraficoCustoPorHorarioAnual,
			setFiltro,
		},
		dispatch
	);
const AnaliseAproveitamentoConsumoHorario = connect(mapStateToProps, mapDispatchToProps)(analiseAproveitamentoConsumoHorario);
export { AnaliseAproveitamentoConsumoHorario };
