import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import "react-circular-progressbar/dist/styles.css";
import InfoBox from "../common/template/infoBox";
import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import Tr from "../common/table/tr";
import Select from "../common/form/select";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import locale from "@amcharts/amcharts4/lang/pt_BR.js";

import graphMonitor from "../assets/images/graphMonitor.png";
import plugInversor from "../assets/images/plugInversor.png";
import consumedEnergy from "../assets/images/consumedEnergy.png";
import calendarCheck from "../assets/images/calendar-check.svg";
import capacitor from "../assets/images/capacitor.png";
import volts from "../assets/images/volts.png";

import {
    setFiltro,
    setAguardando,
    setModoTela,
    setDataInicialGrafico,
    setDataFinalGrafico,
    getDemandaContagem,
    carregarListas,
    getListaGraficoDia,
    getListaGraficoTodasIntegracoesAgrupadasPorHora,
} from "./monitoramentoConsumoActions";
import { adicionarPontosMilhar } from "../utils/adicionarPontosMilhar";
import { Loading } from "../common/loading/loading";
import GraficoMonitoramentoConsumo from "./graficoMonitoramentoConsumo";
import DateFormat from "../common/dateFormat/DateFormat";

function MonitoramentoConsumo(props) {
    const {
        usuarioLogado,
        aguardando,
        modoTela,
        filtro,
        registro,
        listaCliente,
        listaUnidadeConsumidora,
        listaDemandaContagem,
        listaDemandaContratoTipo,
        dataInicialGrafico,
        dataFinalGrafico,
        listaGraficoDia,
        setFiltro,
        setModoTela,
        setDataInicialGrafico,
        setDataFinalGrafico,
        getDemandaContagem,
        carregarListas,
        getListaGraficoDia,
        getListaGraficoTodasIntegracoesAgrupadasPorHora,
    } = props;

    const [grafico, setGrafico] = useState(null);
    const [nomeGraficoAtivo, setNomeGraficoAtivo] = useState("");
    const [abaSazonalSelcionada, setAbaSazonalSelcionada] = useState(true);

    useEffect(() => {
        carregarListas();
        getDemandaContagem();
        
        return desmontarTodosGraficos;
    }, []);

    function desmontarTodosGraficos() {
        if (grafico) {
            console.log("desmontando grafico");
            grafico.dispose();
        }
    }

    useEffect(() => {
        const exibirAbaSazonal = listaDemandaContagem.find((item) => item.contrato_demanda_sazonal);
        const exibirAbaNaoSazonal = listaDemandaContagem.find((item) => !item.contrato_demanda_sazonal);

        if (exibirAbaSazonal && !exibirAbaNaoSazonal && !abaSazonalSelcionada) {
            setAbaSazonalSelcionada(true);
        }

        if (!exibirAbaSazonal && exibirAbaNaoSazonal && abaSazonalSelcionada) {
            setAbaSazonalSelcionada(false);
        }

    }, [listaDemandaContagem, abaSazonalSelcionada]);

    useEffect(() => {
        atualizarListasGraficos(dataInicialGrafico, dataFinalGrafico);
    }, [dataInicialGrafico, dataFinalGrafico, registro]);

    useEffect(() => {
        if (aguardando) return;
        //só monta se já tiver a div com o id de referencia renderizado
        switch (nomeGraficoAtivo) {
            case "graficoDia":
                return montarGraficoDia("graficoDia", listaGraficoDia);
            default:
                return;
        }
    }, [aguardando, nomeGraficoAtivo, JSON.stringify(listaGraficoDia)]);

    return (
        <div>
            {aguardando ? <Loading /> : null}

            <Content>
                {modoTela === "lista" ? listar() : null}
                {modoTela.includes("grafico") ? exibirGrafico(modoTela) : null}
            </Content>
        </div>
    );

    function listarUCsFiltradas() {
        return listaUnidadeConsumidora.filter((item) => {
            const filtroClienteValidado = !filtro.id_cliente || item.id_cliente == filtro.id_cliente;
            return filtroClienteValidado && [1,4].includes(parseInt(item.id_grupo_faturamento));
        });
    }

    function montarTooltipoPeriodoTeste({ periodoTeste, item, props }) {
        if (!periodoTeste.length) return null;
        const mesInicio = "" + JSON.parse(periodoTeste[0]).mes;
        const anoInicio = "" + JSON.parse(periodoTeste[0]).ano;
        const mesFim = "" + JSON.parse(periodoTeste[periodoTeste.length - 1]).mes;
        const anoFim = "" + JSON.parse(periodoTeste[periodoTeste.length - 1]).ano;
        const inicioTeste = mesInicio + "/" + anoInicio;
        const fimTeste = mesFim + "/" + anoFim;

        return (
            <div
                style={{
                    color: "#FFF",
                    backgroundColor: "#257bfb",
                    padding: "10px 8px",
                    borderRadius: "0.3rem",
                    fontSize: "0.7rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minWidth: "fit-content",
                    textWrap: "nowrap",
                    flexGrow: 1,
                    margin: 3,
                }}
                title={`Período de Testes ativo: Início ${inicioTeste} - Fim ${fimTeste}`}
            >
                <b>PERÍODO TESTE</b>
            </div>
        );
    }

    function montarTooltipAgendamento({ item, props }) {
        if (!item.agendamentos.length) return null;

        return (
            <div
                style={{
                    backgroundColor: "#f1cf1a",
                    color: "#111",
                    padding: "10px 8px",
                    borderRadius: "0.3rem",
                    fontSize: "0.7rem",
                    display: "flex",
                    justifyContent: "center",
                    textWrap: "nowrap",
                    minWidth: "fit-content",
                    flexGrow: 1,
                    margin: 3,
                }}
                title={`Agendamento de Alteração de Demanda:\n${item.agendamentos.map((agendamentoDemanda) => agendamentoDemanda.mensagem_tooltip)}`}
            >
                <b>AGENDAMENTO</b>
            </div>
        );
    }
    function listar() {
        desmontarTodosGraficos();
        // VALORES DOS CARDS DO CABEÇALHO --------------------------------------
        const totalUsinas = 0;
        const potenciaTotalInstalada = (0).toFixed(2).replace(".", ",") + " MW";

        const energiaTotalDia = (0).toFixed(2).replace(".", ",") + " MWh";
        const energiaTotalMes = (0).toFixed(2).replace(".", ",") + " MWh";
        const totalUsinasOperandoNormalmente = 0;
        const totalUsinasSemComunicacao = 0;

        const totalUsinasProducaoOtima = 0;

        const totalUsinasProducaoModerada = 0;
        const totalUsinasProducaoBaixa = 0;
        //----------------------------------------------------------------------

        //CARDS

        const cardsTotalizadores = (
            <>
                <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                    <InfoBox text="Número de Unidades" number={totalUsinas} style={{ height: window.innerWidth < 992 ? null : 50, fontSize: "0.9rem" }} minHeight={50}>
                        <img
                            src={graphMonitor}
                            style={{
                                height: 30,
                                width: 30,
                                filter: "brightness(0) invert(1)",
                            }}
                            alt=""
                        />
                    </InfoBox>
                </Grid>
                <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                    <InfoBox text="Potência Total Instalada" number={potenciaTotalInstalada} style={{ height: window.innerWidth < 992 ? null : 50, fontSize: "0.9rem" }} minHeight={50}>
                        <img
                            src={plugInversor}
                            style={{
                                height: 40,
                                width: 40,
                                filter: "brightness(0) invert(1)",
                            }}
                            alt=""
                        />
                    </InfoBox>
                </Grid>
                <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                    <InfoBox text="Energia Total Consumida no Dia" number={energiaTotalDia} style={{ height: window.innerWidth < 992 ? null : 50, fontSize: "0.9rem" }} minHeight={50}>
                        <img
                            src={consumedEnergy}
                            style={{
                                height: 40,
                                width: 40,
                                filter: "brightness(0) invert(1)",
                            }}
                            alt=""
                        />
                    </InfoBox>
                </Grid>
                <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                    <InfoBox text="Energia Total Consumida no Mês" number={energiaTotalMes} style={{ height: window.innerWidth < 992 ? null : 50, fontSize: "0.9rem" }} minHeight={50}>
                        <img
                            src={calendarCheck}
                            style={{
                                height: 30,
                                width: 30,
                                filter: "brightness(0) invert(1)",
                            }}
                            alt=""
                        />
                    </InfoBox>
                </Grid>
            </>
        );

        const cardsStatusProducao = (
            <>
                <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                    <InfoBox icon="fas fa-check" text="Operando Normalmente" number={totalUsinasOperandoNormalmente} twoColors="#3EFF3E" minHeight={50} />
                </Grid>
                <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                    <InfoBox icon="fas fa-exclamation-triangle" text="Sem Comunicação ou Desligado" number={totalUsinasSemComunicacao} twoColors="#F56969" minHeight={50} />
                </Grid>
                <Grid cols="12 4 4 4" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null, fontSize: "0.8rem" }}>
                    <InfoBox text="Adv. por Fator de Potência Baixo" textBold number={totalUsinasProducaoOtima} twoColors="#34c6ff" minHeight={50}>
                        <img
                            src={capacitor}
                            style={{
                                height: 40,
                                width: 40,
                            }}
                            alt=""
                        />
                    </InfoBox>
                </Grid>
                <Grid cols="12 4 4 4" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null, fontSize: "0.8rem" }}>
                    <InfoBox text="Advertência por Tensão Baixa" textBold number={totalUsinasProducaoModerada} twoColors="#ffe53b" minHeight={50}>
                        <img
                            src={volts}
                            style={{
                                height: 45,
                                width: 45,
                            }}
                            alt=""
                        />
                    </InfoBox>
                </Grid>
                <Grid cols="12 4 4 4" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null, fontSize: "0.8rem" }}>
                    <InfoBox text="Advertência por Tensão Alta" textBold number={totalUsinasProducaoBaixa} twoColors="#ffa600" minHeight={50}>
                        <img
                            src={volts}
                            style={{
                                height: 50,
                                width: 50,
                            }}
                            alt=""
                        />
                    </InfoBox>
                </Grid>
            </>
        );
        //----------------------------------------------------------------------

        const corAbaAtiva = "#ffffff";
        const corAbaInativa = "#ebebeb";
        const corTextoAbaAtiva = "#000000";
        const corTextoAbaInativa = "#cccccc";

        const exibirAbaSazonal = listaDemandaContagem.find((item) => item.contrato_demanda_sazonal);
        const exibirAbaNaoSazonal = listaDemandaContagem.find((item) => !item.contrato_demanda_sazonal);

        return (
            <ContentCard>
                <ContentCardBody>
                    <Row
                        style={{
                            marginBottom: 5,
                            justifyContent: "space-between",
                            paddingInline: 10,
                        }}
                    >
                        <GraficoMonitoramentoConsumo />
                        <Grid cols="12 12 12 6">
                            {window.innerWidth > 768 ? (
                                <Row>
                                    {cardsTotalizadores}
                                    {cardsStatusProducao}
                                </Row>
                            ) : (
                                <details open={usuarioLogado && !usuarioLogado.acesso_cliente} style={{ marginTop: 20, padding: 20, fontSize: "1.1rem", border: "1px solid #ddd", borderRadius: 5 }}>
                                    <summary>
                                        <b>Exibir totalizadores</b>
                                    </summary>
                                    <Row style={{ marginTop: 10 }}>
                                        {cardsTotalizadores}
                                        {cardsStatusProducao}
                                    </Row>
                                </details>
                            )}
                        </Grid>
                    </Row>
                    <Row>
                        {usuarioLogado && usuarioLogado.acesso_cliente ? null : (
                            <Select
                                style={{ margin: 0 }}
                                name="id_cliente"
                                options={listaCliente}
                                label="Cliente"
                                cols="12 12 3 3"
                                placeholder="Selecione o Cliente"
                                value={filtro.id_cliente}
                                onChange={(value) => {
                                    setFiltro({
                                        ...filtro,
                                        id_cliente: value,
                                        id_unidade_consumidora: null,
                                    });
                                    // buscarDadosTela();
                                }}
                            />
                        )}
                        <Select
                            style={{ margin: 0 }}
                            options={listarUCsFiltradas()}
                            label="Unidades Consumidoras"
                            cols="12 12 3 3"
                            placeholder="Selecione as UCs"
                            isMulti={true}
                            value={filtro.unidades_consumidoras}
                            onChange={(ucsSelecionadas) => {
                                const ucQualquerDoFiltro = ucsSelecionadas[0] || {};
                                const ucCompleta = listaUnidadeConsumidora.find((uc) => ucQualquerDoFiltro.value == uc.id) || { id_cliente: null };
                                setFiltro({
                                    ...filtro,
                                    unidades_consumidoras: [...ucsSelecionadas],
                                    id_cliente: filtro.id_cliente || ucCompleta.id_cliente,
                                });
                            }}
                        />
                        <Select
                            style={{ margin: 0 }}
                            name="demanda_contrato_tipos"
                            options={listaDemandaContratoTipo.filter((tipo) => {
                                if (!filtro.id_cliente && tipo.id != 3) return true;
                                const ucFiltradaPeloCliente = listaUnidadeConsumidora.filter((uc) => uc.id_cliente == filtro.id_cliente);
                                const condicaoForaPonta = tipo.id == 1;
                                const condicaoPonta = tipo.id == 2 && !!ucFiltradaPeloCliente.find((uc) => uc.id_modalidade_tarifaria == 2); //alguma uc do cliente selecionado tem q ser horosazonal azul
                                return condicaoForaPonta || condicaoPonta;
                            })}
                            label="Tipo de Contrato de Demanda"
                            cols="12 12 3 3"
                            placeholder="Selecione o tipo de contrato de demanda"
                            value={filtro.id_tipo_contrato_demanda}
                            isClearable={false}
                            isSearchable={false}
                            onChange={(id_tipo_contrato_demanda) => {
                                setFiltro({
                                    ...filtro,
                                    id_tipo_contrato_demanda,
                                });
                            }}
                        />

                        <Grid
                            cols="12 12 1 1"
                            style={{
                                paddingTop: 15,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                // fontSize: "1.7rem",
                                paddingLeft: window.innerWidth < 992 ? 0 : null,
                            }}
                        >
                            <Button
                                solid={true}
                                style={{
                                    marginTop: 13,
                                    color: "#fff",

                                    transform: "scaleX(-1)",
                                }}
                                text={usuarioLogado.emitir_alerta_sonoro ? <i style={{ fontSize: "1.2rem" }} className="far fa-bell" /> : <i style={{ fontSize: "1.2rem" }} className="far fa-bell-slash" />}
                                type={"warning"}
                                // event={setEmitirAlertaSonoro}
                            />
                        </Grid>
                        <Grid cols="12 12 2 2" style={{ paddingTop: 29, paddingRight: 15 }}>
                            <Button text="Atualizar" type={"primary"} icon={"fa fa-sync"} event={getDemandaContagem} />
                        </Grid>
                    </Row>
                </ContentCardBody>
                <ContentCardBody
                    style={{
                        paddingTop: 0,
                        minWidth: 890,
                    }}
                >
                    <Row>
                        {exibirAbaSazonal ? (
                            <Grid
                                cols="4 4 4 4"
                                style={{
                                    padding: "15px 0px 5px 10px",
                                    marginLeft: 7,
                                    backgroundColor: abaSazonalSelcionada ? corAbaAtiva : corAbaInativa,
                                    color: abaSazonalSelcionada ? corTextoAbaAtiva : corTextoAbaInativa,
                                    marginBottom: 0,
                                    zIndex: abaSazonalSelcionada ? 1 : 0,
                                    borderRadius: "10px 10px 0 0",
                                    border: `solid 1px ${abaSazonalSelcionada ? "#ddd" : corAbaInativa}`,
                                    cursor: "pointer",
                                    textAlign: "center",
                                }}
                                onClick={() => {
                                    setAbaSazonalSelcionada(true);
                                }}
                            >
                                <h6>Sazonal</h6>
                            </Grid>
                        ) : null}
                        {exibirAbaNaoSazonal ? (
                            <Grid
                                cols="4 4 4 4"
                                style={{
                                    padding: "15px 0px 5px 10px",
                                    backgroundColor: abaSazonalSelcionada ? corAbaInativa : corAbaAtiva,
                                    color: abaSazonalSelcionada ? corTextoAbaInativa : corTextoAbaAtiva,
                                    marginBottom: 0,
                                    zIndex: abaSazonalSelcionada ? 0 : 1,
                                    borderRadius: "10px 10px 0 0",
                                    border: `solid 1px ${abaSazonalSelcionada ? corAbaInativa : "#ddd"}`,
                                    cursor: "pointer",
                                    textAlign: "center",
                                }}
                                onClick={() => {
                                    setAbaSazonalSelcionada(false);
                                }}
                            >
                                <h6>Não Sazonal</h6>
                            </Grid>
                        ) : null}
                    </Row>
                    <Table responsive>
                        <THead>
                            <Tr>
                                <Th backgroundColor={"#FFFFFF"} sticky alignCenter colSpan={abaSazonalSelcionada ? "4" : "2"}>
                                    Atingidas
                                </Th>
                                {usuarioLogado.administrador ? (
                                    <Th backgroundColor={"#FFFFFF"} sticky>
                                        Cliente
                                    </Th>
                                ) : null}
                                <Th colSpan={2} backgroundColor={"#FFFFFF"} sticky>
                                    Unidade Consumidora
                                </Th>
                                <Th backgroundColor={"#FFFFFF"} sticky alignCenter>
                                    Data/Hora
                                </Th>
                                <Th backgroundColor={"#FFFFFF"} sticky alignCenter>
                                    Gráfico
                                </Th>
                                <Th minWidth={50} backgroundColor={"#FFFFFF"} sticky alignCenter>
                                    <p style={{ margin: 0 }}>P</p>(kW)
                                </Th>
                                <Th minWidth={50} backgroundColor={"#FFFFFF"} sticky alignCenter>
                                    <p style={{ margin: 0 }}>Q</p>(kVAr)
                                </Th>
                                <Th minWidth={50} backgroundColor={"#FFFFFF"} sticky alignCenter>
                                    <p style={{ margin: 0 }}>S</p>(kVA)
                                </Th>
                                <Th minWidth={50} backgroundColor={"#FFFFFF"} sticky alignCenter>
                                    <p style={{ margin: 0 }}>I</p>(Ampere)
                                </Th>
                                <Th minWidth={50} backgroundColor={"#FFFFFF"} sticky alignCenter>
                                    <p style={{ margin: 0 }}>V</p>(volts)
                                </Th>
                                <Th minWidth={50} backgroundColor={"#FFFFFF"} sticky alignCenter>
                                    <p style={{ margin: 0 }}>FP</p>
                                </Th>
                                <Th minWidth={100} backgroundColor={"#FFFFFF"} sticky alignCenter>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th colSpan={4} style={{ border: "none" }}>
                                                    P (kW) Máx. no Mês (Demanda)
                                                </th>
                                            </tr>
                                            <tr>
                                                <th style={{ border: "1px solid #ddd", padding: 4, textAlign: "center", minWidth: 90, textWrap: "nowrap", fontSize: "0.8rem" }}>Descrição</th>
                                                <th style={{ border: "1px solid #ddd", padding: 4, textAlign: "center", minWidth: 100, textWrap: "nowrap", fontSize: "0.8rem" }}>Data/Hora</th>
                                                <th style={{ border: "1px solid #ddd", padding: 4, textAlign: "center", minWidth: 60, textWrap: "nowrap", fontSize: "0.8rem" }}>Demanda</th>
                                                <th style={{ border: "1px solid #ddd", padding: 4, textAlign: "center", minWidth: 80, textWrap: "nowrap", fontSize: "0.8rem" }}>Consumo</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </Th>
                                <Th minWidth={150} backgroundColor={"#FFFFFF"} sticky alignCenter>
                                    <p style={{ margin: 0 }}>Valor de Demanda</p>
                                    <p style={{ margin: 0 }}>Contratada do</p>
                                    <p style={{ margin: 0 }}>Mês Atual (kW)</p>
                                </Th>
                                {abaSazonalSelcionada ? (
                                    <Th backgroundColor={"#FFFFFF"} sticky alignCenter>
                                        Ciclo Sazonal
                                    </Th>
                                ) : (
                                    <>
                                        <Th backgroundColor={"#FFFFFF"} sticky alignCenter>
                                            Primeiro Mês Não Sazonal
                                        </Th>
                                        <Th backgroundColor={"#FFFFFF"} sticky alignCenter>
                                            Nº Meses Sem Sazonalidade
                                        </Th>
                                    </>
                                )}

                                <Th backgroundColor={"#FFFFFF"} sticky alignCenter>
                                    Sazonalidade
                                </Th>
                            </Tr>
                        </THead>
                        <TBody>
                            {listaDemandaContagem
                                .filter((item) => (abaSazonalSelcionada ? item.contrato_demanda_sazonal : !item.contrato_demanda_sazonal))
                                .map((item, i) => {
                                    const sazonalidadeDiferenteDaUC = item.contrato_demanda_sazonal != item.sazonal;

                                    let restantes = item.competencias - item.lidas;
                                    let cor = "#222";
                                    if (item.atingidas < 3) {
                                        if (restantes <= 3 && item.atingidas <= 1) {
                                            cor = "#c92e2b";
                                        } else if (restantes <= 4 && item.atingidas <= 2) {
                                            cor = "#f7e11b";
                                        }
                                    }
                                    //Configura o Tooltip de agendamento de demanda-------------------------------------------------------------------------------------
                                    let tooltipAgendamentoDemanda = montarTooltipAgendamento({ item, props });

                                    //Configura o Tooltip de período de testes -----------------------------------------------------------------------------------------
                                    const periodoTeste = item.periodo_teste || [];
                                    let tooltipPeriodoTeste = montarTooltipoPeriodoTeste({ periodoTeste, item, props });

                                    const avisoSazonalidadeDiferente = (
                                        <div style={{ backgroundColor: "#fd5959", color: "#ffffff", padding: 5, borderRadius: "0.3rem", textAlign: "center" }}>
                                            <div style={{ width: "100%", textWrap: "nowrap" }}>A sazonalidade da UC é diferente do contrato</div>
                                            <div>
                                                (UC:{item.contrato_demanda_sazonal ? " Sazonal " : " Não Sazonal "} - Contrato:
                                                {item.sazonal ? " Sazonal " : " Não Sazonal "})
                                            </div>
                                        </div>
                                    );
                                    //----------------------------------------------------------------------------------------------------------------------------------
                                    const ultimaLinha = i == listaDemandaContagem.length - 1;
                                    const horosazonalVerde = item.id_modalidade_tarifaria == 1;
                                    const horosazonalAzul = item.id_modalidade_tarifaria == 2;

                                    //item.demanda_fora_ponta_contratada_mes_atual
                                    function calcularCorDemanda(valorLido, demandaContratada) {
                                        if (isNaN(parseFloat(valorLido))) return null;
                                        return parseFloat(valorLido) > parseFloat(demandaContratada) * 1.05 ? "#ff6666" : parseFloat(valorLido) >= parseFloat(demandaContratada) ? "#64db64" : null;
                                    }

                                    //DEFINIR COR DE ATINGIDA E ULTRAPASSAGEM
                                    //inclui 4 atributos com cores indefinidas
                                    item.consumoLeitura.cor_maior_demanda_mes = null;
                                    item.consumoLeitura.cor_demanda_ponta = null;
                                    item.consumoLeitura.cor_demanda_fora_ponta = null;
                                    item.consumoLeitura.cor_demanda_reservado = null;

                                    let corTabelaDemanda = "#ddd";

                                    //HOROSAZONAL VERDE pega o maior dos 3 e compara com o fora ponta
                                    if (horosazonalVerde) {
                                        corTabelaDemanda = "#00d85f";
                                        item.consumoLeitura.cor_maior_demanda_mes = calcularCorDemanda(item.consumoLeitura.maior_demanda_mes, item.demanda_fora_ponta_contratada_mes_atual);
                                        const valores = [parseFloat(item.consumoLeitura.demanda_ponta), parseFloat(item.consumoLeitura.demanda_fora_ponta), parseFloat(item.consumoLeitura.demanda_reservado)];
                                        const maior = Math.max(...valores);
                                        const indiceMaior = valores.indexOf(maior);
                                        switch (indiceMaior) {
                                            case 0:
                                                item.consumoLeitura.cor_demanda_ponta = calcularCorDemanda(item.consumoLeitura.demanda_ponta, item.demanda_fora_ponta_contratada_mes_atual);
                                                break;
                                            case 1:
                                                item.consumoLeitura.cor_demanda_fora_ponta = calcularCorDemanda(item.consumoLeitura.demanda_fora_ponta, item.demanda_fora_ponta_contratada_mes_atual);
                                                break;
                                            case 2:
                                                item.consumoLeitura.cor_demanda_reservado = calcularCorDemanda(item.consumoLeitura.demanda_reservado, item.demanda_fora_ponta_contratada_mes_atual);
                                                break;
                                            default:
                                                break;
                                        }
                                    }

                                    //HOROSAZONAL AZUL compara ponta com ponta e fora ponta com fora ponta
                                    if (horosazonalAzul) {
                                        corTabelaDemanda = "#34cbff";
                                        item.consumoLeitura.cor_demanda_ponta = calcularCorDemanda(item.consumoLeitura.demanda_ponta, item.demanda_ponta_contratada_mes_atual);
                                        item.consumoLeitura.cor_demanda_fora_ponta = calcularCorDemanda(item.consumoLeitura.demanda_fora_ponta, item.demanda_fora_ponta_contratada_mes_atual);
                                    }

                                    return (
                                        <Tr key={item.id}>
                                            {abaSazonalSelcionada ? (
                                                <>
                                                    <Td
                                                        borderLeft="1px solid #81c784"
                                                        borderRight="1px solid #81c784"
                                                        borderBottom={ultimaLinha ? "1px solid #81c784" : null}
                                                        color={"#fff"}
                                                        minWidth={40}
                                                        backgroundColor={item.atingidas >= 1 ? "#28a745" : "#fff"}
                                                        alignCenter
                                                    ></Td>
                                                    <Td
                                                        minWidth={40}
                                                        borderLeft="1px solid #81c784"
                                                        borderRight="1px solid #81c784"
                                                        borderBottom={ultimaLinha ? "1px solid #81c784" : null}
                                                        color={item.atingidas >= 2 ? "#fff" : "#000"}
                                                        backgroundColor={item.atingidas >= 2 ? "#28a745" : "#fff"}
                                                        alignCenter
                                                    >
                                                        {item.atingidas}
                                                    </Td>
                                                    <Td
                                                        borderLeft="1px solid #81c784"
                                                        borderRight="1px solid #81c784"
                                                        borderBottom={ultimaLinha ? "1px solid #81c784" : null}
                                                        color={"#fff"}
                                                        minWidth={40}
                                                        backgroundColor={item.atingidas >= 3 ? "#28a745" : "#fff"}
                                                        alignCenter
                                                    ></Td>
                                                    <Td
                                                        color={"#fff"}
                                                        title={`Total de ultrapassagens:${parseInt(item.ultrapassagens || 0)}`}
                                                        minWidth={40}
                                                        backgroundColor={parseInt(item.ultrapassagens) ? "#fa3c3c" : "black"}
                                                        borderBottom={ultimaLinha ? `1px solid ${parseInt(item.ultrapassagens) ? "#fa3c3c" : "black"}` : null}
                                                        alignCenter
                                                    >
                                                        <div style={{ display: "flex", flexGrow: 1, flexDirection: "column", borderRadius: 3 }}>
                                                            <div style={{ fontSize: "1.4rem", fontWeight: "bold" }} className="info-box-icon">
                                                                <i className="fas fa-angle-double-up"></i>
                                                            </div>
                                                            <div style={{ fontSize: "0.9rem", fontWeight: "bold" }} className="info-box-number">
                                                                {item.ultrapassagens}
                                                            </div>
                                                        </div>
                                                    </Td>
                                                </>
                                            ) : (
                                                <>
                                                    <Td>
                                                        <div
                                                            style={{
                                                                margin: 0,
                                                                flexDirection: "column",
                                                                alignItems: "center",
                                                                padding: "5px 5px 0px 5px",
                                                                minHeight: "fit-content",
                                                            }}
                                                            className="info-box bg-green"
                                                            title="Atingidas sem Ultrapassagens"
                                                        >
                                                            <span className="info-box-icon" style={{ width: window.innerWidth < 576 ? 20 : 70, fontSize: "1.3rem" }}>
                                                                <i className="fas fa-angle-up"></i>
                                                            </span>
                                                            <span style={{ margin: 0 }} className="info-box-number">
                                                                {item.atingidas - (item.ultrapassagens || 0)}
                                                            </span>
                                                        </div>
                                                    </Td>
                                                    <Td>
                                                        <div
                                                            style={{
                                                                margin: 0,
                                                                flexDirection: "column",
                                                                alignItems: "center",
                                                                padding: "5px 5px 0px 5px",
                                                                minHeight: "fit-content",
                                                            }}
                                                            className="info-box bg-red"
                                                            title="Atingidas com Ultrapassagens"
                                                        >
                                                            <span className="info-box-icon" style={{ width: window.innerWidth < 576 ? 20 : 70, fontSize: "1.3rem" }}>
                                                                <i className="fas fa-angle-double-up"></i>
                                                            </span>
                                                            <span style={{ margin: 0 }} className="info-box-number">
                                                                {item.ultrapassagens || 0}
                                                            </span>
                                                        </div>
                                                    </Td>
                                                </>
                                            )}
                                            {usuarioLogado.administrador ? <Td title={item.nome_cliente}>{item.nome_cliente.length > 25 ? item.nome_cliente.substring(0, 25) + "..." : item.nome_cliente}</Td> : null}
                                            <Td minWidth={200} title={item.nome_unidade_consumidora}>
                                                {item.nome_unidade_consumidora}
                                            </Td>
                                            {sazonalidadeDiferenteDaUC ? (
                                                <Td>{avisoSazonalidadeDiferente}</Td>
                                            ) : (
                                                <Td padding={10}>
                                                    {tooltipAgendamentoDemanda}
                                                    {tooltipPeriodoTeste}
                                                </Td>
                                            )}

                                            <Td alignCenter fontSize="1rem">
                                                {item.consumoLeitura.data_hora ? <div style={{ padding: 5 }}>{DateFormat.formatarDataHoraSqlParaTelaShortYear(item.consumoLeitura.data_hora)}</div> : "-"}
                                            </Td>
                                            <Td alignCenter minWidth={80}>
                                                <Button
                                                    style={{ width: 80, height: 80 }}
                                                    title="Ver o gráfico do dia"
                                                    text={
                                                        <div>
                                                            <div>
                                                                <i style={{ fontSize: "2rem" }} className="fas fa-chart-line"></i>
                                                            </div>
                                                            <b>P e Q</b>
                                                        </div>
                                                    }
                                                    type={"info"}
                                                    event={() => {
                                                        getListaGraficoDia({
                                                            id_consumo_integracao: item.consumoLeitura.id_consumo_integracao,
                                                            dataInicial: dataInicialGrafico,
                                                            dataFinal: dataFinalGrafico,
                                                        });
                                                        setModoTela("graficoDia", item);
                                                    }}
                                                />
                                            </Td>
                                            <Td alignCenter fontSize="1rem">
                                                <div style={{ padding: "15px 5px", border: !isNaN(parseFloat(item.consumoLeitura.kw)) ? (item.consumoLeitura.status_kw ? "1px solid #0E0" : "1px solid #E00") : "none" }}>
                                                    {!isNaN(parseFloat(item.consumoLeitura.kw)) ? adicionarPontosMilhar(item.consumoLeitura.kw) : "-"}
                                                </div>
                                            </Td>
                                            <Td alignCenter fontSize="1rem">
                                                <div
                                                    style={{
                                                        padding: "15px 5px",
                                                        border: !isNaN(parseFloat(item.consumoLeitura.kvar_ind)) ? (item.consumoLeitura.status_kw ? "1px solid #0E0" : "1px solid #E00") : "none",
                                                    }}
                                                >
                                                    {!isNaN(parseFloat(item.consumoLeitura.kvar_ind)) ? adicionarPontosMilhar(item.consumoLeitura.kvar_ind) : "-"}
                                                </div>
                                            </Td>
                                            <Td alignCenter title="S = Raiz Quadrada de (P² + Q²)" fontSize="1rem">
                                                {!isNaN(parseFloat(item.consumoLeitura.kw)) && !isNaN(parseFloat(item.consumoLeitura.kvar_ind))
                                                    ? adicionarPontosMilhar(Math.sqrt(Math.pow(parseFloat(item.consumoLeitura.kw), 2) + Math.pow(parseFloat(item.consumoLeitura.kvar_ind), 2)))
                                                    : "-"}
                                            </Td>
                                            <Td alignCenter fontSize="1rem">
                                                ?
                                            </Td>
                                            <Td alignCenter fontSize="1rem">
                                                ?
                                            </Td>
                                            <Td alignCenter title="FP = P / S" fontSize="1rem">
                                                {!isNaN(parseFloat(item.consumoLeitura.kw)) && !isNaN(parseFloat(item.consumoLeitura.kvar_ind))
                                                    ? adicionarPontosMilhar(
                                                          parseFloat(item.consumoLeitura.kw) / Math.sqrt(Math.pow(parseFloat(item.consumoLeitura.kw), 2) + Math.pow(parseFloat(item.consumoLeitura.kvar_ind), 2))
                                                      )
                                                    : "-"}
                                            </Td>
                                            <Td alignCenter>
                                                <table>
                                                    {/* style={{ border: (horosazonalVerde ? "green" : "blue") }} */}

                                                    <tbody>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    border: `1px solid ${corTabelaDemanda}`,
                                                                    textAlign: "center",
                                                                    padding: "2px 5px",
                                                                    fontSize: "0.8rem",
                                                                    textWrap: "nowrap",
                                                                }}
                                                            >
                                                                Maior do Mês
                                                            </td>
                                                            <td
                                                                style={{
                                                                    border: `1px solid ${corTabelaDemanda}`,
                                                                    textAlign: "center",
                                                                    padding: "2px 5px",
                                                                    fontSize: "0.8rem",
                                                                    textWrap: "nowrap",
                                                                    minWidth: 100,
                                                                }}
                                                            >
                                                                {item.consumoLeitura.datahora_maior_demanda_mes ? (
                                                                    <div>{DateFormat.formatarDataHoraSqlParaTelaShortYear(item.consumoLeitura.datahora_maior_demanda_mes)}</div>
                                                                ) : (
                                                                    "-"
                                                                )}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    border: `1px solid ${corTabelaDemanda}`,
                                                                    textAlign: "center",
                                                                    padding: "2px 5px",
                                                                    fontSize: "0.8rem",
                                                                    textWrap: "nowrap",
                                                                    minWidth: 60,
                                                                    backgroundColor: item.consumoLeitura.cor_maior_demanda_mes,
                                                                }}
                                                            >
                                                                {adicionarPontosMilhar(item.consumoLeitura.maior_demanda_mes) || "-"}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    border: `1px solid ${corTabelaDemanda}`,
                                                                    textAlign: "center",
                                                                    padding: "2px 5px",
                                                                    fontSize: "0.8rem",
                                                                    textWrap: "nowrap",
                                                                    minWidth: 80,
                                                                }}
                                                            >
                                                                {adicionarPontosMilhar(item.consumoLeitura.energia_total) || "-"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    border: `1px solid ${corTabelaDemanda}`,
                                                                    textAlign: "center",
                                                                    padding: "2px 5px",
                                                                    fontSize: "0.8rem",
                                                                    textWrap: "nowrap",
                                                                }}
                                                            >
                                                                Ponta
                                                            </td>
                                                            <td
                                                                style={{
                                                                    border: `1px solid ${corTabelaDemanda}`,
                                                                    textAlign: "center",
                                                                    padding: "2px 5px",
                                                                    fontSize: "0.8rem",
                                                                    textWrap: "nowrap",
                                                                    minWidth: 100,
                                                                }}
                                                            >
                                                                {item.consumoLeitura.datahora_demanda_ponta ? (
                                                                    <div>{DateFormat.formatarDataHoraSqlParaTelaShortYear(item.consumoLeitura.datahora_demanda_ponta)}</div>
                                                                ) : (
                                                                    "-"
                                                                )}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    border: `1px solid ${corTabelaDemanda}`,
                                                                    textAlign: "center",
                                                                    padding: "2px 5px",
                                                                    fontSize: "0.8rem",
                                                                    textWrap: "nowrap",
                                                                    minWidth: 60,
                                                                    backgroundColor: horosazonalAzul ? item.consumoLeitura.cor_demanda_ponta : null, //se for azul pinta, senao só deixa em negrito na cor definida
                                                                    fontWeight: horosazonalVerde && item.consumoLeitura.cor_demanda_ponta ? "bold" : "normal",
                                                                    color: horosazonalVerde && item.consumoLeitura.cor_demanda_ponta ? item.consumoLeitura.cor_demanda_ponta : null,
                                                                }}
                                                            >
                                                                {adicionarPontosMilhar(item.consumoLeitura.demanda_ponta) || "-"}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    border: `1px solid ${corTabelaDemanda}`,
                                                                    textAlign: "center",
                                                                    padding: "2px 5px",
                                                                    fontSize: "0.8rem",
                                                                    textWrap: "nowrap",
                                                                    minWidth: 80,
                                                                }}
                                                            >
                                                                {adicionarPontosMilhar(item.consumoLeitura.energia_ponta) || "-"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    border: `1px solid ${corTabelaDemanda}`,
                                                                    textAlign: "center",
                                                                    padding: "2px 5px",
                                                                    fontSize: "0.8rem",
                                                                    textWrap: "nowrap",
                                                                }}
                                                            >
                                                                Fora Ponta
                                                            </td>
                                                            <td
                                                                style={{
                                                                    border: `1px solid ${corTabelaDemanda}`,
                                                                    textAlign: "center",
                                                                    padding: "2px 5px",
                                                                    fontSize: "0.8rem",
                                                                    textWrap: "nowrap",
                                                                    minWidth: 100,
                                                                }}
                                                            >
                                                                {item.consumoLeitura.datahora_demanda_fora_ponta ? (
                                                                    <div>{DateFormat.formatarDataHoraSqlParaTelaShortYear(item.consumoLeitura.datahora_demanda_fora_ponta)}</div>
                                                                ) : (
                                                                    "-"
                                                                )}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    border: `1px solid ${corTabelaDemanda}`,
                                                                    textAlign: "center",
                                                                    padding: "2px 5px",
                                                                    fontSize: "0.8rem",
                                                                    textWrap: "nowrap",
                                                                    minWidth: 60,
                                                                    backgroundColor: horosazonalAzul ? item.consumoLeitura.cor_demanda_fora_ponta : null, //se for azul pinta, senao só deixa em negrito na cor definida
                                                                    fontWeight: horosazonalVerde && item.consumoLeitura.cor_demanda_fora_ponta ? "bold" : "normal",
                                                                    color: horosazonalVerde && item.consumoLeitura.cor_demanda_fora_ponta ? item.consumoLeitura.cor_demanda_fora_ponta : null,
                                                                }}
                                                            >
                                                                {adicionarPontosMilhar(item.consumoLeitura.demanda_fora_ponta) || "-"}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    border: `1px solid ${corTabelaDemanda}`,
                                                                    textAlign: "center",
                                                                    padding: "2px 5px",
                                                                    fontSize: "0.8rem",
                                                                    textWrap: "nowrap",
                                                                    minWidth: 80,
                                                                }}
                                                            >
                                                                {adicionarPontosMilhar(item.consumoLeitura.energia_fora_ponta) || "-"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    border: `1px solid ${corTabelaDemanda}`,
                                                                    textAlign: "center",
                                                                    padding: "2px 5px",
                                                                    fontSize: "0.8rem",
                                                                    textWrap: "nowrap",
                                                                }}
                                                            >
                                                                Reservado
                                                            </td>
                                                            <td
                                                                style={{
                                                                    border: `1px solid ${corTabelaDemanda}`,
                                                                    textAlign: "center",
                                                                    padding: "2px 5px",
                                                                    fontSize: "0.8rem",
                                                                    textWrap: "nowrap",
                                                                    minWidth: 100,
                                                                }}
                                                            >
                                                                {item.consumoLeitura.datahora_demanda_reservado ? (
                                                                    <div>{DateFormat.formatarDataHoraSqlParaTelaShortYear(item.consumoLeitura.datahora_demanda_reservado)}</div>
                                                                ) : (
                                                                    "-"
                                                                )}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    border: `1px solid ${corTabelaDemanda}`,
                                                                    textAlign: "center",
                                                                    padding: "2px 5px",
                                                                    fontSize: "0.8rem",
                                                                    textWrap: "nowrap",
                                                                    minWidth: 60,
                                                                    backgroundColor: horosazonalAzul ? item.consumoLeitura.cor_demanda_reservado : null, //se for azul pinta, senao só deixa em negrito na cor definida
                                                                    fontWeight: horosazonalVerde && item.consumoLeitura.cor_demanda_reservado ? "bold" : "normal",
                                                                    color: horosazonalVerde && item.consumoLeitura.cor_demanda_reservado ? item.consumoLeitura.cor_demanda_reservado : null,
                                                                }}
                                                            >
                                                                {adicionarPontosMilhar(item.consumoLeitura.demanda_reservado) || "-"}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    border: `1px solid ${corTabelaDemanda}`,
                                                                    textAlign: "center",
                                                                    padding: "2px 5px",
                                                                    fontSize: "0.8rem",
                                                                    textWrap: "nowrap",
                                                                    minWidth: 80,
                                                                }}
                                                            >
                                                                {adicionarPontosMilhar(item.consumoLeitura.energia_reservado) || "-"}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Td>
                                            <Td alignCenter minWidth={160}>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    border: `1px solid ${corTabelaDemanda}`,
                                                                    padding: 4,
                                                                    textAlign: "center",
                                                                    minWidth: 60,
                                                                    textWrap: "nowrap",
                                                                    fontSize: "0.8rem",
                                                                }}
                                                            >
                                                                Ponta
                                                            </td>
                                                            <td
                                                                style={{
                                                                    border: `1px solid ${corTabelaDemanda}`,
                                                                    padding: 4,
                                                                    textAlign: "center",
                                                                    minWidth: 60,
                                                                    textWrap: "nowrap",
                                                                    fontSize: "0.8rem",
                                                                }}
                                                            >
                                                                {adicionarPontosMilhar(item.demanda_ponta_contratada_mes_atual) || "-"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    border: `1px solid ${corTabelaDemanda}`,
                                                                    padding: 4,
                                                                    textAlign: "center",
                                                                    minWidth: 60,
                                                                    textWrap: "nowrap",
                                                                    fontSize: "0.8rem",
                                                                }}
                                                            >
                                                                Fora Ponta
                                                            </td>
                                                            <td
                                                                style={{
                                                                    border: `1px solid ${corTabelaDemanda}`,
                                                                    padding: 4,
                                                                    textAlign: "center",
                                                                    minWidth: 60,
                                                                    textWrap: "nowrap",
                                                                    fontSize: "0.8rem",
                                                                }}
                                                            >
                                                                {adicionarPontosMilhar(item.demanda_fora_ponta_contratada_mes_atual) || "-"}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Td>
                                            {sazonalidadeDiferenteDaUC ? <Td alignCenter>-</Td> : null}
                                            {abaSazonalSelcionada ? (
                                                <Td padding={10} alignCenter>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ border: "1px solid #ddd", textAlign: "center", padding: 4, fontSize: "0.8rem" }}>Início</td>
                                                                <td style={{ border: "1px solid #ddd", textAlign: "center", padding: 4, fontSize: "0.8rem" }}>
                                                                    {sazonalidadeDiferenteDaUC ? "-" : `${String(item.competencia_inicio).substring(4, 6)}/${String(item.competencia_inicio).substring(0, 4)}`}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ border: "1px solid #ddd", textAlign: "center", padding: 4, fontSize: "0.8rem" }}>Fim</td>
                                                                <td style={{ border: "1px solid #ddd", textAlign: "center", padding: 4, fontSize: "0.8rem" }}>
                                                                    {sazonalidadeDiferenteDaUC ? "-" : `${String(item.competencia_fim).substring(4, 6)}/${String(item.competencia_fim).substring(0, 4)}`}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ border: "1px solid #ddd", textAlign: "center", padding: 4, fontSize: "0.8rem" }}>Ciclo</td>
                                                                <td style={{ border: "1px solid #ddd", textAlign: "center", padding: 4, fontSize: "0.8rem" }}>
                                                                    <div
                                                                        style={{ textWrap: "nowrap" }}
                                                                        color={cor}
                                                                        fontWeight="bold"
                                                                        title={
                                                                            item.competencias - item.lidas > 3 - item.atingidas || item.atingidas >= 3
                                                                                ? null
                                                                                : item.competencias - item.lidas == 3 - item.atingidas
                                                                                ? `É necessário atingir ${3 - item.atingidas} demanda${3 - item.atingidas > 1 ? "s" : ""} e resta${
                                                                                      item.competencias - item.lidas > 1 ? "m" : ""
                                                                                  } ${item.competencias - item.lidas} fatura${item.competencias - item.lidas > 1 ? "s" : ""} no ciclo sazonal`
                                                                                : `É necessário atingir ${3 - item.atingidas} demanda${3 - item.atingidas > 1 ? "s" : ""} e ${
                                                                                      item.competencias == item.lidas
                                                                                          ? "o ciclo sazonal já está completo"
                                                                                          : `resta${item.competencias - item.lidas > 1 ? "m" : ""} ${item.competencias - item.lidas} fatura${
                                                                                                item.competencias - item.lidas > 1 ? "s" : ""
                                                                                            } no ciclo sazonal`
                                                                                  }`
                                                                        }
                                                                        backgroundColor={
                                                                            item.competencias - item.lidas > 3 - item.atingidas || item.atingidas >= 3
                                                                                ? null
                                                                                : item.competencias - item.lidas == 3 - item.atingidas
                                                                                ? "#ffb74d"
                                                                                : "#e57373"
                                                                        }
                                                                        alignCenter
                                                                    >
                                                                        {sazonalidadeDiferenteDaUC ? "-" : `${item.lidas || 1} de ${item.competencias}`}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </Td>
                                            ) : (
                                                <Td padding={10} alignCenter>
                                                    {sazonalidadeDiferenteDaUC ? "-" : `${String(item.competencia_base_sazonalidade).substring(4, 6)}/${String(item.competencia_base_sazonalidade).substring(0, 4)}`}
                                                </Td>
                                            )}
                                            {item.sazonal ? null : (
                                                <Td padding={10} alignCenter>
                                                    {item.meses_nao_sazonal || 1}
                                                </Td>
                                            )}

                                            <Td color="#fff" backgroundColor="#40838f" alignCenter>
                                                {sazonalidadeDiferenteDaUC ? "-" : parseFloat(item.sazonalidade).toFixed(2).replace(".", ",")}
                                            </Td>
                                        </Tr>
                                    );
                                })}
                        </TBody>
                    </Table>
                </ContentCardBody>
            </ContentCard>
        );
    }
    function exibirGrafico(nomeGrafico) {
        return (
            <ContentCard style={{ backgroundColor: "#f0f1f5", paddingLeft: 0 }}>
                <ContentCardBody padding="0px">
                    <Row
                        style={{
                            // overflow: "scroll",
                            height: "fit-content",
                            paddingLeft: 15,
                            paddingRight: 7,
                        }}
                    >
                        <Row
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                width: "100%",
                                backgroundColor: "#fff",
                                borderTop: "1px solid #ddd",
                                paddingTop: 25,
                                alignItems: "center",
                                justifyContent: window.innerWidth < 1340 ? "center" : "start",
                            }}
                        >
                            <Row style={{ width: "100%", justifyContent: "flex-end", paddingBottom:10 }}>
                                <Grid cols="12 3 3 3">
                                    <Button
                                        style={{ margin: 0 }}
                                        text="Voltar"
                                        type={"warning"}
                                        icon={"fa fa-chevron-left"}
                                        event={() => {
                                            setModoTela("lista", {});
                                            setDataInicialGrafico(DateFormat.getDataAtual().replace(/\//g, "-"));
                                            setDataFinalGrafico(DateFormat.getDataAtual().replace(/\//g, "-"));
                                        }}
                                    />
                                </Grid>
                            </Row>
                            <Row style={{ width: "100%", justifyContent: "center" }}>
                                <Grid
                                    cols={"12 12 4 4"}
                                    style={{
                                        display: "flex",
                                        minWidth: "fit-content",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <button
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            fontSize: "1.5rem",
                                            fontWeight: 500,
                                            boxShadow: desabilitarBotaoVoltarData(dataInicialGrafico) ? "inset 0 0 16px 1px #ccc" : "0 0 10px 1px #ccc",
                                            color: "#0070cc",
                                            width: 40,
                                            height: 40,
                                            borderRadius: "100%",
                                            backgroundColor: "#fff",
                                            border: "none",
                                            marginInline: 20,
                                        }}
                                        onFocus={(e) => {
                                            e.target.style.outline = "none";
                                        }}
                                        onClick={() => {
                                            voltarAvancarDataGrafico({
                                                dataGrafico: dataInicialGrafico,
                                                tempo: -1,
                                                modoTela,
                                                setDataGrafico: setDataInicialGrafico,
                                            });
                                        }}
                                        disabled={desabilitarBotaoVoltarData(dataInicialGrafico)}
                                    >
                                        {"<"}
                                    </button>
                                    {modoTela === "graficoDia" && (
                                        <input
                                            style={{
                                                fontSize: "1.2rem",
                                                padding: 7,
                                                border: "1px solid #999",
                                                borderRadius: 10,
                                            }}
                                            required
                                            type="date"
                                            value={dataInicialGrafico}
                                            max={DateFormat.getDataAtual().replace(/\//g, "-")}
                                            onFocus={(e) => (e.target.style.outline = "none")}
                                            onChange={(e) => {
                                                const valorData = e.target.value || DateFormat.getDataAtual().replace(/\//g, "-");
                                                setDataInicialGrafico(valorData);
                                                atualizarListasGraficos(valorData, dataFinalGrafico);
                                            }}
                                        ></input>
                                    )}
                                    <button
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            fontSize: "1.5rem",
                                            fontWeight: 500,
                                            boxShadow: desabilitarBotaoAvancarData(dataInicialGrafico) ? "inset 0 0 16px 1px #ccc" : "0 0 10px 1px #ccc",
                                            color: "#0070cc",
                                            width: 40,
                                            height: 40,
                                            borderRadius: "100%",
                                            backgroundColor: "#fff",
                                            border: "none",
                                            marginInline: 20,
                                        }}
                                        onFocus={(e) => {
                                            e.target.style.outline = "none";
                                        }}
                                        onClick={() => {
                                            voltarAvancarDataGrafico({
                                                dataGrafico: dataInicialGrafico,
                                                tempo: 1,
                                                modoTela,
                                                setDataGrafico: setDataInicialGrafico,
                                            });
                                        }}
                                        disabled={desabilitarBotaoAvancarData(dataInicialGrafico)}
                                    >
                                        {">"}
                                    </button>
                                </Grid>
                                <div
                                    style={{
                                        display: "flex",
                                        minWidth: "fit-content",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize:'1.2rem',
                                        padding:15
                                    }}
                                >
                                    Até
                                </div>
                                <Grid
                                    cols={"12 12 4 4"}
                                    style={{
                                        display: "flex",
                                        minWidth: "fit-content",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <button
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            fontSize: "1.5rem",
                                            fontWeight: 500,
                                            boxShadow: desabilitarBotaoVoltarData(dataFinalGrafico) ? "inset 0 0 16px 1px #ccc" : "0 0 10px 1px #ccc",
                                            color: "#0070cc",
                                            width: 40,
                                            height: 40,
                                            borderRadius: "100%",
                                            backgroundColor: "#fff",
                                            border: "none",
                                            marginInline: 20,
                                        }}
                                        onFocus={(e) => {
                                            e.target.style.outline = "none";
                                        }}
                                        onClick={() => {
                                            voltarAvancarDataGrafico({
                                                dataGrafico: dataFinalGrafico,
                                                tempo: -1,
                                                modoTela,
                                                setDataGrafico: setDataFinalGrafico,
                                            });
                                        }}
                                        disabled={desabilitarBotaoVoltarData(dataFinalGrafico)}
                                    >
                                        {"<"}
                                    </button>
                                    {modoTela === "graficoDia" && (
                                        <input
                                            style={{
                                                fontSize: "1.2rem",
                                                padding: 7,
                                                border: "1px solid #999",
                                                borderRadius: 10,
                                            }}
                                            required
                                            type="date"
                                            value={dataFinalGrafico}
                                            max={DateFormat.getDataAtual().replace(/\//g, "-")}
                                            onFocus={(e) => (e.target.style.outline = "none")}
                                            onChange={(e) => {
                                                const valorData = e.target.value || DateFormat.getDataAtual().replace(/\//g, "-");
                                                setDataFinalGrafico(valorData);
                                                atualizarListasGraficos(dataInicialGrafico, valorData);
                                            }}
                                        ></input>
                                    )}
                                    <button
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            fontSize: "1.5rem",
                                            fontWeight: 500,
                                            boxShadow: desabilitarBotaoAvancarData(dataFinalGrafico) ? "inset 0 0 16px 1px #ccc" : "0 0 10px 1px #ccc",
                                            color: "#0070cc",
                                            width: 40,
                                            height: 40,
                                            borderRadius: "100%",
                                            backgroundColor: "#fff",
                                            border: "none",
                                            marginInline: 20,
                                        }}
                                        onFocus={(e) => {
                                            e.target.style.outline = "none";
                                        }}
                                        onClick={() => {
                                            voltarAvancarDataGrafico({
                                                dataGrafico: dataFinalGrafico,
                                                tempo: 1,
                                                modoTela,
                                                setDataGrafico: setDataFinalGrafico,
                                            });
                                        }}
                                        disabled={desabilitarBotaoAvancarData(dataFinalGrafico)}
                                    >
                                        {">"}
                                    </button>
                                </Grid>
                            </Row>
                        </Row>

                        <div
                            id={`${nomeGrafico}`}
                            style={{
                                width: "100%",
                                paddingTop: 15,
                                backgroundColor: "#fff",
                                height: "500px",
                            }}
                        ></div>
                        {nomeGraficoAtivo === nomeGrafico ? null : setNomeGraficoAtivo(nomeGrafico)}
                    </Row>
                </ContentCardBody>
            </ContentCard>
        );
    }

    function desabilitarBotaoVoltarData(dataGrafico) {
        if (["graficoMes", "lista"].includes(modoTela) && dataGrafico.substring(0, 7) === "2024-10") return true;
        if (modoTela === "graficoAno" && dataGrafico.substring(0, 4) === "2024") return true;
        return false;
    }

    function desabilitarBotaoAvancarData(dataGrafico) {
        if (["graficoDia", "lista"].includes(modoTela) && dataGrafico === new Date().toISOString().substring(0, 10)) return true;
        return false;
    }

    function montarGraficoDia(id, lista) {
        desmontarTodosGraficos();
        am4core.useTheme(am4themes_animated);

        let chart = am4core.create(id, am4charts.XYChart);

        // Configura título e subtítulo
        const subtitle = chart.titles.create();
        subtitle.text = registro.nome_unidade_consumidora;
        subtitle.fontWeight = "bold";
        subtitle.fontSize = window.innerWidth < 1150 ? "0.7rem" : "1rem";
        subtitle.fill = am4core.color("#000");
        subtitle.marginBottom = 30;

        const title = chart.titles.create();
        title.text = "Gráfico de Demanda Direta (Consumo)";
        title.fontWeight = "bold";
        title.fontSize = window.innerWidth < 1150 ? "0.8rem" : "1.2rem";
        title.fill = am4core.color("#000");
        title.marginBottom = 30;

        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.filePrefix = "Gráfico";
        chart.exporting.menu.align = "right";
        chart.exporting.menu.verticalAlign = "top";
        chart.exporting.menu.items = [
            {
                label: "...",
                menu: [
                    {
                        type: "png",
                        label: "PNG",
                    },
                ],
            },
        ];
        chart.language.locale = locale;
        chart.language.locale["_decimalSeparator"] = ",";
        chart.language.locale["_thousandSeparator"] = ".";
        chart.numberFormatter.numberFormat = "###,###,###,###.00";

        // Configura eixo X
        const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.startLocation = 0.5;
        dateAxis.endLocation = 0.5;
        // dateAxis.dateFormats.setKey("minute", "HH:mm");

        dateAxis.baseInterval = {
            timeUnit: "minute",
            count: 15,
        };

        // Lógica para ajustar os rótulos com base no intervalo de dados
        if (lista.length) {
            const firstDate = new Date(lista[0].data_hora);
            const lastDate = new Date(lista[lista.length - 1].data_hora);
            const isSingleDay = firstDate.toDateString() === lastDate.toDateString();

            if (isSingleDay) {
                // Exibe apenas horas se os dados forem de um único dia
                dateAxis.dateFormats.setKey("hour", "HH:mm");
                dateAxis.dateFormats.setKey("minute", "HH:mm");
            } else {
                // Exibe dias para múltiplos dias
                dateAxis.dateFormats.setKey("day", "dd/MM\nEEE");
            }
        }

        // Centraliza os rótulos no eixo
        dateAxis.renderer.labels.template.textAlign = "middle";
        dateAxis.renderer.labels.template.fill = am4core.color("#000");
        dateAxis.renderer.maxLabelPosition = 0.95;
        dateAxis.renderer.minGridDistance = 40;
        dateAxis.renderer.minLabelPosition = 0.05;

        // Configura eixo Y para "P (kW)"
        const valueAxisP = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxisP.title.text = "P (kW)";
        valueAxisP.renderer.labels.template.fill = am4core.color("#000");
        valueAxisP.min = 0;

        // Configura eixo Y secundário para "Q (kVAr)"
        const valueAxisQ = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxisQ.title.text = "Q (kVAr)";
        valueAxisQ.renderer.opposite = true; // Eixo no lado direito
        valueAxisQ.renderer.labels.template.fill = am4core.color("#000");
        valueAxisQ.min = 0;

        // Série para "P (kW)"
        const seriesP = chart.series.push(new am4charts.LineSeries());
        seriesP.dataFields.valueY = "p"; // Campo para "P (kW)"
        seriesP.dataFields.dateX = "data_hora";
        seriesP.yAxis = valueAxisP;
        seriesP.name = "P (kW)";
        seriesP.tooltipText = `P (kW) às {dateX.formatDate("dd/MM/yyyy HH:mm")}h: [bold]{valueY.formatNumber("###,###,###,###.00")} kW[/]`;
        seriesP.strokeWidth = 2;
        seriesP.tensionX = 1;
        seriesP.stroke = am4core.color("#017acd");
        seriesP.fill = am4core.color("#017acd");
        seriesP.fillOpacity = 0.2;

        // Série para "Q (kVAr)"
        const seriesQ = chart.series.push(new am4charts.LineSeries());
        seriesQ.dataFields.valueY = "q"; // Campo para "Q (kVAr)"
        seriesQ.dataFields.dateX = "data_hora";
        seriesQ.yAxis = valueAxisQ;
        seriesQ.name = "Q (kVAr)";
        seriesQ.tooltipText = `Q (kVAr) às {dateX.formatDate("dd/MM/yyyy HH:mm")}h: [bold]{valueY.formatNumber("###,###,###,###.00")} kVAr[/]`;
        seriesQ.strokeWidth = 2;
        seriesQ.tensionX = 1;
        seriesQ.stroke = am4core.color("#1bd07c");
        seriesQ.fill = am4core.color("#1bd07c");
        seriesQ.fillOpacity = 0.2;

        // Scrollbar
        chart.scrollbarX = new am4charts.XYChartScrollbar();
        chart.scrollbarX.series.push(seriesP);
        chart.scrollbarX.series.push(seriesQ);
        chart.scrollbarX.unselectedOverlay.fill = am4core.color("#fff");
        chart.scrollbarX.unselectedOverlay.fillOpacity = 0.7;
        chart.scrollbarX.unselectedOverlay.stroke = am4core.color("#fff");

        chart.scrollbarX.background.fill = am4core.color("#fff");
        chart.scrollbarX.thumb.background.fill = am4core.color("#2190ff"); //cor do trecho selecionado
        chart.scrollbarX.thumb.background.fillOpacity = 0.3; //opacidade da cor do trecho selecionado
        chart.scrollbarX.stroke = am4core.color("#003040"); // cor do texto dentro do trecho seleionado
        chart.scrollbarX.strokeWidth = 0;

        //Cor da scrollbar ao passar o mouse
        chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fill = am4core.color("#2190ff");
        chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fillOpacity = 0.3;

        chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill = am4core.color("#2190ff");
        chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fillOpacity = 0.3;

        chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill = am4core.color("#2190ff");
        chart.scrollbarX.thumb.background.states.getKey("hover").properties.fillOpacity = 0.3;

        //Adiciona legenda
        chart.legend = new am4charts.Legend();
        chart.legend.labels.template.fill = am4core.color("#000");

        // Adiciona cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = dateAxis;
        chart.cursor.snapToSeries = [seriesP, seriesQ];

        //configura a cor do tooltip x
        const dateAxisTooltip = dateAxis.tooltip;
        dateAxisTooltip.background.fill = am4core.color("#0070cc");
        dateAxisTooltip.stroke = am4core.color("#fff");
        dateAxisTooltip.background.strokeWidth = 0;
        dateAxisTooltip.background.cornerRadius = 3;
        dateAxisTooltip.background.pointerLength = 0;
        dateAxisTooltip.dy = 5;

        //configura a cor do tooltip y
        const valueAxisTooltipP = valueAxisP.tooltip;
        valueAxisTooltipP.background.fill = am4core.color("#0070cc");
        valueAxisTooltipP.stroke = am4core.color("#fff");
        valueAxisTooltipP.background.strokeWidth = 0;
        valueAxisTooltipP.background.cornerRadius = 3;
        valueAxisTooltipP.background.pointerLength = 0;
        valueAxisTooltipP.dy = 5;
        valueAxisP.renderer.line.strokeOpacity = 1;
        valueAxisP.renderer.line.strokeWidth = 2;

        //configura a cor do tooltip y
        const valueAxisTooltipQ = valueAxisQ.tooltip;
        valueAxisTooltipQ.background.fill = am4core.color("#0070cc");
        valueAxisTooltipQ.stroke = am4core.color("#fff");
        valueAxisTooltipQ.background.strokeWidth = 0;
        valueAxisTooltipQ.background.cornerRadius = 3;
        valueAxisTooltipQ.background.pointerLength = 0;
        valueAxisTooltipQ.dy = 5;
        valueAxisQ.renderer.line.strokeOpacity = 1;
        valueAxisQ.renderer.line.strokeWidth = 2;

        // Configura dados
        chart.data = lista.map((leitura) => {
            const { p, q, data_hora } = leitura;
            return { p, q, data_hora: new Date(data_hora) };
        });

        // Customiza botões da scrollbar
        customizeChartGrip(chart.scrollbarX.startGrip);
        customizeChartGrip(chart.scrollbarX.endGrip);

        return setGrafico(chart);
    }

    function voltarAvancarDataGrafico({ dataGrafico, tempo, modoTela, setDataGrafico }) {
        const novaData = new Date(dataGrafico);
        switch (modoTela) {
            case "lista": //usado no gráfico admin que é diário também
                novaData.setUTCDate(novaData.getUTCDate() + tempo);
                break;
            case "graficoDia":
                novaData.setUTCDate(novaData.getUTCDate() + tempo);
                setDataGrafico(novaData.toISOString().substring(0, 10));
                break;
            default:
                break;
        }
    }

    function atualizarListasGraficos(dataInicial, dataFinal) {
        if (!(registro.consumoLeitura || {}).id_consumo_integracao) return;
        const filtro = {
            id_consumo_integracao: registro.consumoLeitura.id_consumo_integracao,
            dataInicial,
            dataFinal,
        };
        switch (modoTela) {
            case "graficoDia":
                getListaGraficoDia(filtro);
                break;
            default:
                break;
        }
    }

    //deixa botoes da barra de rolagem do gráfico quadrados
    function customizeChartGrip(grip) {
        // Remove default grip image
        grip.icon.disabled = true;

        // Disable background
        grip.background.disabled = true;

        // Add rotated rectangle as bi-di arrow
        const img = grip.createChild(am4core.Rectangle);
        img.width = 15;
        img.height = 15;
        img.fill = am4core.color("#0070cc");
        img.stroke = am4core.color("#0070cc");
        img.rotation = 45;
        img.align = "center";
        img.valign = "middle";

        // Add vertical bar
        const line = grip.createChild(am4core.Rectangle);
        line.height = 60;
        line.width = 3;
        line.fill = am4core.color("#0070cc");
        line.stroke = am4core.color("#0070cc");
        line.align = "center";
        line.valign = "middle";
    }
}

const mapStateToProps = (state) => {
    return {
        usuarioLogado: state.auth.usuarioLogado,
        aguardando: state.monitoramentoConsumo.aguardando,
        modoTela: state.monitoramentoConsumo.modoTela,
        filtro: state.monitoramentoConsumo.filtro,
        registro: state.monitoramentoConsumo.registro,
        listaCliente: state.monitoramentoConsumo.listaCliente,
        listaUnidadeConsumidora: state.monitoramentoConsumo.listaUnidadeConsumidora,
        listaDemandaContagem: state.monitoramentoConsumo.listaDemandaContagem,
        listaDemandaContratoTipo: state.monitoramentoConsumo.listaDemandaContratoTipo,
        dataInicialGrafico: state.monitoramentoConsumo.dataInicialGrafico,
        dataFinalGrafico: state.monitoramentoConsumo.dataFinalGrafico,
        listaGraficoDia: state.monitoramentoConsumo.listaGraficoDia,
        listaGraficoMes: state.monitoramentoConsumo.listaGraficoMes,
        listaGraficoAno: state.monitoramentoConsumo.listaGraficoAno,
    };
};
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setFiltro,
            setAguardando,
            setModoTela,
            setDataInicialGrafico,
            setDataFinalGrafico,
            getDemandaContagem,
            carregarListas,
            getListaGraficoDia,
            getListaGraficoTodasIntegracoesAgrupadasPorHora,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(MonitoramentoConsumo);
