import React from "react";
import Grid from "../layout/grid";
import DateFormat from "../dateFormat/DateFormat";

export default (props) => {
	return (
		<Grid cols={props.cols}>
			<div className="form-group">
				<label htmlFor={props.name}>{props.label}</label>
				{props.onChange ? (
					<input
						className="form-control"
						{...props.input}
						style={{
							...props.style,
						}}
						required
						type="date"
						disabled={!!props.readOnly}
						value={(props.input || props).value}
						max={props.liberarTodasDatas ? null : DateFormat.getDataAtual().replace(/\//g, "-")}
						onFocus={(e) => (e.target.style.outline = "none")}
						onChange={props.onChange}
					></input>
				) : (
					<input
						className="form-control"
						{...props.input}
						style={{
							...props.style,
						}}
						required
						type="date"
						disabled={!!props.readOnly}
						value={(props.input || props).value}
						max={props.liberarTodasDatas ? null : DateFormat.getDataAtual().replace(/\//g, "-")}
						onFocus={(e) => (e.target.style.outline = "none")}
					></input>
				)}
			</div>
		</Grid>
	);
};
