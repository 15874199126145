import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import locale from "@amcharts/amcharts4/lang/pt_BR.js";

function montarGraficoPizza({
	state,
	component,
	nome,
	dados = [],
	category,
	value,
	titulo,
	prefixo,
	sufixo,
	chartBackground = "#30303d",
	fontSizeTitle = 16,
	fontWeightTitle = "normal",
}) {
	if (component) {
		component.dispose();
	}

	let chart = am4core.create(nome, am4charts.PieChart3D);
	chart.colors.step = 1;

	chart.language.locale = locale;

	let title = chart.titles.create();
	title.text = titulo;
	title.fontSize = fontSizeTitle;
	title.fontWeight = fontWeightTitle;
	title.marginTop = 20;
	title.marginBottom = titulo ? 30 : 0;

	chart.exporting.menu = new am4core.ExportMenu();
	chart.exporting.filePrefix = "Gráfico";
	chart.exporting.menu.align = "right";
	chart.exporting.menu.verticalAlign = "top";
	chart.exporting.menu.items = [
		{
			label: "...",
			menu: [
				{
					type: "png",
					label: "PNG",
				},
			],
		},
	];

	// chart.legend.paddingBottom = 20;
	// chart.legend.labels.template.maxWidth = 95;
	chart.background.fill = chartBackground;
	chart.language.locale["_decimalSeparator"] = ",";
	chart.language.locale["_thousandSeparator"] = ".";
	chart.numberFormatter.numberFormat = "###,###,###,###.00";
	chart.hiddenState.properties.opacity = 0;

	let series = chart.series.push(new am4charts.PieSeries3D());
	series.dataFields.value = value;
	series.dataFields.category = category;
	series.labels.template.paddingBottom = 20;
	series.labels.template.text = `{category}:\n{value.percent.formatNumber('#.0')}% - ${prefixo} {value.formatNumber("###,###,###,###.00")} ${sufixo}`;
	series.colors.list = state.cores;

	series.slices.template.tooltipText = `{category}: [bold]${prefixo} {value.formatNumber("###,###,###,###.00")} ${sufixo}[/]`;

	chart.legend = new am4charts.Legend();
	chart.legend.position = "top";
	chart.legend.layout = "vertical";
	chart.legend.valueLabels.template.text = "";

	chart.data = [...dados];

	return chart;
}

export { montarGraficoPizza };
