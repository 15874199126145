//retorna todas UC em que a data de desligamento for igual ou superior a competencia recebida por parametro
function filtrarUcPorDataDesligamentoCompetencia({
	listaUnidadeConsumidora, //lista a ser filtrada
	ano, //ano do filtro
	mes, //mes do filtro
}) {
	try {
		return listaUnidadeConsumidora.filter((uc) => {
			if (!uc.data_desligamento || !mes || !ano) return true;
			const competenciaFiltro = parseInt(ano + "" + mes);
			if (isNaN(parseInt(ano)) || String(ano).length != 4 || String(ano).toLowerCase() != String(ano).toUpperCase()) {
				throw new Error("O ano informado na função de filtragem por data de desligamento é inválido, a lista não será filtrada.");
			}
			if (isNaN(parseInt(mes)) || String(mes).length != 2 || String(mes).toLowerCase() != String(mes).toUpperCase()) {
				throw new Error("O mês informado na função de filtragem por data de desligamento é inválido, a lista não será filtrada.");
			}
			const competenciaDataDesligamento = parseInt(String(uc.data_desligamento).substring(0, 7).replace("-", ""));
			if (
				isNaN(parseInt(competenciaDataDesligamento)) ||
				String(competenciaDataDesligamento).length != 6 ||
				String(competenciaDataDesligamento).toLowerCase() != String(competenciaDataDesligamento).toUpperCase()
			) {
				throw new Error("A data de desligamento da UC é inválida, a lista não será filtrada.");
			}
			return competenciaDataDesligamento >= competenciaFiltro;
		});
	} catch (erro) {
		// console.log(erro.message);
		return listaUnidadeConsumidora;
	}
}
export { filtrarUcPorDataDesligamentoCompetencia };
