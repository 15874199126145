const INITIAL_STATE = {
    registro: {},
    aguardando: false,
    modoTela: "lista",
    lista: [],
    listaConsumoIntegracaoTipos: [],
    listaCliente: [],
    listaUnidadeConsumidora: [],
    filtro: {
        id_cliente: null,
        id_unidade_consumidora: null,
    },
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "CONSUMO_INTEGRACAO_MODO_TELA":
            return {
                ...state,
                modoTela: action.payload.modo,
                registro: action.payload.registro,
            };

        case "CONSUMO_INTEGRACAO_SET_AGUARDANDO":
            return { ...state, aguardando: action.payload };

        case "CONSUMO_INTEGRACAO_FILTRO":
            return { ...state, filtro: action.payload };

        case "CONSUMO_INTEGRACAO_LISTADO":
            return {
                ...state,
                lista: action.payload.data,
            };

        case "CONSUMO_INTEGRACAO_CLIENTE_SELECT_LISTADO":
            return {
                ...state,
                listaCliente: action.payload.data,
            };

        case "CONSUMO_INTEGRACAO_UNIDADE_CONSUMIDORA_SELECT_LISTADO":
            return {
                ...state,
                listaUnidadeConsumidora: action.payload.data,
            };

        case "CONSUMO_INTEGRACAO_TIPOS_SELECT_LISTADO":
            return {
                ...state,
                listaConsumoIntegracaoTipos: action.payload.data,
            };

        default:
            return state;
    }
};
