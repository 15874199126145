const INITIAL_STATE = {
    registro: {},
    modoTela: "lista",
    filtro: {
        ano: null,
        competencia_inicial: null,
        competencia_final: null,
        id_cliente: null,
        id_unidade_consumidora: null,
        mes_base: 1,
        id_tipo_contrato_demanda: 1, //padrão traz somente fora ponta
    },
    listaCliente: [],
    listaUnidadeConsumidora: [],
    listaDemandaContratoTipo: [],
    contratoDemanda: null,
    listaDemandas: [],
    listaDemandaContagem: [],
    listaGraficoCustoAnual: [],
    listaGraficoGeracaoQuantidadeAnual: [],
    listaGraficoCustoUnidadeUltimoMes: [],
    listaGraficoCustoPorUnidadeAcumuladoAno: [],
    listaConsumoPercentualPorHorarioUltimoMes: [],
    listaGraficoGeracaoAvaliacaoProducaoAnual: [],
    listaMesBase: [
        { id: 1, valor: "Mês Atual" },
        { id: 2, valor: "Mês Anterior" },
    ],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "DASHBOARD_FILTRO":
            return { ...state, filtro: action.payload };

        case "DASHBOARD_CLIENTE_SELECT_LISTADO":
            return {
                ...state,
                listaCliente: action.payload.data,
            };

        case "DASHBOARD_UNIDADE_CONSUMIDORA_SELECT_LISTADO":
            return {
                ...state,
                listaUnidadeConsumidora: action.payload.data,
            };
        case "DASHBOARD_CONTRATO_DEMANDA_TIPO_SELECT_LISTADO":
            return {
                ...state,
                listaDemandaContratoTipo: action.payload.data,
            };
        case "DASHBOARD_DEMANDA_LISTADO":
            return {
                ...state,
                contratoDemanda: action.payload ? action.payload.vigente : null,
                listaDemandas: action.payload ? action.payload.demandas : [],
            };

        case "DASHBOARD_DEMANDA_CONTAGEM_LISTADO":
            return {
                ...state,
                listaDemandaContagem: action.payload.data,
            };

        case "DASHBOARD_GRAFICO_CUSTO_ANUAL_LISTADO":
            return {
                ...state,
                listaGraficoCustoAnual: action.payload.data,
            };

        case "DASHBOARD_GRAFICO_GERACAO_QUANTIDADE_ANUAL_LISTADO":
            return {
                ...state,
                listaGraficoGeracaoQuantidadeAnual: action.payload.data,
            };

        case "DASHBOARD_GRAFICO_CUSTO_UNIDADE_ULTIMO_MES_LISTADO":
            return {
                ...state,
                listaGraficoCustoUnidadeUltimoMes: action.payload.data,
            };

        case "DASHBOARD_GRAFICO_CUSTO_UNIDADE_ACUMULADO_ANO_LISTADO":
            return {
                ...state,
                listaGraficoCustoPorUnidadeAcumuladoAno: action.payload.data,
            };

        case "DASHBOARD_GRAFICO_CONSUMO_PERCENTUAL_POR_HORARIO_MES_LISTADO":
            return {
                ...state,
                listaConsumoPercentualPorHorarioUltimoMes: action.payload.data,
            };

        case "DASHBOARD_GRAFICO_GERACAO_AVALIACAO_PRODUCAO_LISTADO":
            return {
                ...state,
                listaGraficoGeracaoAvaliacaoProducaoAnual: action.payload.data,
            };

        default:
            return state;
    }
};
