import axios from "axios";
import consts from "../consts";
import { reset as resetForm, initialize } from "redux-form";
import { setErroAPI, setError, setSuccess } from "../common/toast/toast";

export function setModoTela(modo, registro = {}) {
	return {
		type: "EMPENHO_MODO_TELA",
		payload: {
			modo: modo,
			registro: registro,
		},
	};
}

export function setAguardando(aguardando) {
	return {
		type: "EMPENHO_SET_AGUARDANDO",
		payload: aguardando,
	};
}

export function initForm(registro = {}) {
	return [initialize("empenhoForm", registro)];
}

export function setFiltro(filtro) {
	return {
		type: "EMPENHO_FILTRO",
		payload: filtro,
	};
}

export function salvar(registro) {
	return async (dispatch, getState) => {
		const registroFormatado = JSON.parse(JSON.stringify(registro));
		const idUsuarioLogado = getState().auth.usuarioLogado.id;

		registroFormatado.valor_previsto = parseFloat(String(registroFormatado.valor_previsto).replace(",", "."));
		registroFormatado.unidades_consumidoras = (registroFormatado.unidades_consumidoras || []).map((uc) => uc.value); // pega só os id
		try {
			if (!registroFormatado.id) {
				registroFormatado.id_usuario_inclusao = idUsuarioLogado;
				registroFormatado.id_usuario_alteracao = idUsuarioLogado;
				dispatch(setAguardando(true));
				await axios.post(`${consts.API_URL}/empenho`, registroFormatado);
				setSuccess("Operação Realizada com sucesso.");
				dispatch(getLista());
				dispatch(setModoTela("lista"));
			} else {
				registroFormatado.id_usuario_alteracao = idUsuarioLogado;
				if (!window.confirm("Deseja realmente alterar?")) return {};
				dispatch(setAguardando(true));
				await axios.put(`${consts.API_URL}/empenho`, registroFormatado);
				setSuccess("Operação Realizada com sucesso.");
				dispatch(getLista());
				dispatch(setModoTela("lista"));
			}
		} catch (erro) {
			setErroAPI(erro);
		} finally {
			dispatch(setAguardando(false));
		}
	};
}

export function excluir(registro) {
	return async (dispatch, getState) => {
		try {
			const idUsuarioLogado = getState().auth.usuarioLogado.id;
			if (!window.confirm("Deseja realmente excluir?")) return {};
			dispatch(setAguardando(true));
			await axios.delete(`${consts.API_URL}/empenho?id=${registro.id}&id_usuario_alteracao=${idUsuarioLogado}`);
			setSuccess("Operação Realizada com sucesso.");
			dispatch(getLista());
			dispatch(setModoTela("lista"));
		} catch (error) {
			setErroAPI(error);
		} finally {
			dispatch(setAguardando(false));
		}
	};
}

export function getLista(id_cliente = null, ano_referencia = null) {
	return async (dispatch, getState) => {
		try {
			dispatch(setAguardando(true));
			const resp = await axios.get(`${consts.API_URL}/empenho?id_cliente=${id_cliente}&ano_referencia=${ano_referencia}`);
			dispatch({
				type: "EMPENHO_LISTADO",
				payload: resp,
			});
		} catch (error) {
			setErroAPI(error);
		} finally {
			dispatch(setAguardando(false));
		}
	};
}

export function getListaCliente() {
	const request = axios.get(`${consts.API_URL}/cliente/listarSelect`);
	return {
		type: "EMPENHO_CLIENTE_SELECT_LISTADO",
		payload: request,
	};
}

export function getListaUnidadeConsumidora() {
	const request = axios.get(`${consts.API_URL}/unidadeConsumidora/listarSelect`);
	return {
		type: "EMPENHO_UNIDADE_CONSUMIDORA_SELECT_LISTADO",
		payload: request,
	};
}
