import { toastr } from "react-redux-toastr";
import { reset as resetForm, initialize } from "redux-form";
import axios from "axios";
import consts from "../consts";
import { setError } from "../common/toast/toast";

export function initForm(registro = {}) {
	return [initialize("authForm", registro)];
}

export function login(values) {
	return submit(values, `${consts.OAPI_URL}/usuario/login`);
}

export function signup(values) {
	return submit(values, `${consts.OAPI_URL}/signup`);
}

function submit(values, url) {
	return async (dispatch) => {
		try {
			const resp = await axios.post(url, values);
			dispatch([{ type: "USER_FETCHED", payload: resp.data }]);
		} catch (e) {
			if (e.response.status == 400) {
				if (e.response.data instanceof Array) {
					e.response.data.forEach((retorno) => {
						dispatch([
							{
								type: "USER_MESSEGE",
								payload: {
									open: true,
									text: retorno.msg ? retorno.msg : retorno,
									time: 5000,
									type: "error",
								},
							},
						]);
						setTimeout(() => {
							dispatch([
								{
									type: "USER_MESSEGE",
									payload: {
										open: false,
										text: "",
										time: 5000,
										type: "error",
									},
								},
							]);
						}, 6000);
					});
				} else {
					dispatch([
						{
							type: "USER_MESSEGE",
							payload: {
								open: true,
								text: e.response.data,
								time: 5000,
								type: "error",
							},
						},
					]);
					setTimeout(() => {
						dispatch([
							{
								type: "USER_MESSEGE",
								payload: {
									open: false,
									text: "",
									time: 5000,
									type: "error",
								},
							},
						]);
					}, 6000);
				}
			} else {
				dispatch([
					{
						type: "USER_MESSEGE",
						payload: {
							open: true,
							text: "Erro ao realizar Operação!!",
							time: 5000,
							type: "error",
						},
					},
				]);
				setTimeout(() => {
					dispatch([
						{
							type: "USER_MESSEGE",
							payload: {
								open: false,
								text: "",
								time: 5000,
								type: "error",
							},
						},
					]);
				}, 6000);
			}
		} finally {
		}
	};
}

export function logout() {
	window.location.reload();
	return { type: "TOKEN_VALIDATED", payload: false };
}

export function validateToken(token) {
	return async (dispatch) => {
		try {
			const resp = await axios.post(`${consts.OAPI_URL}/usuario/validateToken`, { token });
			dispatch({
				type: "TOKEN_VALIDATED",
				payload: { valid: resp.data.valid, usuario: resp.data.usuario },
			});
		} catch (e) {
			dispatch({
				type: "TOKEN_VALIDATED",
				payload: { valid: false, usuario: {} },
			});
		} finally {
		}
	};
}

export function buscarSessao() {
	return async (dispatch, getState) => {
		const url = `${consts.API_URL}/usuario/sessao?
		id=${getState().auth && getState().auth.usuario && getState().auth.usuario.id ? getState().auth.usuario.id : null}`;

		try {
			const resp = await axios.get(url);
			dispatch({
				type: "USER_SESSAO",
				payload: resp.data,
			});
		} catch (ex) {
			setError("Sessão não encontrada");
		} finally {
		}
	};
}
