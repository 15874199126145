import React, { useEffect, useState } from "react";
import leaflet from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Popup, Marker, useMapEvents } from "react-leaflet";
import "./MapLeaflet.css";

export function MapLeaflet(props) {
	const updateForm = props.onChange;

	//se vier coordenadas nas props atribui, senão atribui as coordenadas do centro do estado
	const [coordenadas, setCoordinates] = useState({
		latitude: props.latitude || -29.895971,
		longitude: props.longitude || -53.0622382,
		zoom: props.latitude && props.longitude ? 12 : 6,
	});

	//ao clicar no mapa altera o valor das coordenadas do state do componente e também no formulário
	const MapEvents = () => {
		useMapEvents({
			click(e) {
				setCoordinates({
					latitude: e.latlng.lat + "",
					longitude: e.latlng.lng + "",
				});
				updateForm({
					latitude: e.latlng.lat + "",
					longitude: e.latlng.lng + "",
				});
			},
		});
		return false;
	};
	useEffect(() => {
		//configura o ícone de marcação
		delete leaflet.Icon.Default.prototype._getIconUrl;
		leaflet.Icon.Default.mergeOptions({
			iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
			iconUrl: require("leaflet/dist/images/marker-icon.png"),
			shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
		});

		//só pega a localização se estiver cadastrando uma integração nova
		if (!props.getCurrentPosition || !props.latitude || !props.longitude) return;
		navigator.geolocation.getCurrentPosition(
			(position) => {
				setCoordinates({
					latitude: position.coords.latitude,
					longitude: position.coords.longitude,
					zoom: 12,
				});
				updateForm({
					latitude: position.coords.latitude,
					longitude: position.coords.longitude,
				});
			},
			(error) => {
				setCoordinates({
					latitude: -29.895971,
					longitude: -53.0622382,
					zoom: 6,
				});
			}
		);
	}, []);
	return (
		<div className="map-container border">
			<MapContainer
				center={[coordenadas.latitude, coordenadas.longitude]}
				zoom={coordenadas.zoom}
				scrollWheelZoom={true}
				style={{ height: "100%", width: "100%" }}
			>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				<MapEvents />
				<Marker position={[coordenadas.latitude, coordenadas.longitude]}>
					<Popup>
						latitude:{coordenadas.latitude}
						<br />
						longitude:
						{coordenadas.longitude}
						<br />
					</Popup>
				</Marker>
			</MapContainer>
		</div>
	);
}
