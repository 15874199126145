import axios from "axios";
import consts from "../consts";
import { setErroAPI, setSuccess } from "../common/toast/toast";
import { setArquivoPDF } from "../displayPDF/displayPDFActions";

export function setFiltro(filtro) {
    return {
        type: "ANALISE_SET_FILTRO",
        payload: filtro,
    };
}

export function setAguardando(aguardando) {
    return {
        type: "ANALISE_SET_AGUARDANDO",
        payload: aguardando,
    };
}

export function setTabSelecionada(tab) {
    return {
        type: "ANALISE_TAB_SELECIONADA",
        payload: tab,
    };
}

export function setAbas(abas) {
    return {
        type: "ANALISE_ABAS_CARREGADA",
        payload: abas,
    };
}

export async function getListaEmpenho() {
    return async (dispatch, getState) => {
        try {
            dispatch(setAguardando(true));

            const filtroIdCliente = getState().analise.filtro.id_cliente;
            const filtroAno = getState().analise.filtro.ano_referencia;

            //se não tiver filtro de cliente não retorna nada
            if (!filtroIdCliente) {
                return {
                    type: "ANALISE_TABELA_GESTAO_EMPENHO_LISTADO",
                    payload: { data: [] },
                };
            }

            const url = `${consts.API_URL}/analiseEmpenho?id_cliente=${filtroIdCliente}&ano_referencia=${filtroAno}`;
            const resposta = await axios.get(url);
            dispatch({
                type: "ANALISE_TABELA_GESTAO_EMPENHO_LISTADO",
                payload: resposta,
            });
        } catch (erro) {
            setErroAPI(erro);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export async function getDemandaContagem() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_DEMANDA_CONTAGEM_LISTADO",
                payload: { data: [] },
            });

            dispatch({
                type: "ANALISE_AGENDAMENTO_DEMANDA",
                payload: { data: [] },
            });

            //se não ta logado sai fora
            if (!getState().auth.usuarioLogado) return {};
            const resp = await axios.get(
                `${consts.API_URL}/demandaContrato/listarContagemComAgendamentoDemanda?
			id_cliente=${getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || 0}&id_tipo_contrato_demanda=${
                    getState().analise.filtro.id_tipo_contrato_demanda || 0
                }`
            );

            //garante que "agendamentos" vai ser um array
            const listaDemandaComAgendamento = (resp.data || []).map((demanda) => {
                return {
                    ...demanda,
                    agendamentos: demanda.agendamentos || [],
                };
            });

            if (getState().analise.filtro.unidades_consumidoras.length == 1) {
                const idUcSelecionada = getState().analise.filtro.unidades_consumidoras[0].value;
                const demanda = resp.data.find((demanda) => demanda.id_unidade_consumidora == idUcSelecionada) || {};
                const idTipoContratoDemanda = getState().analise.filtro.id_tipo_contrato_demanda || 0;
                const listaAgendamentoDemanda = await getListaAgendamento(demanda.id, idTipoContratoDemanda);
                listaAgendamentoDemanda.forEach((agendamento, indice, lista) => {
                    const ano = String(agendamento.competencia_inicio_vigencia).substring(0, 4);
                    const mes = String(agendamento.competencia_inicio_vigencia).substring(4, 6);

                    const agendamentoOrdenado = {};

                    //ordernar conforme mes de inicio, para que inicie em janeiro na tabela
                    for (let i = demanda.mes_inicio, j = 1; j <= 12; j++, i++) {
                        if (i > 12) {
                            i = 1;
                        }
                        agendamentoOrdenado[`valor_${i}`] = agendamento[`valor_${j}`];
                    }
                    agendamentoOrdenado.id_demanda_contrato = demanda.id;
                    agendamentoOrdenado.competencia_inicio_vigencia = agendamento.competencia_inicio_vigencia;
                    agendamentoOrdenado.competencia_formatada = `Agendado ${mes}/${ano}`;
                    agendamentoOrdenado.mensagem_tooltip = `Início previsto para ${mes}/${ano}`;

                    lista[indice] = { ...agendamentoOrdenado };
                });
                listaAgendamentoDemanda.sort((a, b) => a.competencia_inicio_vigencia - b.competencia_inicio_vigencia);

                dispatch({
                    type: "ANALISE_AGENDAMENTO_DEMANDA",
                    payload: { data: listaAgendamentoDemanda },
                });
            }

            dispatch({
                type: "ANALISE_DEMANDA_CONTAGEM_LISTADO",
                payload: { data: listaDemandaComAgendamento },
            });
        } catch (erro) {
            setErroAPI(erro);
        }
    };
}

async function getListaAgendamento(idDemanda, idTipoContratoDemanda) {
    try {
        const agendamentos = await axios.get(
            `${consts.API_URL}/demandaAgendamento?id_demanda_contrato=${idDemanda || 0}&id_tipo_contrato_demanda=${idTipoContratoDemanda}&executado=false`
        );
        return agendamentos.data || [];
    } catch (erro) {
        setErroAPI(erro);
        return [];
    }
}

export function excluirPeriodoTeste(registro) {
    return (dispatch, getState) => {
        if (window.confirm(`Deseja realmente excluir o período de teste?`)) {
            axios
                .delete(`${consts.API_URL}/demandaContrato/periodoTeste/?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getDemandaContagem());
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        }
    };
}

export function excluirAgendamento(registro) {
    return (dispatch, getState) => {
        if (window.confirm(`Deseja realmente excluir o agendamento de ${registro.competencia_agendamento}?`)) {
            axios
                .delete(`${consts.API_URL}/demandaAgendamento?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getDemandaContagem());
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        }
    };
}


function filtrarIdsUnidadesConsumidoras(state) {
    const filtroGrupoFaturamento = state.filtro.grupos_faturamento.map((item) => parseInt(item.value)); //apenas os ids do filtro
    //se selecionou alguma UC manda a lista de ids das UCs selecionadas
    let ids_unidades_consumidoras = state.filtro.unidades_consumidoras.map((item) => item.value);
    if (ids_unidades_consumidoras.length) {
        return ids_unidades_consumidoras;
    }
    //se não tiver nenhuma UC selecionada, filtra a lista de UC original de acordo com os filtros selecionados
    const listaUCs = state.listaUnidadeConsumidora
        .filter(
            (item) =>
                (!state.filtro.id_cliente || item.id_cliente == state.filtro.id_cliente) &&
                (state.tabSelecionada === "demanda"
                    ? [1,4].includes(parseInt(item.id_grupo_faturamento)) || (item.id_grupo_faturamento === 3 && item.irrigante)
                    : (!filtroGrupoFaturamento.length || filtroGrupoFaturamento.includes(item.id_grupo_faturamento)) &&
                      ((state.filtro.irrigante && item.irrigante) || (state.filtro.naoIrrigante && !item.irrigante)))
        )
        .map((item) => item.id);

    //se após filtrar as UCs a lista acabar vazia, significa que nenhuma UC se enquadra no filtro
    //entao adiciona retorna uma lista contendo o id 0, que é inválido, para que não traga nenhuma informação
    return listaUCs.length ? listaUCs : [0];
}

export async function getListaGraficoValorDevolvidoAnual() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_DEVOLUCAO_ANUAL_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const usuarioLogado = getState().auth.usuarioLogado || {};
            const modoSafra = state.filtro.devolucao_ano_safra;

            //se for tipo safra os meses iniciam em junho no gráfico
            const endpoint = modoSafra ? "listarGraficoValorDevolvidoSafra" : "listarGraficoValorDevolvidoAnual";

            //valida o id do cliente
            const id_cliente = usuarioLogado.acesso_cliente ? usuarioLogado.id_cliente : state.filtro.id_cliente || 0;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/${endpoint}?id_cliente=${id_cliente}&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}`
            );

            //se o ano/safra atual do filtro não existe na lista do novo cliente altera o ano/safra para o último período da lista
            const anosSelect = resp.data.map((item) => (modoSafra ? item.ano_inicio_fim_safra.split("/")[0] : item.ano));
            if (!anosSelect.find((item) => item == state.filtro.ano_devolucao)) {
                let anoFiltro = anosSelect.length ? anosSelect[anosSelect.length - 1] : new Date().getFullYear();
                dispatch(
                    setFiltro({
                        ...state.filtro,
                        ano_devolucao: anoFiltro,
                        competencia_inicial_comparativo_devolucao_ressarcimento: modoSafra ? `${anoFiltro}06` : `${anoFiltro}01`,
                        competencia_final_comparativo_devolucao_ressarcimento: modoSafra ? `${parseInt(anoFiltro) + 1}05` : `${anoFiltro}12`,
                        competencia_inicial_devolucao_por_uc: modoSafra ? `${anoFiltro}06` : `${anoFiltro}01`,
                        competencia_final_devolucao_por_uc: modoSafra ? `${parseInt(anoFiltro) + 1}05` : `${anoFiltro}12`,
                    })
                );
            }
            dispatch({
                type: "ANALISE_GRAFICO_DEVOLUCAO_ANUAL_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoValorDevolvidoMensalAno() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_DEVOLUCAO_MENSAL_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const usuarioLogado = getState().auth.usuarioLogado || {};
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            //se for tipo safra os meses iniciam em junho no gráfico
            const endpoint = state.filtro.devolucao_ano_safra ? "listarGraficoValorDevolvidoMensalSafra" : "listarGraficoValorDevolvidoMensalAno";

            //valida o id do cliente
            const id_cliente = usuarioLogado.acesso_cliente ? usuarioLogado.id_cliente : state.filtro.id_cliente || 0;

            const resp = await axios.get(
                `${consts.API_URL}/fatura/${endpoint}?
			id_cliente=${id_cliente}&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}&ano=${state.filtro.ano_devolucao}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_DEVOLUCAO_MENSAL_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoValorDevolvidoRessarcidoPeriodo() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_DEVOLUCAO_RESSARCIMENTO_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const usuarioLogado = getState().auth.usuarioLogado || {};

            //valida o id do cliente
            const id_cliente = usuarioLogado.acesso_cliente ? usuarioLogado.id_cliente : state.filtro.id_cliente || 0;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoValorDevolvidoRessarcidoPeriodo?id_cliente=${id_cliente}&ids_unidades_consumidoras=${encodeURIComponent(
                    ids_unidades_consumidoras
                )}&competencia_inicial=${state.filtro.competencia_inicial_comparativo_devolucao_ressarcimento}&competencia_final=${
                    state.filtro.competencia_final_comparativo_devolucao_ressarcimento
                }`
            );

            dispatch({
                type: "ANALISE_GRAFICO_DEVOLUCAO_RESSARCIMENTO_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoValorDevolvidoPorUC() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_DEVOLUCAO_POR_UC_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const usuarioLogado = getState().auth.usuarioLogado || {};

            //valida o id do cliente
            const id_cliente = usuarioLogado.acesso_cliente ? usuarioLogado.id_cliente : state.filtro.id_cliente || 0;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoValorDevolvidoPorUC?id_cliente=${id_cliente}&ids_unidades_consumidoras=${encodeURIComponent(
                    ids_unidades_consumidoras
                )}&competencia_inicial=${state.filtro.competencia_inicial_devolucao_por_uc}&competencia_final=${state.filtro.competencia_final_devolucao_por_uc}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_DEVOLUCAO_POR_UC_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListarGraficoCustoJurosPorAtrasoPagamentoAnual() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_JUROS_POR_ATRASO_PAGAMENTO_ANUAL_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const modoSafra = state.filtro.custo_ano_safra;
            const idCliente = getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null;

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoJurosPorAtrasoPagamentoAnual?id_cliente=${idCliente}&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}&periodo_safra=${modoSafra}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_JUROS_POR_ATRASO_PAGAMENTO_ANUAL_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListarGraficoCustoJurosPorAtrasoPagamentoMensal() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_JUROS_POR_ATRASO_PAGAMENTO_MENSAL_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const modoSafra = state.filtro.custo_ano_safra;
            const idCliente = getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null;

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoJurosPorAtrasoPagamentoMensal?id_cliente=${idCliente}&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}&periodo_safra=${modoSafra}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_JUROS_POR_ATRASO_PAGAMENTO_MENSAL_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoCustoAnual() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_ANUAL_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const modoSafra = state.filtro.custo_ano_safra;
            const idCliente = getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null;

            const resp = await axios.get(`${consts.API_URL}/fatura/listarGraficoCusto?id_cliente=${idCliente}&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}&periodo_safra=${modoSafra}`);

            let anosSelect = [];
            resp.data.forEach((item) => {
                item.anos.forEach((ano, i, array) => {
                    const objAno = modoSafra ? { ...ano, ano: String(ano.ano).replace("-", "/") } : ano;
                    array[i] = objAno;
                    const anoFormatado = String(objAno.ano).includes("/") ? String(objAno.ano).split("/")[0] : String(objAno.ano);
                    if (!anosSelect.find((itemAno) => itemAno == anoFormatado)) {
                        anosSelect.push(anoFormatado);
                    }
                });
            });
            //ordena a lista de anos
            anosSelect.sort((a, b) => {
                return modoSafra ? a.split("/")[0] - b.split("/")[0] : a - b;
            });
            if (!anosSelect.find((item) => item == state.filtro.ano_custo)) {
                let anoFiltro = anosSelect.length ? anosSelect[anosSelect.length - 1] : new Date().getFullYear();
                let mesAtual = new Date().getMonth() + 1;
                mesAtual = mesAtual < 10 ? "0" + mesAtual : mesAtual;
                dispatch(
                    setFiltro({
                        ...state.filtro,
                        ano_custo: anoFiltro,
                        custo_ano_safra: modoSafra,
                        competencia_inicial_custo: modoSafra ? `${anoFiltro}06` : `${anoFiltro}01`,
                        competencia_final_custo: modoSafra ? `${parseInt(anoFiltro) + 1}05` : `${anoFiltro}12`,
                        competencia_inicial_unidade_custo: modoSafra ? `${anoFiltro}06` : `${anoFiltro}01`,
                        competencia_final_unidade_custo: modoSafra ? `${parseInt(anoFiltro) + 1}05` : `${anoFiltro}12`,
                        competencia_inicial_custo_comparativo_antes: modoSafra ? `${parseInt(anoFiltro) - 1}06` : `${parseInt(anoFiltro) - 1}01`,
                        competencia_final_custo_comparativo_antes: modoSafra ? `${anoFiltro}05` : `${parseInt(anoFiltro) - 1}${mesAtual}`,
                        competencia_inicial_custo_comparativo_depois: modoSafra ? `${anoFiltro}06` : `${anoFiltro}01`,
                        competencia_final_custo_comparativo_depois: modoSafra ? `${parseInt(anoFiltro) + 1}05` : `${anoFiltro}${mesAtual}`,
                        ano_acumulado_custo: anoFiltro,
                    })
                );
            }

            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_ANUAL_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoCustoAnualPeriodoSafra() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_ANUAL_PERIODO_SAFRA_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCusto?
			id_cliente=${getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null}
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&periodo_safra=${true}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_ANUAL_PERIODO_SAFRA_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoTarifaMedia() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_TARIFA_MEDIA_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoTarifaMedia?
			id_cliente=${getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null}
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_TARIFA_MEDIA_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}
export async function getListaGraficoTarifaMediaGeral() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_TARIFA_MEDIA_GERAL_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoTarifaMedia?
				id_cliente=${getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null}
				&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
				&geral=true`
            );
            dispatch({
                type: "ANALISE_GRAFICO_TARIFA_MEDIA_GERAL_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoCustoPorHorarioAnual() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_HORARIO_ANUAL_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoPorHorario?
			id_cliente=${getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null}
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&ano=${getState().analise.filtro.anoComparativoPizza || getState().analise.filtro.ano}
			&competencia_inicial=${getState().analise.filtro.competencia_inicial_horario}
			&competencia_final=${getState().analise.filtro.competencia_final_horario}`
            );
            const unidadeConsumidora = getState().analise.listaUnidadeConsumidora.find((item) => getState().analise.filtro.unidades_consumidoras.find((uc) => uc.value == item.id));

            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_HORARIO_ANUAL_LISTADO",
                payload: {
                    data: resp.data.map((item) => ({
                        mes: item.mes,
                        tipos: item.tipos.filter((tipo) => {
                            if (tipo.tipo == "Reservado") {
                                return !unidadeConsumidora || unidadeConsumidora.id_grupo_faturamento == 2 || unidadeConsumidora.irrigante;
                            } else {
                                return true;
                            }
                        }),
                    })),
                },
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}
export async function getListaGraficoConsumoPorHorarioAnual() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_HORARIO_ANUAL_LISTADO",
                payload: { data: [] },
            });
            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoConsumoPorHorario?
			id_cliente=${getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null}
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&competencia_inicial=${getState().analise.filtro.competencia_inicial_horario}
			&competencia_final=${getState().analise.filtro.competencia_final_horario}`
            );
            const unidadeConsumidora = getState().analise.listaUnidadeConsumidora.find((item) => getState().analise.filtro.unidades_consumidoras.find((uc) => uc.value == item.id));

            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_HORARIO_ANUAL_LISTADO",
                payload: {
                    data: resp.data.map((item) => ({
                        mes: item.mes,
                        tipos: item.tipos.filter((tipo) => {
                            if (tipo.tipo == "Reservado") {
                                return !unidadeConsumidora || unidadeConsumidora.id_grupo_faturamento == 2 || unidadeConsumidora.irrigante;
                            } else {
                                return true;
                            }
                        }),
                    })),
                },
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoCustoDemandaEnergiaConsumida() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_DEMANDA_ENERGIA_CONSUMIDA_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoDemandaEnergiaConsumida?
			id_cliente=${getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null}
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&ano=${getState().analise.filtro.anoComparativoPizza || getState().analise.filtro.ano}
			&competencia_inicial=${getState().analise.filtro.competencia_inicial_horario}
			&competencia_final=${getState().analise.filtro.competencia_final_horario}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_DEMANDA_ENERGIA_CONSUMIDA_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoConsumoPorHorarioMensal() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_HORARIO_MENSAL_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoConsumoPorHorario?
			id_cliente=${getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null}
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&ano=${getState().analise.filtro.anoComparativoPizza || getState().analise.filtro.ano}
			&competencia_inicial=${getState().analise.filtro.competencia_inicial_horario}
			&competencia_final=${getState().analise.filtro.competencia_final_horario}
            &periodo_safra=${state.filtro.horario_ano_safra}`
            );
            const unidadeConsumidora = getState().analise.listaUnidadeConsumidora.find((item) => getState().analise.filtro.unidades_consumidoras.find((uc) => uc.value == item.id));

            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_HORARIO_MENSAL_LISTADO",
                payload: {
                    data: resp.data.map((item) => ({
                        mes: item.mes,
                        tipos: item.tipos.filter((tipo) => {
                            if (tipo.tipo == "Reservado") {
                                return !unidadeConsumidora || unidadeConsumidora.id_grupo_faturamento == 2 || unidadeConsumidora.irrigante;
                            } else {
                                return true;
                            }
                        }),
                    })),
                },
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}
export async function getListaGraficoConsumoPonta() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_PONTA_LISTADO",
                payload: { data: [] },
            });
            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoConsumoPonta?
			id_cliente=${getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null}
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
            &periodo_safra=${state.filtro.horario_ano_safra}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_PONTA_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoConsumoForaPonta() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_FORA_PONTA_LISTADO",
                payload: { data: [] },
            });
            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoConsumoForaPonta?
			id_cliente=${getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null}
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&periodo_safra=${state.filtro.horario_ano_safra}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_FORA_PONTA_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}
export async function getListaGraficoConsumoReservado() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_RESERVADO_LISTADO",
                payload: { data: [] },
            });
            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoConsumoReservado?
			id_cliente=${getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null}
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&periodo_safra=${state.filtro.horario_ano_safra}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_RESERVADO_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getDemanda() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_DEMANDA_LISTADO",
                payload: null,
            });

            const usuarioLogado = getState().auth.usuarioLogado;
            const state = getState().analise;
            const idCliente = usuarioLogado && usuarioLogado.acesso_cliente ? usuarioLogado.id_cliente : state.filtro.id_cliente;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
            const resp = await axios.get(
                `${consts.API_URL}/demandaContrato?id_cliente=${idCliente}&id_tipo_contrato_demanda=${state.filtro.id_tipo_contrato_demanda}&ids_unidades_consumidoras=${encodeURIComponent(
                    ids_unidades_consumidoras
                )}&encerrado=false`
            );
            const demandas = resp.data || [];

            //só seta a vigente quando seleciona uma única UC no filtro
            let vigente = null;
            if (state.filtro.unidades_consumidoras.length == 1) {
                const idUcSelecionada = state.filtro.unidades_consumidoras[0].value;
                vigente = resp.data.find((demanda) => demanda.id_unidade_consumidora == idUcSelecionada) || null;
            }
            dispatch({
                type: "ANALISE_DEMANDA_LISTADO",
                payload: {
                    demandas,
                    vigente,
                },
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoDemanda(lista) {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_DEMANDA_LISTADO",
                payload: { data: lista || [] },
            });
            dispatch({
                type: "ANALISE_GRAFICO_DEMANDA_INDICE_SAZONALIDADE_LISTADO",
                payload: {data:[]},
            });
            const state = getState().analise;
            const usuarioLogado = getState().auth.usuarioLogado;
            const acessoCliente = usuarioLogado && usuarioLogado.acesso_cliente;
            const idCliente = acessoCliente ? usuarioLogado.id_cliente : state.filtro.id_cliente;
            if (!lista && idCliente) {
                let respostaListaGrafico = {data:[]};
                let respostaIdicesSazonalidade = {data:[]};

                const idTipoContratoDemanda = state.filtro.id_tipo_contrato_demanda || 0;
                const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

                //sazonal e não sazonal apenas quando tiver uma Única UC
                if ((ids_unidades_consumidoras || []).length == 1) {
                    const ucSelecionada = state.listaUnidadeConsumidora.find((uc) => uc.id == ids_unidades_consumidoras[0]);
                    const idUnidadeConsumidora = ucSelecionada.id;
                    //SE FOR UMA UC APENAS, TRAZ DADOS SAZONAL OU NÂO SAZONAL, DE ACORDO COM A UC
                    if (ucSelecionada.contrato_demanda_sazonal) {

                        respostaIdicesSazonalidade = await axios.get(`${consts.API_URL}/fatura/listarSazonalidadeAnualPorUcSazonal?id_cliente=${idCliente}&id_unidade_consumidora=${idUnidadeConsumidora}`);

                        //busca enpoint sazonal *fazer rota
                        respostaListaGrafico = await axios.get(
                            `${consts.API_URL}/fatura/listarGraficoDemandaSazonal?id_cliente=${idCliente}&id_unidade_consumidora=${idUnidadeConsumidora}&id_tipo_contrato_demanda=${idTipoContratoDemanda}`
                        );
                    } else {

                        respostaIdicesSazonalidade = await axios.get(`${consts.API_URL}/fatura/listarSazonalidadeAnualPorUcNaoSazonal?id_cliente=${idCliente}&id_unidade_consumidora=${idUnidadeConsumidora}`);

                        //busca enpoint nao sazonal *fazer rota
                        respostaListaGrafico = await axios.get(
                            `${consts.API_URL}/fatura/listarGraficoDemandaNaoSazonal?id_cliente=${idCliente}&id_unidade_consumidora=${idUnidadeConsumidora}&id_tipo_contrato_demanda=${idTipoContratoDemanda}`
                        );
                    }
                } else {
                    //se não tiver UC no filtro, ou tiver mais de uma, traz totalizadores apenas
                    respostaListaGrafico = await axios.get(
                        `${consts.API_URL}/fatura/listarGraficoDemandaNovo?id_cliente=${idCliente}&ids_unidades_consumidoras=${encodeURIComponent(
                            ids_unidades_consumidoras
                        )}&id_tipo_contrato_demanda=${idTipoContratoDemanda}`
                    );
                }
                dispatch({
                    type: "ANALISE_GRAFICO_DEMANDA_INDICE_SAZONALIDADE_LISTADO",
                    payload: respostaIdicesSazonalidade,
                });
                dispatch({
                    type: "ANALISE_GRAFICO_DEMANDA_LISTADO",
                    payload: respostaListaGrafico,
                });
            }
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoConsumoAnual(lista) {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_ANUAL_LISTADO",
                payload: { data: lista || [] },
            });

            const state = getState().analise;
            const modoSafra = state.filtro.consumo_ano_safra;
            const usuarioLogado = getState().auth.usuarioLogado;
            const acessoCliente = usuarioLogado && usuarioLogado.acesso_cliente;
            const idCliente = acessoCliente ? usuarioLogado.id_cliente : state.filtro.id_cliente;
            if (!lista && idCliente) {
                const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
                //sazonal e não sazonal apenas quando tiver uma Única UC

                const resp = await axios.get(`${consts.API_URL}/fatura/listarGraficoConsumo?id_cliente=${idCliente}&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}&periodo_safra=${modoSafra}`);

                let anosSelect = [];
                resp.data.forEach((item) => {
                    item.anos.forEach((ano, i, array) => {
                        const objAno = modoSafra ? { ...ano, ano: String(ano.ano).replace("-", "/") } : ano;
                        array[i] = objAno;
                        const anoFormatado = String(objAno.ano).includes("/") ? String(objAno.ano).split("/")[0] : String(objAno.ano);
                        if (!anosSelect.find((itemAno) => itemAno == anoFormatado)) {
                            anosSelect.push(anoFormatado);
                        }
                    });
                });
                //ordena a lista de anos
                anosSelect.sort((a, b) => {
                    return modoSafra ? a.split("/")[0] - b.split("/")[0] : a - b;
                });
                if (!anosSelect.find((item) => item == state.filtro.ano_consumo)) {
                    let anoFiltro = anosSelect.length ? anosSelect[anosSelect.length - 1] : new Date().getFullYear();
                    let mesAtual = new Date().getMonth() + 1;
                    mesAtual = mesAtual < 10 ? "0" + mesAtual : mesAtual;
                    dispatch(
                        setFiltro({
                            ...state.filtro,
                            ano_consumo: anoFiltro,
                            consumo_ano_safra: modoSafra,
                            competencia_inicial_consumo_comparativo_antes: modoSafra ? `${parseInt(anoFiltro) - 1}06` : `${parseInt(anoFiltro) - 1}01`,
                            competencia_final_consumo_comparativo_antes: modoSafra ? `${anoFiltro}05` : `${parseInt(anoFiltro) - 1}${mesAtual}`,
                            competencia_inicial_consumo_comparativo_depois: modoSafra ? `${anoFiltro}06` : `${anoFiltro}01`,
                            competencia_final_consumo_comparativo_depois: modoSafra ? `${parseInt(anoFiltro) + 1}05` : `${anoFiltro}${mesAtual}`,
                            ano_acumulado_consumo: anoFiltro,
                        })
                    );
                }

                dispatch({
                    type: "ANALISE_GRAFICO_CONSUMO_ANUAL_LISTADO",
                    payload: resp,
                });
            }
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

//primeiro grafico aba reativo
export async function getListaGraficoCustoReativoAnual() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_LISTADO_ANUAL",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const modoSafra = state.filtro.reativo_ano_safra;

            const idCliente = getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null;

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoReativoAnual?id_cliente=${idCliente}&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}&periodo_safra=${modoSafra}`
            );

            //se o ano/safra atual do filtro não existe na lista do novo cliente altera o ano/safra para o último período da lista
            const anosSelect = resp.data.map((item) => (modoSafra ? item.ano_inicio_fim_safra.split("/")[0] : item.ano));
            if (!anosSelect.find((item) => item == state.filtro.ano_reativo)) {
                let anoFiltro = anosSelect.length ? anosSelect[anosSelect.length - 1] : new Date().getFullYear();
                dispatch(
                    setFiltro({
                        ...state.filtro,
                        ano_reativo: anoFiltro,
                        competencia_inicial_reativo: modoSafra ? `${anoFiltro}06` : `${anoFiltro}01`,
                        competencia_final_reativo: modoSafra ? `${parseInt(anoFiltro) + 1}05` : `${anoFiltro}12`,
                    })
                );
            }

            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_LISTADO_ANUAL",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

//segundo grafico aba reativo
export async function getListaGraficoCustoReativoMensal() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_LISTADO_MENSAL",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const modoSafra = state.filtro.reativo_ano_safra;

            const idCliente = getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null;

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoReativoMensal?id_cliente=${idCliente}&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}&periodo_safra=${modoSafra}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_LISTADO_MENSAL",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

//terceiro grafico aba reativo
export async function getListaGraficoConsumoReativoMensal() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_REATIVO_LISTADO_MENSAL",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const modoSafra = state.filtro.reativo_ano_safra;

            const idCliente = getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null;
            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoConsumoReativoMensal?id_cliente=${idCliente}&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}&periodo_safra=${modoSafra}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_REATIVO_LISTADO_MENSAL",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoCustoReativoPorUnidadeAnual() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_UNIDADE_ANUAL_LISTADO",
                payload: { data: [] },
            });

            const idCliente = getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null;

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoReativoPorUnidade?id_cliente=${idCliente}&competencia_inicial=${getState().analise.filtro.competencia_inicial_reativo}&competencia_final=${
                    getState().analise.filtro.competencia_final_reativo
                }`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_UNIDADE_ANUAL_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoCustoReativoTipo() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_TIPO_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
            const idCliente = getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null;

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoReativoTipos?id_cliente=${idCliente}&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}&competencia_inicial=${
                    getState().analise.filtro.competencia_inicial_reativo
                }&competencia_final=${getState().analise.filtro.competencia_final_reativo}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_TIPO_LISTADO",
                payload: {
                    data: resp.data.map((item) => ({
                        ...item,
                        tipos: item.anos,
                    })),
                },
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoConsumoAnualPeriodoSafra(lista) {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_ANUAL_PERIODO_SAFRA_LISTADO",
                payload: { data: lista || [] },
            });

            if (!lista) {
                const state = getState().analise;
                const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
                const resp = await axios.get(
                    `${consts.API_URL}/fatura/listarGraficoConsumo?
				id_cliente=${getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null}
				&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
				&periodo_safra=${true}`
                );
                dispatch({
                    type: "ANALISE_GRAFICO_CONSUMO_ANUAL_PERIODO_SAFRA_LISTADO",
                    payload: resp,
                });
            }
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoTarifaMediaPeriodoSafra() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_TARIFA_MEDIA_PERIODO_SAFRA_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoTarifaMedia?
			id_cliente=${getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null}
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&periodo_safra=${true}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_TARIFA_MEDIA_PERIODO_SAFRA_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoTarifaMediaGeralPeriodoSafra() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_TARIFA_MEDIA_GERAL_PERIODO_SAFRA_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoTarifaMedia?
			id_cliente=${getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null}
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&geral=true
			&periodo_safra=${true}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_TARIFA_MEDIA_GERAL_PERIODO_SAFRA_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoCustoReativoPeriodoSafra() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_PERIODO_SAFRA_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoReativoSafra?id_cliente=${
                    getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null
                }&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}&periodo_safra=${true}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_PERIODO_SAFRA_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoCustoPorHorarioAnualPeriodoSafra() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_HORARIO_ANUAL_PERIODO_SAFRA_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoPorHorario?id_cliente=${
                    getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null
                }&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}&ano=${getState().analise.filtro.anoComparativoPizza || getState().analise.filtro.ano}&competencia_inicial=${
                    getState().analise.filtro.competencia_inicial_horario_periodo_safra
                }&competencia_final=${getState().analise.filtro.competencia_final_horario_periodo_safra}&periodo_safra=${true}`
            );
            const unidadeConsumidora = getState().analise.listaUnidadeConsumidora.find((item) => getState().analise.filtro.unidades_consumidoras.find((uc) => uc.value == item.id));

            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_HORARIO_ANUAL_PERIODO_SAFRA_LISTADO",
                payload: {
                    data: resp.data.map((item) => ({
                        mes: item.mes,
                        tipos: item.tipos.filter((tipo) => {
                            return tipo.tipo != "Reservado" || !unidadeConsumidora || unidadeConsumidora.id_grupo_faturamento == 2 || unidadeConsumidora.irrigante;
                        }),
                    })),
                },
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoConsumoPorHorarioAnualPeriodoSafra() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_HORARIO_ANUAL_PERIODO_SAFRA_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoConsumoPorHorario?
			id_cliente=${getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null}
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&competencia_inicial=${(getState().analise.filtro.anoPeriodoSafraAcumulado || 0) - 1}06
			&competencia_final=${getState().analise.filtro.anoPeriodoSafraAcumulado || 0}05
			&periodo_safra=${true}`
            );
            const unidadeConsumidora = getState().analise.listaUnidadeConsumidora.find((item) => getState().analise.filtro.unidades_consumidoras.find((uc) => uc.value == item.id));

            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_HORARIO_ANUAL_PERIODO_SAFRA_LISTADO",
                payload: {
                    data: resp.data.map((item) => ({
                        mes: item.mes,
                        tipos: item.tipos.filter((tipo) => {
                            return tipo.tipo != "Reservado" || !unidadeConsumidora || unidadeConsumidora.id_grupo_faturamento == 2 || unidadeConsumidora.irrigante;
                        }),
                    })),
                },
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoCustoDemandaEnergiaConsumidaPeriodoSafra() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_DEMANDA_ENERGIA_CONSUMIDA_PERIODO_SAFRA_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoDemandaEnergiaConsumida?
			id_cliente=${getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null}
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&ano=${getState().analise.filtro.anoComparativoPizza || getState().analise.filtro.ano}
			&competencia_inicial=${(getState().analise.filtro.anoPeriodoSafraAcumulado || 0) - 1}06
			&competencia_final=${getState().analise.filtro.anoPeriodoSafraAcumulado || 0}05
			&periodo_safra=${true}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_DEMANDA_ENERGIA_CONSUMIDA_PERIODO_SAFRA_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoCustoMultaAnual() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_MULTA_ANUAL_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const idCliente = getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
            const modoSafra = state.filtro.multas_ano_safra;

            const resp = await axios.get(`${consts.API_URL}/fatura/listarGraficoCustoMulta?id_cliente=${idCliente}&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}&periodo_safra=${modoSafra}`);
            //se o ano/safra atual do filtro não existe na lista do novo cliente altera o ano/safra para o último período da lista
            const anosSelect = resp.data.map((item) => (modoSafra ? item.ano_inicio_fim_safra.split("/")[0] : item.ano));
            if (!anosSelect.find((item) => item == state.filtro.ano_multas)) {
                let anoFiltro = anosSelect.length ? anosSelect[anosSelect.length - 1] : new Date().getFullYear();
                dispatch(
                    setFiltro({
                        ...state.filtro,
                        ano_multas: anoFiltro,
                        competencia_inicial_comparativo_tipo_multa: modoSafra ? `${anoFiltro}06` : `${anoFiltro}01`,
                        competencia_final_comparativo_tipo_multa: modoSafra ? `${parseInt(anoFiltro) + 1}05` : `${anoFiltro}12`,
                    })
                );
            }
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_MULTA_ANUAL_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoCustoMultaAgrupado() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_MULTA_AGRUPADO_LISTADO",
                payload: { data: [] },
            });
            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
            const idCliente = getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null;
            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoTiposMulta?
			id_cliente=${idCliente}
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&competencia_inicial=${getState().analise.filtro.competencia_inicial_comparativo_tipo_multa}
			&competencia_final=${getState().analise.filtro.competencia_final_comparativo_tipo_multa}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_MULTA_AGRUPADO_LISTADO",
                payload: {
                    data: resp.data.map((item) => ({
                        ...item,
                        tipos: item.anos,
                    })),
                },
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoCustoReativoTipoMulta() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_TIPO_MULTA_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
            const idCliente = getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null;
            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoReativoTipos?
			id_cliente=${idCliente}
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&competencia_inicial=${getState().analise.filtro.competencia_inicial_comparativo_tipo_multa}
			&competencia_final=${getState().analise.filtro.competencia_final_comparativo_tipo_multa}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_TIPO_MULTA_LISTADO",
                payload: {
                    data: resp.data.map((item) => ({
                        ...item,
                        tipos: item.anos,
                    })),
                },
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoGeracaoAvaliacaoProducao() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_AVALIACAO_PRODUCAO_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
            const idCliente = getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null;

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoGeracaoAvaliacaoProducao?
			id_cliente=${idCliente}
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&competencia_inicial=${getState().analise.filtro.competencia_inicial}
			&competencia_final=${getState().analise.filtro.competencia_final}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_AVALIACAO_PRODUCAO_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoGeracaoQuantidadeAnual() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_QUANTIDADE_ANUAL_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoGeracaoQuantidade?
			id_cliente=${getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null}
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_QUANTIDADE_ANUAL_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoGeracaoSaldo() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_SALDO_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoGeracaoSaldo?
			id_cliente=${getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null}
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_SALDO_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoGeracaoComparativoConsumo() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_COMPARATIVO_CONSUMO_LISTADO",
                payload: { data: [] },
            });
            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoGeracaoComparativoConsumo?
			id_cliente=${getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null}
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_COMPARATIVO_CONSUMO_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoGeracaoConsumoInstantaneo() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_CONSUMO_INSTANTANEO_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoGeracaoConsumoInstantaneo?
			id_cliente=${getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null}
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&competencia_inicial=${getState().analise.filtro.competencia_inicial}
			&competencia_final=${getState().analise.filtro.competencia_final}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_CONSUMO_INSTANTANEO_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoGeracaoEconomia() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_ECONOMIA_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoGeracaoEconomia?
			id_cliente=${getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null}
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&competencia_inicial=${getState().analise.filtro.competencia_inicial}
			&competencia_final=${getState().analise.filtro.competencia_final}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_ECONOMIA_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}


export async function getListaGraficoCustoPorUnidadeAcumuladoAno() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_UNIDADE_ACUMULADO_ANO_LISTADO",
                payload: { data: [] },
            });

            const state = getState();
            const modoSafra = state.analise.filtro.custo_ano_safra;
            const idCliente = state.auth.usuarioLogado && state.auth.usuarioLogado.acesso_cliente ? state.auth.usuarioLogado.id_cliente : state.analise.filtro.id_cliente || 0;
            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoPorUnidadeAcumuladoAno?id_cliente=${idCliente}&periodo_safra=${modoSafra}${
                    modoSafra
                        ? `&competencia_inicial=${state.analise.filtro.ano_acumulado_custo}06&competencia_final=${parseInt(state.analise.filtro.ano_acumulado_custo) + 1}05`
                        : `&competencia_base=${state.analise.filtro.ano_acumulado_custo}12`
                }`
            );

            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_UNIDADE_ACUMULADO_ANO_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoConsumoPorUnidadeAcumuladoAno() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_UNIDADE_ACUMULADO_ANO_LISTADO",
                payload: { data: [] },
            });

            const state = getState();
            const modoSafra = state.analise.filtro.consumo_ano_safra;
            const idCliente = state.auth.usuarioLogado && state.auth.usuarioLogado.acesso_cliente ? state.auth.usuarioLogado.id_cliente : state.analise.filtro.id_cliente || 0;
            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoConsumoPorUnidadeAcumuladoAno?id_cliente=${idCliente}&periodo_safra=${modoSafra}${
                    modoSafra
                        ? `&competencia_inicial=${state.analise.filtro.ano_acumulado_consumo}06&competencia_final=${parseInt(state.analise.filtro.ano_acumulado_consumo) + 1}05`
                        : `&competencia_base=${state.analise.filtro.ano_acumulado_consumo}12`
                }`
            );

            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_UNIDADE_ACUMULADO_ANO_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoCustoPorUnidadeAcumuladoAnoPeriodoSafra() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_UNIDADE_ACUMULADO_ANO_PERIODO_SAFRA_LISTADO",
                payload: { data: [] },
            });

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoPorUnidadeAcumuladoAno?
			id_cliente=${getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null}
			&periodo_safra=true
			&competencia_inicial=${(getState().analise.filtro.anoPeriodoSafraAcumulado || 0) - 1}06
			&competencia_final=${getState().analise.filtro.anoPeriodoSafraAcumulado || 0}05`
            );

            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_UNIDADE_ACUMULADO_ANO_PERIODO_SAFRA_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoCustoPorUnidadeAnual() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_UNIDADE_ANUAL_LISTADO",
                payload: { data: [] },
            });
            const state = getState();
            const modoSafra = state.analise.filtro.custo_ano_safra;
            const idCliente = state.auth.usuarioLogado && state.auth.usuarioLogado.acesso_cliente ? state.auth.usuarioLogado.id_cliente : state.analise.filtro.id_cliente || null;

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoPorUnidadeGrupo?
			id_cliente=${idCliente}&periodo_safra=${modoSafra}
			&competencia_inicial=${state.analise.filtro.competencia_inicial_unidade_custo}
			&competencia_final=${state.analise.filtro.competencia_final_unidade_custo}`
            );

            //modifica ano de 2023-2024 para 2023/2024
            resp.data = (resp.data || []).map((item) => {
                const anos = (item.anos || []).map((obj) => ({ ...obj, ano: String(obj.ano).replace("-", "/") }));
                return { ...item, anos };
            });

            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_UNIDADE_ANUAL_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaCustoPorUnidadePeriodo() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_CUSTO_POR_UNIDADE_PERIODO_LISTADO",
                payload: { data: [] },
            });

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarCustoPorUnidadePeriodo?
				id_cliente=${getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null}
				&competencia_inicial_antes=${getState().analise.filtro.competencia_inicial_custo_comparativo_antes}
				&competencia_final_antes=${getState().analise.filtro.competencia_final_custo_comparativo_antes}
				&competencia_inicial_depois=${getState().analise.filtro.competencia_inicial_custo_comparativo_depois}
				&competencia_final_depois=${getState().analise.filtro.competencia_final_custo_comparativo_depois}
				`
            );
            dispatch({
                type: "ANALISE_CUSTO_POR_UNIDADE_PERIODO_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaConsumoPorUnidadePeriodo() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_CONSUMO_POR_UNIDADE_PERIODO_LISTADO",
                payload: { data: [] },
            });

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarConsumoPorUnidadePeriodo?
				id_cliente=${getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null}
				&competencia_inicial_antes=${getState().analise.filtro.competencia_inicial_consumo_comparativo_antes}
				&competencia_final_antes=${getState().analise.filtro.competencia_final_consumo_comparativo_antes}
				&competencia_inicial_depois=${getState().analise.filtro.competencia_inicial_consumo_comparativo_depois}
				&competencia_final_depois=${getState().analise.filtro.competencia_final_consumo_comparativo_depois}
				`
            );
            dispatch({
                type: "ANALISE_CONSUMO_POR_UNIDADE_PERIODO_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoCustoPorUnidadeAnualPeriodoSafra() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_UNIDADE_ANUAL_PERIODO_SAFRA_LISTADO",
                payload: { data: [] },
            });

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoPorUnidadeGrupo?
			id_cliente=${getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().analise.filtro.id_cliente || null}
			&id_unidade_consumidora=${null}
			&ano=${getState().analise.filtro.competencia_final ? parseInt(parseInt(getState().analise.filtro.competencia_final) / 100) : null}
			&competencia_inicial=${getState().analise.filtro.ano_safra_custo_anual_periodo_safra ? `${getState().analise.filtro.ano_safra_custo_anual_periodo_safra}06` : null}
			&competencia_final=${getState().analise.filtro.ano_safra_custo_anual_periodo_safra ? `${getState().analise.filtro.ano_safra_custo_anual_periodo_safra + 1}05` : null}
			&periodo_safra=${true}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_UNIDADE_ANUAL_PERIODO_SAFRA_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoCustoPorUnidadeMensal() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_UNIDADE_MENSAL_LISTADO",
                payload: { data: [] },
            });

            const state = getState();
            const idCliente = state.auth.usuarioLogado && state.auth.usuarioLogado.acesso_cliente ? state.auth.usuarioLogado.id_cliente : state.analise.filtro.id_cliente || null;
            const modoSafra = state.analise.filtro.custo_ano_safra;
            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoPorUnidade?
			id_cliente=${idCliente}
			&id_unidade_consumidora=${null}
			&ano=${state.analise.filtro.ano}
			&competencia_inicial=${state.analise.filtro.competencia_inicial_custo}
			&competencia_final=${state.analise.filtro.competencia_final_custo}&periodo_safra=${modoSafra}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_UNIDADE_MENSAL_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoCustoPorUnidadeMensalPeriodoSafra() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_UNIDADE_MENSAL_PERIODO_SAFRA_LISTADO",
                payload: { data: [] },
            });

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoPorUnidade?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&id_unidade_consumidora=${null}
			&ano=${getState().analise.filtro.ano}
			&competencia_inicial=${getState().analise.filtro.ano_safra_custo_anual_periodo_safra ? `${getState().analise.filtro.ano_safra_custo_anual_periodo_safra}06` : null}
			&competencia_final=${getState().analise.filtro.ano_safra_custo_anual_periodo_safra ? `${getState().analise.filtro.ano_safra_custo_anual_periodo_safra + 1}05` : null}
			&periodo_safra=${true}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_UNIDADE_MENSAL_PERIODO_SAFRA_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoCustoReativoPorUnidadeAcumuladoAno() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_UNIDADE_ACUMULADO_ANO_LISTADO",
                payload: { data: [] },
            });

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoReativoPorUnidadeAcumuladoAno?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&competencia_inicial=${getState().analise.filtro.competencia_inicial_reativo}
			&competencia_final=${getState().analise.filtro.competencia_final_reativo}
            &periodo_safra=${false}` //só usa true na aba Periodo Safra
            );
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_UNIDADE_ACUMULADO_ANO_LISTADO",
                payload: resp,
            });
        } catch (erro) {
            setErroAPI(erro);
        }
    };
}

export async function getListaGraficoCustoReativoPorUnidadeAcumuladoAnoPeridoSafra() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_UNIDADE_ACUMULADO_ANO_PERIODO_SAFRA_LISTADO",
                payload: { data: [] },
            });

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoReativoPorUnidadeAcumuladoAno?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&competencia_inicial=${(getState().analise.filtro.anoPeriodoSafraAcumulado || 0) - 1}06
			&competencia_final=${getState().analise.filtro.anoPeriodoSafraAcumulado || 0}05
			&periodo_safra=${true}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_UNIDADE_ACUMULADO_ANO_PERIODO_SAFRA_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}


export async function getGeracaoCadastro() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_CADASTRO_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const usuarioLogado = getState().auth.usuarioLogado;
            const idCliente = usuarioLogado && usuarioLogado.acesso_cliente ? usuarioLogado.id_cliente : state.filtro.id_cliente || null;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
            const resp = await axios.get(`${consts.API_URL}/geracao?id_cliente=${idCliente}&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}`);

            // const listaGeracao = resp.data || [];

            // console.log({listaGeracao})

            // let valorInvestido = 0;
            // for (const geracao of listaGeracao) {
            //     //soma todas as ampliaçõas da geração, *só a primeira geração vai ter, pq nao tem historico
            //     // ai o total investido na primeira, é considerado nas demais
            //     if (geracao.historico) continue;
            //     const ampliacoesRequest = await axios.get(`${consts.API_URL}/geracaoAmpliacao?id_geracao=${geracao.id}`);
            //     const ampliacoes = ampliacoesRequest.data || {};
            //     console.log({ampliacoes})
            //     const totalAmpliacoes = ampliacoes.reduce((total, atual) => (total += parseFloat(atual.valor_investido || 0)), 0);
            //     valorInvestido += parseFloat(geracao.valor_investido || 0) + totalAmpliacoes;
            // }

            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_CADASTRO_LISTADO",
                payload: { data: resp.data || [] },
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoGeracaoEconomiaPonta() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_ECONOMIA_PONTA_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoGeracaoEconomiaPonta?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&competencia_inicial=${getState().analise.filtro.competencia_inicial}
			&competencia_final=${getState().analise.filtro.competencia_final}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_ECONOMIA_PONTA_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarSelect`);
    return {
        type: "ANALISE_CLIENTE_SELECT_LISTADO",
        payload: request,
    };
}

export function getListaDemandaContratoTipo() {
    const request = axios.get(`${consts.API_URL}/demandaContratoTipo/listarselect`);
    return {
        type: "ANALISE_CONTRATO_DEMANDA_TIPO_SELECT_LISTADO",
        payload: request,
    };
}

export function getListaUnidadeConsumidora() {
    const request = axios.get(`${consts.API_URL}/unidadeConsumidora/listarSelect`);
    return {
        type: "ANALISE_UNIDADE_CONSUMIDORA_SELECT_LISTADO",
        payload: request,
    };
}

export function buscaCusd(id, nomePdf) {
    return async (dispatch, getState) => {
        try {
            dispatch(setAguardando(true));
            const resp = await axios.get(`${consts.API_URL}/demandaContrato/importarCusd?id=${id}`);
            if (resp.data && resp.data.arquivo_cusd) {
                dispatch(setArquivoPDF(resp.data.arquivo_cusd, nomePdf));
            }
        } catch (ex) {
            dispatch(setErroAPI(ex));
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function buscaCcer(id, nomePdf) {
    return async (dispatch, getState) => {
        try {
            dispatch(setAguardando(true));
            const resp = await axios.get(`${consts.API_URL}/demandaContrato/importarCcer?id=${id}`);
            if (resp.data && resp.data.arquivo_ccer) {
                dispatch(setArquivoPDF(resp.data.arquivo_ccer, nomePdf));
            }
        } catch (ex) {
            dispatch(setErroAPI(ex));
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export async function getListaGraficoCustoTiposMultaAnual() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_TIPO_MULTA_ANUAL_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoTiposMulta?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_TIPO_MULTA_ANUAL_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoCustoMultaPorUnidadeAcumuladoAno() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_MULTA_UNIDADE_ACUMULADO_ANO_LISTADO",
                payload: { data: [] },
            });
            const idCliente =
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null;

            const modoSafra = getState().analise.filtro.multas_ano_safra;

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoMultaPorUnidade?id_cliente=${idCliente}&ano=${getState().analise.filtro.ano_multas}&periodo_safra=${modoSafra}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_MULTA_UNIDADE_ACUMULADO_ANO_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}
