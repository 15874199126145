const INITIAL_STATE = {
	registro: {},
	aguardando: false,
	modoTela: "lista",
	lista: [],
	listaCliente: [],
	listaUnidadeConsumidora: [],
	listaDocumentoTipo: [],
	listaMeses: [
		{ id: 1, valor: "Janeiro" },
		{ id: 2, valor: "Fevereiro" },
		{ id: 3, valor: "Março" },
		{ id: 4, valor: "Abril" },
		{ id: 5, valor: "Maio" },
		{ id: 6, valor: "Junho" },
		{ id: 7, valor: "Julho" },
		{ id: 8, valor: "Agosto" },
		{ id: 9, valor: "Setembro" },
		{ id: 10, valor: "Outubro" },
		{ id: 11, valor: "Novembro" },
		{ id: 12, valor: "Dezembro" },
	],
	filtro: {
		ano: new Date().getFullYear(),
		id_cliente: null,
		id_unidade_consumidora: null,
		id_documento_tipo: null,
	},
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case "DOCUMENTO_MODO_TELA":
			return { ...state, modoTela: action.payload.modo, registro: action.payload.registro };

		case "DOCUMENTO_SET_AGUARDANDO":
			return { ...state, aguardando: action.payload };

		case "DOCUMENTO_FILTRO":
			return { ...state, filtro: action.payload };

		case "DOCUMENTO_LISTADO":
			return {
				...state,
				lista: action.payload.data,
			};

		case "DOCUMENTO_CLIENTE_SELECT_LISTADO":
			return {
				...state,
				listaCliente: action.payload.data,
			};

		case "DOCUMENTO_UNIDADE_CONSUMIDORA_SELECT_LISTADO":
			return {
				...state,
				listaUnidadeConsumidora: action.payload.data,
			};

		case "DOCUMENTO_TIPO_SELECT_LISTADO":
			return {
				...state,
				listaDocumentoTipo: action.payload.data,
			};

		default:
			return state;
	}
};
