import { PDFDocument } from "pdf-lib";

// Função para combinar os PDFs base64 em um único arquivo PDF
export const combinarPdfRetornarUrl = async (listaPdfBase64) => {
	// Crie um novo documento PDF vazio
	const combinedPdf = await PDFDocument.create();

	// Combine cada arquivo PDF base64 em um único arquivo PDF
	for (const pdfBase64 of listaPdfBase64) {
		// Converta o PDF base64 em um Uint8Array
		const base64Data = pdfBase64.split(",")[1];
		const pdfBytes = Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0));

		// Carregue o PDF como um PDFDocument
		const pdf = await PDFDocument.load(pdfBytes);

		// Copie todas as páginas do PDF para o documento combinado
		const copiedPages = await combinedPdf.copyPages(pdf, pdf.getPageIndices());
		copiedPages.forEach((page) => {
			combinedPdf.addPage(page);
		});
	}

	// Salve o documento PDF combinado como um array buffer
	const combinedPdfBytes = await combinedPdf.save();

	// Converta o array buffer para um URL de objeto
	const pdfBlob = new Blob([combinedPdfBytes], { type: "application/pdf" });
	const pdfUrl = URL.createObjectURL(pdfBlob);
	return pdfUrl;
};
