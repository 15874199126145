const INITIAL_STATE = {
	registro: {},
	aguardando: false,
	modoTela: 'lista',
	lista: [],
	listaCliente: [],
	listaUnidadeConsumidora: [],
	listaMeses: [
		{ id: 1, valor: 'Janeiro' }, { id: 2, valor: 'Fevereiro' }, { id: 3, valor: 'Março' }, { id: 4, valor: 'Abril' },
		{ id: 5, valor: 'Maio' }, { id: 6, valor: 'Junho' }, { id: 7, valor: 'Julho' }, { id: 8, valor: 'Agosto' },
		{ id: 9, valor: 'Setembro' }, { id: 10, valor: 'Outubro' }, { id: 11, valor: 'Novembro' }, { id: 12, valor: 'Dezembro' }],
	filtro: {
		ano: new Date().getFullYear(),
		id_cliente: null,
		id_unidade_consumidora: null
	},
	listaDemandaCompetencia: [],
	modoTelaDemandaCompetencia: 'lista'
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'REDE_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'REDE_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

		case 'REDE_FILTRO':
			return { ...state, filtro: action.payload };

        case 'REDE_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

        case 'REDE_CLIENTE_SELECT_LISTADO':
			return {
				...state,
				listaCliente: action.payload.data
			};

        case 'REDE_UNIDADE_CONSUMIDORA_SELECT_LISTADO':
			return {
				...state,
				listaUnidadeConsumidora: action.payload.data
			};

		case 'DEMANDA_COMPETENCIA_LISTADO':
            return {
				...state,
				listaDemandaCompetencia: action.payload.data
			};

		case 'DEMANDA_COMPETENCIA_LISTA_ALTERACAO':
			return {
				...state,
				modoTelaDemandaCompetencia: action.payload.modo,
				listaAlteracaoDemandaCompetencia: action.payload.lista,
				listaHistorico: action.payload.modo == 'lista' ? [] : state.listaHistorico
			};

		case 'DEMANDA_COMPETENCIA_HISTORICO_LISTADO':
            return {
				...state,
				listaHistorico: action.payload.data
			};

        default:
            return state;
    }
}
