import React from "react";
import Grid from "../layout/grid";

export default (props) => (
	<Grid cols={props.cols}>
		<div className="form-group">
			<label htmlFor={props.name}>{props.label}</label>
			<textarea
				{...props.input}
				value={props.input ? props.input.value : props.value}
				name={props.input ? props.input.name : props.name}
				className="form-control"
				rows={props.rows || 5}
				placeholder={props.placeholder}
				readOnly={props.readOnly}
				type={props.type}
				style={{ ...props.style }}
				onChange={(e) =>
					props.onChange ? props.onChange(e ? (props.getTarget ? e.target : e.target ? e.target.value : null) : null) : props.input.onChange(e)
				}
			/>
		</div>
	</Grid>
);
