const INITIAL_STATE = {
	registro: {},
	registroUC: {},
	modoTela: "lista",
	modoTelaUC: null,
	aguardando: false,
	lista: [],
	listaCidade: [],
	listaUcValidarUsuarioImplantacao: [],
	listaConcessionaria: [],
	listaPlataformaMercadoLivre:[],
	listaGrupoFaturamento: [],
	listaModalidadeTarifaria: [],
	listaTipoMercado: [],
	listaTipoMedicao: [],
	listaSimNao: [
		{ id: 1, valor: "Sim" },
		{ id: 2, valor: "Não" },
	],
	listaAnexo: [],
	listaPercentual: (() => {
		let lista = [];
		for (var i = 0; i <= 100; i++) {
			lista.push({ id: i, valor: `${i} %` });
		}
		return lista;
	})(),
	listaGeracaoTipo: [
		// { id: 1, valor: "Sem geração Solar" },
		{ id: 2, valor: "Fonte Geradora" },
		{ id: 3, valor: "Unidade Remota" },
	],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case "CLIENTE_MODO_TELA":
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro,
			};

		case "UC_MODO_TELA":
			return {
				...state,
				modoTelaUC: action.payload.modoUC,
				registroUC: action.payload.registroUC,
			};

		case "CLIENTE_LISTADO":
			return {
				...state,
				lista: action.payload,
			};

		case "CLIENTE_SET_AGUARDANDO":
			return { ...state, aguardando: action.payload };

		case "CLIENTE_CONCESSIONARIA_SELECT_LISTADO":
			return {
				...state,
				listaConcessionaria: action.payload.data,
			};

		case "CLIENTE_PLATAFORMA_MERCADO_LIVRE_SELECT_LISTADO":
			return {
				...state,
				listaPlataformaMercadoLivre: action.payload.data,
			};

		case "CLIENTE_CIDADE_SELECT_LISTADO":
			return {
				...state,
				listaCidade: action.payload.data,
			};
		case "CLIENTE_GRUPO_FATURAMENTO_SELECT_LISTADO":
			return {
				...state,
				listaGrupoFaturamento: action.payload.data,
			};
		case "CLIENTE_MODALIDADE_TARIFARIA_SELECT_LISTADO":
			return {
				...state,
				listaModalidadeTarifaria: action.payload.data,
			};

		case "CLIENTE_TIPO_MEDICAO_SELECT_LISTADO":
			return {
				...state,
				listaTipoMedicao: action.payload.data,
			};
		case "CLIENTE_TIPO_MERCADO_SELECT_LISTADO":
			return {
				...state,
				listaTipoMercado: action.payload.data,
			};
		case "CLIENTE_ANEXO_LISTADO":
			return {
				...state,
				listaAnexo: action.payload.data,
			};
		case "UNIDADE_CONSUMIDORA_LISTADO":
			return {
				...state,
				listaUcValidarUsuarioImplantacao: action.payload.data,
			};
		default:
			return state;
	}
};
