import axios from "axios";
import consts from "../consts";
import { reset as resetForm, initialize } from "redux-form";
import { setErroAPI, setError, setSuccess } from "../common/toast/toast";
import confirmDialog from "../common/dialog/confirmDialog";
import { adicionarBloqueioUsuarioImplantacao } from "../utils/adicionarBloqueioUsuarioImplantacao";
import { getUcValidarUsuarioImplantacao } from "../cliente/clienteActions";

export function setModoTela(modo, registro = {}) {
	return {
		type: "GESTAO_PROCESSO_MODO_TELA",
		payload: {
			modo: modo,
			registro: registro,
		},
	};
}

export function setAguardando(aguardando) {
	return {
		type: "GESTAO_PROCESSO_SET_AGUARDANDO",
		payload: aguardando,
	};
}

export function initForm(registro = {}) {
	return [initialize("gestaoProcessoForm", registro)];
}

export function initFormAndamento(registro = {}) {
	return [initialize("gestaoProcessoAndamentoForm", registro)];
}

export function setFiltro(filtro) {
	return {
		type: "GESTAO_PROCESSO_FILTRO",
		payload: filtro,
	};
}

export function salvarAndamento(registro, callbackFecharModal) {
	return async (dispatch, getState) => {
		try {
			if (!registro.descricao) {
				setError("Informe a descrição");
				return {};
			}
			if (!registro.solucao_final) {
				setError("Selecione o tipo de ação");
				return {};
			}
			dispatch(setAguardando(true));
			const id_usuario_logado = getState().auth.usuarioLogado.id;
			const registroAlterado = JSON.parse(JSON.stringify(registro));
			delete registroAlterado.gestao_processo.andamento;
			registroAlterado.id_usuario_alteracao = id_usuario_logado;
			registroAlterado.id_gestao_processo = registro.gestao_processo.id;

			//se definir o andamento como solução final pede confirmação
			if (
				JSON.parse(registro.solucao_final) &&
				!window.confirm("Se definir o registro como solução final não será possível adicionar novos registros a esta tarefa, deseja continuar?")
			) {
				return {};
			}
			if (!registro.id) {
				//pede confirmação se quer incluir o registro
				if (!JSON.parse(registro.solucao_final) && !window.confirm("Deseja realmente incluir o registro de andamento?")) {
					return {};
				}
				//se tiver incluindo, define usuario de inclusao e salva
				registroAlterado.id_usuario_inclusao = id_usuario_logado;
				await axios.post(`${consts.API_URL}/gestaoProcessoAndamento`, registroAlterado);
			} else {
				//pede confirmação se quer editar o registro
				if (!JSON.parse(registro.solucao_final) && !window.confirm("Deseja realmente alterar o registro de andamento?")) {
					return {};
				}
				//remove atributos desnecessários para o PUT
				delete registroAlterado.id_usuario_inclusao;
				delete registroAlterado.nome_usuario_inclusao;
				delete registroAlterado.nome_usuario_alteracao;
				const resp = await axios.put(`${consts.API_URL}/gestaoProcessoAndamento`, registroAlterado);
				dispatch(
					initForm({
						...resp.data,
						id_usuario_alteracao: getState().auth.usuarioLogado.id,
						status: String(resp.data.status),
						data_inicio: resp.data.data_inicio ? String(resp.data.data_inicio).substring(0, 10) : null,
						prazo_conclusao: resp.data.prazo_conclusao ? String(resp.data.prazo_conclusao).substring(0, 10) : null,
					})
				);
				const mesmoModoTela = getState().gestaoProcesso.modoTela;
				dispatch(
					setModoTela(mesmoModoTela, {
						...resp.data,
					})
				);
			}
			callbackFecharModal();
			setSuccess("Operação Realizada com sucesso.");
			dispatch(getLista());
		} catch (erro) {
			setErroAPI(erro);
		} finally {
			dispatch(setAguardando(false));
		}
	};
}

export function salvar(registro) {
	return (dispatch, getState) => {
		if (registro.numero_protocolo === "") {
			registro.numero_protocolo = null;
		}
		registro.status = JSON.parse(registro.status);

		if (!registro.id) {
			dispatch(setAguardando(true));
			axios
				.post(`${consts.API_URL}/gestaoProcesso`, registro)
				.then((resp) => {
					setSuccess("Operação Realizada com sucesso.");
					dispatch(getLista());
					dispatch(setModoTela("lista"));
					dispatch(setAguardando(false));
				})
				.catch((e) => {
					setErroAPI(e);
					dispatch(setAguardando(false));
					dispatch(
						initForm({
							...registro,
						})
					);
				});
		} else {
			confirmDialog("Deseja realmente alterar?", () => {
				dispatch(setAguardando(true));
				axios
					.put(`${consts.API_URL}/gestaoProcesso`, registro)
					.then((resp) => {
						setSuccess("Operação Realizada com sucesso.");
						dispatch(getLista());
						dispatch(setModoTela("lista"));
						dispatch(setAguardando(false));
					})
					.catch((e) => {
						setErroAPI(e);
						dispatch(setAguardando(false));
						dispatch(
							initForm({
								...registro,
							})
						);
					});
			});
		}
	};
}

export function excluir(registro) {
	return (dispatch, getState) => {
		confirmDialog("Deseja realmente excluir?", () => {
			axios
				.delete(`${consts.API_URL}/gestaoProcesso?id=${registro.id}&id_usuario_alteracao=${registro.id_usuario_alteracao}`)
				.then((resp) => {
					setSuccess("Operação Realizada com sucesso.");
					dispatch(getLista());
					dispatch(setModoTela("lista"));
				})
				.catch((e) => {
					setErroAPI(e);
				});
		});
	};
}

export function getLista() {
    return async (dispatch, getState) => {
        try {
            dispatch(setAguardando(true));
            await dispatch(getUcValidarUsuarioImplantacao());
            const { filtro } = getState().gestaoProcesso;
            const url = `${consts.API_URL}/gestaoProcesso?id_cliente=${filtro.id_cliente || null}&id_unidade_consumidora=${filtro.id_unidade_consumidora || null}&id_concessionaria=${
                filtro.id_concessionaria || null
            }&id_usuario_responsavel=${filtro.id_usuario_responsavel || null}&numero_protocolo=${filtro.numero_protocolo || null}&status=${filtro.status || null}&id_tipo=${filtro.id_tipo || null}`;

            const resp = await axios.get(url);
            const listaFiltrada = adicionarBloqueioUsuarioImplantacao(getState().auth.usuarioLogado, getState().cliente.listaUcValidarUsuarioImplantacao, resp.data);
            //console.log(resp.data);

            dispatch({
                type: "GESTAO_PROCESSO_LISTADO",
                payload: { data: listaFiltrada },
            });
        } catch (ex) {
            setError("Não foi possível carregar a lista de processos");
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarSelect`);
    return {
        type: "GESTAO_PROCESSO_CLIENTE_SELECT_LISTADO",
        payload: request,
    };
}

export function getListaUnidadeConsumidora() {
    const request = axios.get(`${consts.API_URL}/unidadeConsumidora/listarSelect`);
    return {
        type: "GESTAO_PROCESSO_UNIDADE_CONSUMIDORA_SELECT_LISTADO",
        payload: request,
    };
}

export function getListaConcessionaria() {
    const request = axios.get(`${consts.API_URL}/concessionaria/listarSelect`);
    return {
        type: "GESTAO_PROCESSO_CONCESSIONARIA_SELECT_LISTADO",
        payload: request,
    };
}

export function getListaGestaoProcessoTipo() {
    const request = axios.get(`${consts.API_URL}/gestaoProcessoTipo/listarSelect`);
    return {
        type: "GESTAO_PROCESSO_TIPO_SELECT_LISTADO",
        payload: request,
    };
}
