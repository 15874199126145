import React, { Component } from "react";
import THead from "../common/table/tHead";
import TBody from "../common/table/tBody";
import Table from "../common/table/table";

export default class TabelaFaturaAdmPDF extends Component {
    render() {
        const { cabecalhoSemColunasBotoes, linhas, rodape, filtro } = this.props.dadosImpressao;
        const todasPaginas = [];
        const LINHAS_POR_PAGINA = 8; // Ajuste este valor se necessário
        linhas.push(rodape);
        const quantidadePaginas = Math.ceil(linhas.length / LINHAS_POR_PAGINA);
        let minLinhaProxPagina = 0;

        for (let i = 0; i < quantidadePaginas; i++) {
            const linhasDaPagina = [];
            let maxLinhasPaginaAtual = minLinhaProxPagina + LINHAS_POR_PAGINA;

            for (let j = minLinhaProxPagina; j < maxLinhasPaginaAtual && j < linhas.length; j++) {
                linhasDaPagina.push(linhas[j]);
            }

            minLinhaProxPagina = maxLinhasPaginaAtual;

            todasPaginas.push(
                <div
                    key={i}
                    style={{
                        width: 2500,
                        height: 1740, 
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                    }}
                >
                    <div style={{ display: "flex" }}>{filtro}</div>
                    <Table>
                        <THead>{cabecalhoSemColunasBotoes}</THead>
                        <TBody>{linhasDaPagina.map((linha) => linha)}</TBody>
                    </Table>
                </div>
            );
        }

        return <>{todasPaginas}</>;
    }
}

