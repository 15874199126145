import { filtrarUcPorDataDesligamento } from "../utils/filtrarUcPorDataDesligamento";
const INITIAL_STATE = {
    registro: {},
    aguardando: false,
    modoTela: "lista",
    lista: [],
    listaCliente: [],
    listaUnidadeConsumidora: [],
    filtro: {
        id_tipo_relatorio: 1,
        ano_safra: (() => {
            if (new Date().getMonth() >= 5) {
                //se for junho ou maior seta ano anterior / ano atual pois fechou o periodo safra
                return `${new Date().getFullYear() - 1}/${new Date().getFullYear()}`;
            } else {
                return `${new Date().getFullYear() - 2}/${new Date().getFullYear() - 1}`;
            }
        })(),
        ano: (() => {
            if (new Date().getMonth() == 0) {
                return new Date().getFullYear() - 1;
            } else {
                return new Date().getFullYear();
            }
        })(),
        mes: (() => {
            let data = new Date();
            data.setMonth(data.getMonth() - 1);
            return data.getMonth() < 9 ? `0${data.getMonth() + 1}` : data.getMonth() + 1;
        })(),
        id_cliente: null,
        id_unidade_consumidora: null,
        id_status: 2,
        id_tipo_contrato_demanda: 1, //padrão traz somente fora ponta
    },
    dadosRelatorioSafra: null,
    contratoDemanda: null,
    listaDemandas: [],
    listaDemandaContagem: [],
    listaGraficoCustoAnual: [],
    listaGraficoGeracaoQuantidadeAnual: [],
    listaGraficoCustoUnidadeUltimoMes: [],
    listaGraficoCustoPorUnidadeAcumuladoAno: [],
    listaConsumoPercentualPorHorarioUltimoMes: [],
    listaGraficoGeracaoAvaliacaoProducaoAnual: [],
    listaGraficoCustoUnidadeMensal: [],
    listaMeses: [
        { id: "01", valor: "Janeiro" },
        { id: "02", valor: "Fevereiro" },
        { id: "03", valor: "Março" },
        { id: "04", valor: "Abril" },
        { id: "05", valor: "Maio" },
        { id: "06", valor: "Junho" },
        { id: "07", valor: "Julho" },
        { id: "08", valor: "Agosto" },
        { id: "09", valor: "Setembro" },
        { id: "10", valor: "Outubro" },
        { id: "11", valor: "Novembro" },
        { id: "12", valor: "Dezembro" },
    ],
    listaStatus: [
        { id: 1, valor: "Pendente de Importação" },
        { id: 3, valor: "Todas Importadas - Faltando Produção Solar" },
        { id: 2, valor: "Todas Importadas - Não Enviados" },
        { id: 4, valor: "Enviados" },
    ],

    listaTiposRelatorios: [
        { id: 1, valor: "Mensal" },
        { id: 2, valor: "Anual P. Safra (Junho à Maio)" },
        { id: 3, valor: "[INDISPONÍVEL] Anual P. Civil (Janeiro à Dezembro)" },
    ],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "FATURA_RELATORIO_MODO_TELA":
            return {
                ...state,
                modoTela: action.payload.modo,
                registro: action.payload.registro,
            };

        case "FATURA_RELATORIO_SET_AGUARDANDO":
            return { ...state, aguardando: action.payload };

        case "FATURA_RELATORIO_FILTRO":
            return { ...state, filtro: action.payload };

        case "FATURA_RELATORIO_LISTADO":
            return {
                ...state,
                lista: action.payload.data,
            };

        case "FATURA_RELATORIO_CLIENTE_SELECT_LISTADO":
            return {
                ...state,
                listaCliente: action.payload.data,
            };

        case "FATURA_RELATORIO_UNIDADE_CONSUMIDORA_SELECT_LISTADO":
            return {
                ...state,
                listaUnidadeConsumidora: filtrarUcPorDataDesligamento(action.payload.data || []),
            };

        case "FATURA_RELATORIO_DEMANDA_LISTADO":
            return {
                ...state,
                contratoDemanda: action.payload ? action.payload.vigente : null,
                listaDemandas: action.payload ? action.payload.demandas : [],
            };

        case "FATURA_RELATORIO_DEMANDA_CONTAGEM_LISTADO":
            return {
                ...state,
                listaDemandaContagem: action.payload.data,
            };

        case "FATURA_RELATORIO_GRAFICO_CUSTO_ANUAL_LISTADO":
            return {
                ...state,
                listaGraficoCustoAnual: action.payload.data,
            };

        case "FATURA_RELATORIO_GRAFICO_GERACAO_QUANTIDADE_ANUAL_LISTADO":
            return {
                ...state,
                listaGraficoGeracaoQuantidadeAnual: action.payload.data,
            };

        case "FATURA_RELATORIO_GRAFICO_CUSTO_UNIDADE_ULTIMO_MES_LISTADO":
            return {
                ...state,
                listaGraficoCustoUnidadeUltimoMes: action.payload.data,
            };

        case "FATURA_RELATORIO_GRAFICO_CUSTO_UNIDADE_ACUMULADO_ANO_LISTADO":
            return {
                ...state,
                listaGraficoCustoPorUnidadeAcumuladoAno: action.payload.data,
            };

        case "FATURA_RELATORIO_GRAFICO_CONSUMO_PERCENTUAL_POR_HORARIO_MES_LISTADO":
            return {
                ...state,
                listaConsumoPercentualPorHorarioUltimoMes: action.payload.data,
            };

        case "FATURA_RELATORIO_GRAFICO_GERACAO_AVALIACAO_PRODUCAO_LISTADO":
            return {
                ...state,
                listaGraficoGeracaoAvaliacaoProducaoAnual: action.payload.data,
            };

        case "FATURA_RELATORIO_GRAFICO_CUSTO_UNIDADE_MENSAL_LISTADO":
            return {
                ...state,
                listaGraficoCustoUnidadeMensal: action.payload.data,
            };
        case "FATURA_RELATORIO_DADOS_SAFRA":
            const dadosTratados = action.payload.data;
            //GRAFICO DE CUSTO POR SAFRA ******************************************************************************************************
            //pega apenas as 4 últimas
            dadosTratados.listaGraficoCustoTotalPorSafra = dadosTratados.listaGraficoCustoTotalPorSafra
                .sort(
                    (a, b) =>
                        parseInt(a.ano_inicio_fim_safra.split("/")[0]) - parseInt(b.ano_inicio_fim_safra.split("/")[0])
                )
                .slice(-4);

            //LISTA CUSTO ACUMULADO POR UNIDADE E POR SAFRA ************************************************************************************
            //ordena para que as com maior custo apareçam no início
            dadosTratados.listaGraficoCustoAcumuladoPorUnidadeAnoSafra =
                dadosTratados.listaGraficoCustoAcumuladoPorUnidadeAnoSafra.sort((a, b) => b.valor - a.valor);

            //HISTORICO ÍNDICE DE MULTAS *******************************************************************************************************

            //encontra o pior caso
            const listaIndicesMulta = dadosTratados.listaGraficoHistoricoIndicesMultasPorSafra.map(
                (item) => item.indice_total
            ); // valor %
            const maiorIndice = Math.max(...listaIndicesMulta);

            //separa o registro do maior indice
            const registroMaiorIndice = dadosTratados.listaGraficoHistoricoIndicesMultasPorSafra.find(
                (item) => item.indice_total == maiorIndice
            );

            //pega os 4 últimos anos
            dadosTratados.listaGraficoHistoricoIndicesMultasPorSafra =
                dadosTratados.listaGraficoHistoricoIndicesMultasPorSafra
                    .sort(
                        (a, b) =>
                            parseInt(a.ano_inicio_fim_safra.split("/")[0]) -
                            parseInt(b.ano_inicio_fim_safra.split("/")[0])
                    )
                    .slice(-4);

            //verifica se o pior caso está entre os últimos 4 anos, senão inclui no inicio da lista
            if (
                !dadosTratados.listaGraficoHistoricoIndicesMultasPorSafra.find(
                    (item) => item.ano_inicio_fim_safra == registroMaiorIndice.ano_inicio_fim_safra
                )
            ) {
                dadosTratados.listaGraficoHistoricoIndicesMultasPorSafra.unshift(registroMaiorIndice);
            }

            //percorre todos para criar duas series, uma para linha (últimos 4 anos) e uma para coluna (pior caso)
            dadosTratados.listaGraficoHistoricoIndicesMultasPorSafra =
                dadosTratados.listaGraficoHistoricoIndicesMultasPorSafra.map((item) => {
                    return {
                        ...item,
                        indice_total_linha: item.indice_total,
                        indice_total_coluna:
                            item.ano_inicio_fim_safra == registroMaiorIndice.ano_inicio_fim_safra
                                ? item.indice_total
                                : null,
                    };
                });

            //GRAFICO DE DEVOLUÇÃO POR SAFRA ****************************************************************************************************
            //pega apenas as 4 últimas

            dadosTratados.listaGraficoTotalDevolucoesPorSafra =
                dadosTratados.listaGraficoTotalDevolucoesPorSafra.slice(-4);

            return {
                ...state,
                dadosRelatorioSafra: dadosTratados,
            };

        default:
            return state;
    }
};
