function filtrarUcPorDataDesligamento(listaUnidadeConsumidora) {
  return listaUnidadeConsumidora.filter((uc) => {
    const dataAtual = new Date();
    dataAtual.setHours(0);
    dataAtual.setMinutes(0);
    dataAtual.setSeconds(0);
    dataAtual.setMilliseconds(0);
    return (
      !uc.data_desligamento ||
      dataAtual.getTime() < new Date(uc.data_desligamento).getTime()
    );
  });
}
export { filtrarUcPorDataDesligamento };
