const INITIAL_STATE = {
	registro: {},
	aguardando: false,
	modoTela: "lista",
	lista: [],
	listaDocumentoTipo: [],
	filtro: {
		id_documento_tipo: null,
	},
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case "DOCUMENTO_ADM_MODO_TELA":
			return { ...state, modoTela: action.payload.modo, registro: action.payload.registro };

		case "DOCUMENTO_ADM_SET_AGUARDANDO":
			return { ...state, aguardando: action.payload };

		case "DOCUMENTO_ADM_FILTRO":
			return { ...state, filtro: action.payload };

		case "DOCUMENTO_ADM_LISTADO":
			return {
				...state,
				lista: action.payload.data,
			};

		case "DOCUMENTO_ADM_TIPO_SELECT_LISTADO":
			return {
				...state,
				listaDocumentoTipo: action.payload.data,
			};

		default:
			return state;
	}
};
