import React from 'react';
import Grid from '../layout/grid';
import InputMask from 'react-input-mask';

export default props => {
	return (
        <Grid cols={props.cols}>
            <div className="form-group">
                <label htmlFor={props.name}>{props.label}</label>
                {props.onChange ? (
                    <InputMask
                        {...props.input}
                        className="form-control"
                        mask={props.mask}
                        maskChar={props.maskChar}
                        placeholder={props.placeholder}
                        readOnly={props.readOnly}
                        type={props.type}
                        value={props.value}
                        onChange={props.onChange}
                        autoFocus={props.autoFocus || false}
                    />
                ) : (
                    <InputMask
                        {...props.input}
                        className="form-control"
                        mask={props.mask}
                        maskChar={props.maskChar}
                        placeholder={props.placeholder}
                        readOnly={props.readOnly}
                        type={props.type}
                        autoFocus={props.autoFocus || false}
                    />
                )}
            </div>
        </Grid>
    );
}
