import React from "react";
import imagemSoLogo from "../../assets/images/logo_aguardando.gif";
export function Loading() {
	return (
		<div className="modal fade show" id="modal-overlay" style={{ display: "block", paddingRight: 15 }} aria-modal="true" role="dialog">
			<div className="modal-dialog">
				<div className="overlay">
					<div style={{ marginLeft: "50%", marginRight: "50%" }}>
						<img src={imagemSoLogo} style={{ height: 50, width: 50, borderRadius: "50%" }} />
					</div>
				</div>
			</div>
		</div>
	);
}
