import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";

import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";
import Select from "../common/form/select";
import LabelAndCheckbox from "../common/form/labelAndCheckbox";
import LabelAndInputNumber from "../common/form/labelAndInputNumber";
import LabelAndTextarea from "../common/form/labelAndTextarea";
import LabelAndInputDate from "../common/form/labelAndInputDate";
import RadioButtom from "../common/form/radioButtom";
import { setModoTelaUC, initUcForm, excluirUC, reativarUC } from "./clienteActions";

class UnidadeConsumidoraForm extends Component {
    componentDidMount() {
        //caso a unidade geradora setada está desligada, seta como nula, para que ao salvar o usuário seja obrigado a selecionar outra
        const listaUCsGeradorasAtivas = (this.props.listaUcCliente || [])
            .filter((uc) => uc.geracao && uc.ativo && !uc.data_desligamento && uc.id != this.props.ucFormularioValues.id)
            .map((uc) => ({
                id: uc.id,
                valor: uc.nome,
            }));

        const id_unidade_geradora = (listaUCsGeradorasAtivas.find((item) => item.id == this.props.ucFormularioValues.id_unidade_geradora) || {}).id;

        this.props.initUcForm({
            ...this.props.ucFormularioValues,
            id_unidade_geradora: id_unidade_geradora || null,
        });
        window.scrollTo(0, 0);
    }

    render() {
        const readOnly = this.props.excluir || this.props.visualizar || this.props.reativar || this.props.ucFormularioValues.bloquearEdicaoUcUsuarioImplantacao ? "readOnly" : "";

        //usado para mostrar em um input somente leitura
        const aliquota_icms = (
            this.props.listaTributacao.find((tributacao) => tributacao.id_estado == this.props.ucFormularioValues.id_estado && tributacao.id_atividade == this.props.ucFormularioValues.id_atividade) || {}
        ).aliquota_icms;

        const listaUCsGeradorasAtivas = (this.props.listaUcCliente || [])
            .filter((uc) => uc.geracao && uc.ativo && !uc.data_desligamento && uc.id != this.props.ucFormularioValues.id)
            .map((uc) => ({
                id: uc.id,
                valor: uc.nome,
            }));

        return (
            <ContentCard>
                <Form event={this.props.handleSubmit}>
                    <ContentCardBody>
                        <Field name="id_concessionaria" component={Select} options={this.props.listaConcessionaria} label="Concessionária" cols="12 12 6 6" placeholder="Selecione a concessionaria" readOnly={readOnly} isClearable={false}/>
                        {this.props.ucFormularioValues.id_concessionaria == 2 ? ( //se for da RGE aparece um campo para informar o cpf ou cnpj
                            <LabelAndInput
                                name="cpf_cnpj"
                                label="CPF/CNPJ - RGE"
                                placeholder="Informe o CPF ou o CNPJ que consta na RGE"
                                cols="12 12 6 6"
                                readOnly={readOnly}
                                value={this.props.ucFormularioValues.cpf_cnpj}
                                maxLength={14}
                                onChange={(data) => {
                                    this.props.initUcForm({
                                        ...this.props.ucFormularioValues,
                                        cpf_cnpj: (data.target.value || "").match(/\d{0,14}/g)[0] || null,
                                    });
                                }}
                            />
                        ) : null}
                        <Field name="nome" component={LabelAndInput} label="Nome" placeholder="Informe o nome" cols="12 12 6 6" readOnly={readOnly} />
                        <Field name="numero" component={LabelAndInput} label="Código da UC" placeholder="Informe o código" cols="12 12 6 6" readOnly={readOnly} />
                        <Select
                            name="id_estado"
                            options={this.props.listaEstado.filter((estado) => this.props.listaTributacao.find((tributacao) => tributacao.id_estado == estado.id))}
                            label="UF"
                            cols="12 12 6 6"
                            readOnly={readOnly}
                            isClearable={false}
                            placeholder="Selecione a UF"
                            value={this.props.ucFormularioValues.id_estado}
                            onChange={(data) => {
                                this.props.initUcForm({
                                    ...this.props.ucFormularioValues,
                                    id_estado: data,
                                    id_atividade: null,
                                });
                            }}
                        />
                        <Select
                            name="id_atividade"
                            options={this.props.listaAtividade.filter((atividade) =>
                                this.props.listaTributacao.filter((tributacao) => tributacao.id_estado == this.props.ucFormularioValues.id_estado).find((tributacao) => tributacao.id_atividade == atividade.id)
                            )}
                            label="Tipo de Atividade"
                            cols="12 12 6 6"
                            placeholder="Selecione a atividade"
                            readOnly={readOnly}
                            isClearable={false}
                            value={this.props.ucFormularioValues.id_atividade}
                            onChange={(data) => {
                                this.props.initUcForm({
                                    ...this.props.ucFormularioValues,
                                    id_atividade: data,
                                    contrato_demanda_sazonal: (data == 2 && [1,4].includes(parseInt(this.props.ucFormularioValues.id_grupo_faturamento))) || this.props.ucFormularioValues.contrato_demanda_sazonal,
                                });
                            }}
                        />
                        <LabelAndInput label="(% ICMS)" cols="12 12 6 6" readOnly={true} key={aliquota_icms} value={aliquota_icms ? aliquota_icms + " %" : null} />
                        <Select
                            name="id_grupo_faturamento"
                            options={this.props.listaGrupoFaturamento}
                            label="Grupo de Faturamento"
                            cols="12 12 6 6"
                            placeholder="Selecione o grupo de faturamento"
                            readOnly={readOnly}
                            isClearable={false}
                            value={this.props.ucFormularioValues.id_grupo_faturamento}
                            onChange={(data) => {
                                const valor = parseInt(data);
                                //o tipo 4 é o mesmo do 1, tanto que ao salvar ele altera 4 para 1, porem com o atributo grupo_faturamento_optante = true
                                this.props.initUcForm({
                                    ...this.props.ucFormularioValues,
                                    id_grupo_faturamento: valor,
                                    contrato_demanda_sazonal: [1,4].includes(valor) && (this.props.ucFormularioValues.id_atividade == 2 || this.props.ucFormularioValues.contrato_demanda_sazonal),
                                    id_modalidade_tarifaria: [1,4].includes(valor) ? this.props.ucFormularioValues.id_modalidade_tarifaria : null,
                                    //SE FOR GRUPO A ou IRRIGANTE é Média Tensão (id = 2) senão é Baixa Tensão (id=1)
                                    id_tipo_medicao: !this.props.ucFormularioValues.id ? ([1,4].includes(valor) || this.props.ucFormularioValues.irrigante ? 2 : 1) : this.props.ucFormularioValues.id_tipo_medicao,
                                });
                            }}
                        />
                        <Select
                            name="id_tipo_mercado"
                            options={this.props.listaTipoMercado}
                            label="Tipo de Mercado"
                            cols="12 12 6 6"
                            placeholder="Selecione o tipo de mercado"
                            readOnly={readOnly}
                            isClearable={false}
                            value={this.props.ucFormularioValues.id_tipo_mercado}
                            onChange={(data) => {
                                this.props.initUcForm({
                                    ...this.props.ucFormularioValues,
                                    id_tipo_mercado: data,
                                    id_plataforma_mercado_livre: data == 2 ? this.props.ucFormularioValues.id_plataforma_mercado_livre : null,
                                    usuario_mercado_livre: data == 2 ? this.props.ucFormularioValues.usuario_mercado_livre : null,
                                    senha_mercado_livre: data == 2 ? this.props.ucFormularioValues.senha_mercado_livre : null,
                                });
                            }}
                        />
                        {this.props.ucFormularioValues.id_tipo_mercado == 2 ? (
                            <Grid cols="12 12 6 6" style={{ border: "1px solid #DDD", borderRadius: 15, padding: 15, margin: "20px 7px" }}>
                                <Grid cols="12 12 12 12">
                                    <label>Acesso Plataforma Mercado Livre</label>
                                </Grid>
                                <Field
                                    component={Select}
                                    name="id_plataforma_mercado_livre"
                                    options={this.props.listaPlataformaMercadoLivre}
                                    label="Plataforma Mercado Livre"
                                    cols="12 12 12 12"
                                    placeholder="Selecione a plataforma"
                                    readOnly={readOnly}
                                    isClearable={false}
                                />
                                <Field component={LabelAndInput} name="usuario_mercado_livre" label="Login" placeholder="Informe o login" cols="12 12 12 12" readOnly={readOnly} />
                                <Field component={LabelAndInput} name="senha_mercado_livre" label="Senha" placeholder="Informe o senha" cols="12 12 12 12" readOnly={readOnly} />
                            </Grid>
                        ) : null}
                        {[1,4].includes(parseInt(this.props.ucFormularioValues.id_grupo_faturamento)) ? ( //só exibe os 2 campos se for Grupo A
                            <>
                                <Field
                                    component={Select}
                                    name="id_modalidade_tarifaria"
                                    options={this.props.listaModalidadeTarifaria}
                                    label="Modalidade Tarifária (Reflexo no tipo de Contrato de Demanda)"
                                    cols="12 12 6 6"
                                    placeholder="Selecione a modalidade tarifária"
                                    readOnly={readOnly}
                                    isClearable={false}
                                />

                                <LabelAndCheckbox
                                    title={{
                                        marcado: "Disponível apenas para Grupo A, se for Grupo A e Atividade Rural a caixa é marcada e não pode ser alterada",
                                        desmarcado: "Disponível apenas para Grupo A, se for Grupo A e Atividade Rural a caixa é marcada e não pode ser alterada",
                                    }}
                                    label="Possui contrato de demanda Sazonal?"
                                    cols="12 12 6 6"
                                    readOnly={
                                        readOnly ||
                                        ![1,4].includes(parseInt(this.props.ucFormularioValues.id_grupo_faturamento)) || //Não for grupo A
                                        ([1,4].includes(parseInt(this.props.ucFormularioValues.id_grupo_faturamento)) && this.props.ucFormularioValues.id_atividade == 2) //se for Grupo A e Rural seta sim e n deixa editar
                                    }
                                    value={!!this.props.ucFormularioValues.contrato_demanda_sazonal}
                                    onChange={() => {
                                        this.props.initUcForm({
                                            ...this.props.ucFormularioValues,
                                            contrato_demanda_sazonal: !this.props.ucFormularioValues.contrato_demanda_sazonal,
                                        });
                                    }}
                                />
                            </>
                        ) : null}
                        <LabelAndTextarea
                            label="Mensagem Última Importacao Fatura"
                            cols="12 12 6 6"
                            value={this.props.ucFormularioValues.mensagem_ultima_importacao_fatura}
                            readOnly={true}
                            style={{
                                width: "100%",
                                height: 90,
                                resize: "none",
                                backgroundColor: this.props.ucFormularioValues.mensagem_ultima_importacao_fatura ? "#f96b6b" : "",
                                color: "#ffffff",
                            }}
                        />
                        <Field component={Select} name="id_tipo_medicao" options={this.props.listaTipoMedicao} label="Tipo de Medição" cols="12 12 6 6" placeholder="Selecione o tipo de medição" readOnly={readOnly} isClearable={false}/>
                        <LabelAndCheckbox
                            // alignCenter={window.innerWidth > 991}
                            // divMarginRight={10}
                            title={{
                                marcado: "Contabiliza histórico de dados das faturas até a data do desligamento",
                                desmarcado: "Contabiliza histórico de dados das faturas durante todo o período",
                            }}
                            label="Desligado"
                            cols="12 12 6 6"
                            readOnly={readOnly}
                            value={!!this.props.ucFormularioValues.mostrar_data_desligamento}
                            onChange={() => {
                                this.props.initUcForm({
                                    ...this.props.ucFormularioValues,
                                    mostrar_data_desligamento: !this.props.ucFormularioValues.mostrar_data_desligamento,
                                    data_desligamento: null,
                                });
                            }}
                        />
                        {this.props.ucFormularioValues.mostrar_data_desligamento || this.props.ucFormularioValues.data_desligamento ? (
                            <Field component={LabelAndInputDate} name="data_desligamento" label="Data de Desligamento" cols="12 12 6 6" placeholder="Informe a data" readOnly={readOnly} />
                        ) : null}
                        <LabelAndCheckbox
                            style={{ marginRight: 10 }}
                            label="Irrigante"
                            cols="12 12 6 6"
                            readOnly={readOnly}
                            value={!!this.props.ucFormularioValues.irrigante}
                            onChange={() => {
                                this.props.initUcForm({
                                    ...this.props.ucFormularioValues,
                                    irrigante: !this.props.ucFormularioValues.irrigante,
                                    subsidio_irrigante: !this.props.ucFormularioValues.irrigante && this.props.ucFormularioValues.subsidio_irrigante, //tem q ser irrigante pra ser true
                                    //SE FOR GRUPO A (id=1) ou SE FOR IRRIGANTE é Média Tensão (id = 2) senão é Baixa Tensão (id=1)
                                    id_tipo_medicao: !this.props.ucFormularioValues.id
                                        ?  [1,4].includes(parseInt(this.props.ucFormularioValues.id_grupo_faturamento)) || !this.props.ucFormularioValues.irrigante //valor do checkbox alterado
                                            ? 2
                                            : 1
                                        : this.props.ucFormularioValues.id_tipo_medicao,
                                });
                            }}
                        />
                        {this.props.ucFormularioValues.irrigante ? (
                            <Grid
                                cols="12 12 12 12"
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    height: "min-content",
                                    paddingInline: 20,
                                    marginBottom: 20,
                                }}
                            >
                                <RadioButtom
                                    nameField="Possui Subsídio Irrigante? (Outorga de água definitiva e Liberação Ambiental)"
                                    options={[
                                        {
                                            id: "subsidio_irrigante_sim",
                                            name: "subsidio_irrigante",
                                            value: this.props.ucFormularioValues.subsidio_irrigante,
                                            conditionChecked: this.props.ucFormularioValues.subsidio_irrigante,
                                            event: (e) => {
                                                this.setState({ tipoCliente: e.target.value });
                                                this.props.initUcForm({
                                                    ...this.props.ucFormularioValues,
                                                    subsidio_irrigante: true,
                                                });
                                            },
                                            label: "Sim",
                                        },
                                        {
                                            id: "subsidio_irrigante_nao",
                                            name: "subsidio_irrigante",
                                            value: false,
                                            conditionChecked: !this.props.ucFormularioValues.subsidio_irrigante,
                                            event: (e) => {
                                                this.setState({ tipoCliente: e.target.value });
                                                this.props.initUcForm({
                                                    ...this.props.ucFormularioValues,
                                                    subsidio_irrigante: false,
                                                });
                                            },
                                            label: "Não",
                                        },
                                    ]}
                                    readOnly={readOnly}
                                />
                            </Grid>
                        ) : null}
                        <LabelAndCheckbox
                            style={{ marginRight: 10 }}
                            label="Geração de Energia"
                            cols="12 12 6 6"
                            readOnly={readOnly}
                            value={!!this.props.ucFormularioValues.mostrar_geracao_solar}
                            onChange={() => {
                                this.props.initUcForm({
                                    ...this.props.ucFormularioValues,
                                    mostrar_geracao_solar: !this.props.ucFormularioValues.mostrar_geracao_solar,
                                    geracao: !this.props.ucFormularioValues.mostrar_geracao_solar,
                                    remota: false,
                                    id_unidade_geradora: null,
                                    percentual_geracao: null,
                                });
                            }}
                        />
                        {this.props.ucFormularioValues.mostrar_geracao_solar ? (
                            <Select
                                options={this.props.listaGeracaoTipo}
                                label="Classificação de Geração"
                                cols="12 12 6 6"
                                placeholder="Selecione a classificação de geração"
                                readOnly={readOnly}
                                isClearable={false}
                                value={this.props.ucFormularioValues.remota ? 3 : this.props.ucFormularioValues.geracao ? 2 : 1}
                                onChange={(data) => {
                                    this.props.initUcForm({
                                        ...this.props.ucFormularioValues,
                                        geracao: data == 2 ? true : false,
                                        remota: data == 3 ? true : false,
                                        id_unidade_geradora: data == 3 ? this.props.ucFormularioValues.id_unidade_geradora : null,
                                    });
                                }}
                            />
                        ) : null}
                        {this.props.ucFormularioValues.remota ? (
                            <Grid
                                cols="12 12 12 12"
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    height: "min-content",
                                    marginBottom: 20,
                                }}
                            >
                                <RadioButtom
                                    nameField="Origem UC Geradora"
                                    options={[
                                        {
                                            id: "uc_gradios",
                                            name: "geradora_externa",
                                            value: "uc_gradios",
                                            conditionChecked: !this.props.ucFormularioValues.geradora_externa,
                                            event: (e) => {
                                                this.props.initUcForm({
                                                    ...this.props.ucFormularioValues,
                                                    geradora_externa: false,
                                                });
                                            },
                                            label: "Gradios",
                                        },
                                        {
                                            id: "uc_externa",
                                            name: "geradora_externa",
                                            value: "uc_externa",
                                            conditionChecked: this.props.ucFormularioValues.geradora_externa,
                                            event: (e) => {
                                                this.props.initUcForm({
                                                    ...this.props.ucFormularioValues,
                                                    geradora_externa: true,
                                                    id_unidade_geradora: null,
                                                });
                                            },
                                            label: "Externo (Usina Aluguel)",
                                        },
                                    ]}
                                    readOnly={readOnly}
                                />
                            </Grid>
                        ) : null}
                        {this.props.ucFormularioValues.remota && !this.props.ucFormularioValues.geradora_externa ? (
                            <Field component={Select} name="id_unidade_geradora" options={listaUCsGeradorasAtivas} label="Unidade Fonte Geradora" cols="12 12 6 6" placeholder="Selecione a UC" readOnly={readOnly} isClearable={false}/>
                        ) : null}
                        {this.props.ucFormularioValues.mostrar_geracao_solar ? (
                            <LabelAndInputNumber
                                allowNegative={false}
                                name="percentual_geracao"
                                label="Percentual da Geração"
                                cols="12 12 6 6"
                                placeholder="Informe o percentual"
                                readOnly={readOnly}
                                casas={2}
                                value={parseFloat(this.props.ucFormularioValues.percentual_geracao) >= 0 ? String(this.props.ucFormularioValues.percentual_geracao).replace(".", ",") : null}
                                onChange={(data) => {
                                    this.props.initUcForm({
                                        ...this.props.ucFormularioValues,
                                        percentual_geracao: parseFloat(data.target.value) >= 0 ? String(data.target.value).replace(",", ".") : null,
                                    });
                                }}
                            />
                        ) : null}
                        {!this.props.usuarioLogado.usuario_implantacao && (
                            <>
                                <LabelAndCheckbox
                                    style={{ marginRight: 10 }}
                                    component={LabelAndCheckbox}
                                    label="Usuário de Implantação"
                                    cols="12 12 6 6"
                                    readOnly={readOnly}
                                    value={!!this.props.ucFormularioValues.usuario_implantacao}
                                    onChange={() => {
                                        this.props.initUcForm({
                                            ...this.props.ucFormularioValues,
                                            usuario_implantacao: !this.props.ucFormularioValues.usuario_implantacao,
                                            id_usuario_implantacao: !this.props.ucFormularioValues.usuario_implantacao ? this.props.ucFormularioValues.id_usuario_implantacao : null,
                                        });
                                    }}
                                />
                                {this.props.ucFormularioValues.usuario_implantacao && (
                                    <Field
                                        component={Select}
                                        name="id_usuario_implantacao"
                                        options={this.props.listaUsuarioImplantacao}
                                        label="Nome do Usuário de Implantação"
                                        cols="12 12 6 6"
                                        placeholder="Selecione o usuário de implantação"
                                        readOnly={readOnly}
                                    />
                                )}
                            </>
                        )}
                        <Grid cols="12 12 6 6" style={{ border: "1px solid #DDD", borderRadius: 15, padding: 15, margin: "20px 7px" }}>
                            <Grid cols="12 12 12 12">
                                <label>Acesso Concessionária</label>
                            </Grid>
                            <Field component={LabelAndInput} name="usuario" label="Login" placeholder="Informe o login" cols="12 12 12 12" readOnly={readOnly} />
                            <Field component={LabelAndInput} name="senha" label="Senha" placeholder="Informe o senha" cols="12 12 12 12" readOnly={readOnly} />
                        </Grid>
                    </ContentCardBody>
                    <ContentCardFooter>
                        <Row alignCenter={true}>
                            {this.props.excluir ? (
                                <>
                                    <Grid cols="12 12 3 3" style={{ paddingTop: 5 }}>
                                        <Button
                                            text="Inativar"
                                            type={"dark"}
                                            event={() => {
                                                this.props.excluirUC({
                                                    id: this.props.ucFormularioValues.id,
                                                    id_cliente: this.props.ucFormularioValues.id_cliente, //precisa passar pra buscar a lista atualizada de UCs do cliente
                                                    exclusao_definitiva: false,
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <Grid cols="12 12 3 3" style={{ paddingTop: 5 }}>
                                        <Button
                                            text="Excluir Definitivamente"
                                            event={() => {
                                                this.props.excluirUC({
                                                    id: this.props.ucFormularioValues.id,
                                                    id_cliente: this.props.ucFormularioValues.id_cliente, //precisa passar pra buscar a lista atualizada de UCs do cliente
                                                    exclusao_definitiva: true,
                                                });
                                            }}
                                            type={"danger"}
                                        />
                                    </Grid>
                                </>
                            ) : (
                                <Grid cols="12 12 3 3" style={{ paddingTop: 5 }}>
                                    {this.props.reativar ? (
                                        <Button
                                            text="Reativar"
                                            type={"success"}
                                            event={() => {
                                                this.props.reativarUC({
                                                    id: this.props.ucFormularioValues.id,
                                                    id_cliente: this.props.ucFormularioValues.id_cliente, //precisa passar pra buscar a lista atualizada de UCs do cliente
                                                });
                                            }}
                                        />
                                    ) : (
                                        <Button text="Salvar" submit type={"success"} disabled={readOnly} />
                                    )}
                                </Grid>
                            )}

                            <Grid cols="12 12 3 3" style={{ paddingTop: 5 }}>
                                <Button
                                    text="Voltar"
                                    type={"warning"}
                                    icon={"fa fa-chevron-left"}
                                    event={() => {
                                        this.props.setModoTelaUC(null); //quando fica nulo exibe novamente o formulário do cliente sem alteração
                                    }}
                                />
                            </Grid>
                        </Row>
                    </ContentCardFooter>
                </Form>
            </ContentCard>
        );
    }
}

UnidadeConsumidoraForm = reduxForm({ form: "unidadeConsumidoraForm", destroyOnUnmount: false })(UnidadeConsumidoraForm);
const mapStateToProps = (state) => ({
    usuarioLogado: state.auth.usuarioLogado,
    listaUsuarioImplantacao: state.usuario.lista.filter((item) => item.usuario_implantacao).map((item) => ({ id: item.id, valor: item.nome })),
    ucFormularioValues: getFormValues("unidadeConsumidoraForm")(state),
    listaUcCliente: getFormValues("clienteForm")(state).itens,
    listaConcessionaria: state.cliente.listaConcessionaria,
    listaPlataformaMercadoLivre: state.cliente.listaPlataformaMercadoLivre,
    listaGrupoFaturamento: state.cliente.listaGrupoFaturamento,
    listaModalidadeTarifaria: state.cliente.listaModalidadeTarifaria,
    listaTipoMercado: state.cliente.listaTipoMercado,
    listaTipoMedicao: state.cliente.listaTipoMedicao,
    listaGeracaoTipo: state.cliente.listaGeracaoTipo,
    listaTributacao: state.tributacao.lista,
    listaEstado: state.tributacao.listaEstado,
    listaAtividade: state.tributacao.listaAtividade,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setModoTelaUC,
            initUcForm,
            excluirUC,
            reativarUC,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(UnidadeConsumidoraForm);
