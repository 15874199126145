export function adicionarBloqueioUsuarioImplantacao(
  usuarioLogado,
  listaUcValidarUsuarioImplantacao,
  lista
) {
  //adiciona uma propriedade que diz se deve bloquear ou não os botões
  //e editar e remover, para quando for um usuário implantação logado
  return lista.map((item) => {
    if (!usuarioLogado.usuario_implantacao) return item;

    const uc = listaUcValidarUsuarioImplantacao.find(
      (uc) => uc.id === item.id_unidade_consumidora
    );
    const bloquearEdicaoUsuarioImplantacao =
      !uc || uc.id_usuario_implantacao !== usuarioLogado.id;
    return { ...item, bloquearEdicaoUsuarioImplantacao };
  });
}
