import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import locale from "@amcharts/amcharts4/lang/pt_BR.js";

function montarGraficoClusteredColumnGrupo(state, component, nome, dados = [], titulo, prefixo, sufixo) {
	if (component) {
		component.dispose();
	}

	let chart = am4core.create(nome, am4charts.XYChart3D);
	chart.colors.step = 1;

	chart.colors.list = state.cores;

	chart.language.locale = locale;

	let title = chart.titles.create();
	title.text = titulo;
	title.fontSize = 16;
	title.marginBottom = 30;

	chart.exporting.menu = new am4core.ExportMenu();
	chart.exporting.filePrefix = "Gráfico";
	chart.exporting.menu.align = "right";
	chart.exporting.menu.verticalAlign = "top";
	chart.exporting.menu.items = [
		{
			label: "...",
			menu: [
				{
					type: "png",
					label: "PNG",
				},
			],
		},
	];

	chart.legend = new am4charts.Legend();
	chart.legend.position = "top";
	chart.legend.paddingBottom = 20;
	chart.legend.labels.template.maxWidth = 95;
	if (state.mobile) {
		chart.legend.labels.template.fontSize = 10;
	}
	chart.language.locale["_decimalSeparator"] = ",";
	chart.language.locale["_thousandSeparator"] = ".";
	chart.numberFormatter.numberFormat = "###,###,###,###.00";
	chart.maskBullets = false;
	let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
	xAxis.dataFields.category = "category";
	xAxis.renderer.cellStartLocation = 0.1;
	xAxis.renderer.cellEndLocation = 0.9;
	xAxis.renderer.grid.template.location = 0;
	xAxis.paddingBottom = 15;
	xAxis.renderer.labels.template.wrap = true;
	xAxis.renderer.labels.template.maxWidth = 200;

	let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
	yAxis.min = 0;

	let arrangeColumns = () => {
		let series = chart.series.getIndex(0);

		let w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
		if (series.dataItems.length > 1) {
			let x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
			let x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
			let delta = ((x1 - x0) / chart.series.length) * w;
			if (am4core.isNumber(delta)) {
				let middle = chart.series.length / 2;

				let newIndex = 0;
				chart.series.each(function (series) {
					if (!series.isHidden && !series.isHiding) {
						series.dummyData = newIndex;
						newIndex++;
					} else {
						series.dummyData = chart.series.indexOf(series);
					}
				});
				let visibleCount = newIndex;
				let newMiddle = visibleCount / 2;

				chart.series.each(function (series) {
					let trueIndex = chart.series.indexOf(series);
					let newIndex = series.dummyData;

					let dx = (newIndex - trueIndex + middle - newMiddle) * delta;

					series.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
					series.bulletsContainer.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
				});
			}
		}
	};

	let createSeries = (value, name) => {
		let series = chart.series.push(new am4charts.ColumnSeries3D());
		series.dataFields.valueY = value;
		series.dataFields.categoryX = "category";
		series.name = name;

		series.events.on("hidden", arrangeColumns);
		series.events.on("shown", arrangeColumns);

		let bullet = series.bullets.push(new am4charts.LabelBullet());
		bullet.interactionsEnabled = false;
		// bullet.dy = 2;
		bullet.label.dy = 2;

		bullet.label.text = "{valueY}";
		bullet.label.fill = am4core.color("#ffffff");
		bullet.label.truncate = false;
		// bullet.label.hideOversized = true;

		series.columns.template.tooltipText = `{categoryX}: [bold]${prefixo} {valueY.formatNumber("###,###,###,###.00")} ${sufixo}[/]`;
		series.columns.template.fillOpacity = 0.8;

		return series;
	};

	//lista de anos (nomes das propriedades)
	const series = [];
	dados.forEach((item) => {
		item.anos.forEach((itemAno) => {
			if (!series.includes(itemAno.ano)) {
				series.push(itemAno.ano);
			}
		});
	});
	series.sort();

	const seriesPorMes = [];
	dados.forEach((dado) => {
		const elemento = { category: dado.agrupador };
		//adiciona a lista de propriedades inicialmente com valores zerados,
		series.forEach((nomePropriedade) => {
			elemento[nomePropriedade] = 0;
		});
		seriesPorMes.push(elemento);
	});

	chart.data = seriesPorMes;

	for (let i = 0; i < chart.data.length; i++) {
		const agrupador = dados.find((item) => item.agrupador == (chart.data[i] ? chart.data[i].category : ""));
		if (agrupador) {
			chart.data = chart.data.map((item, j) => {
				if (i == j) {
					const valoresSeries = {};
					series.forEach((nomeSerie) => {
						//EX: {"ano": "2022",	"mes": "01", "valor": 941}
						const itemMesAno = (agrupador.anos || []).find((item) => item && item.ano == nomeSerie) || {};
						valoresSeries[nomeSerie] = itemMesAno.valor || 0;
					});
					return {
						...item,
						...valoresSeries,
					};
				} else {
					return item;
				}
			});
		}
	}

	series.forEach((item) => {
		createSeries(String(item), String(item));
	});
	return chart;
}

export { montarGraficoClusteredColumnGrupo };
