import "../common/template/dependencies";
import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import App from "./app";
import Auth from "../auth/auth";
import "./authOrApp.css";
import { validateToken } from "../auth/authActions";

class AuthOrApp extends Component {
    state = {
        isIos: false,
        isAndroid: false,
        isInstalled: false, // Estado para verificar se o app está instalado
        showInstallPrompt: false,
        deferredPrompt: null,
        showIosMessage: true, // Exibe a mensagem no iOS
    };

    //verifica se o dispositivo está instalado
    checkInstallationStatus() {
        const userAgent = window.navigator.userAgent.toLowerCase();
        const isIos = userAgent.includes("iphone") || userAgent.includes("ipod");
        const isAndroid = userAgent.includes("android");

        const isInstalled = isIos ? window.navigator.standalone || window.matchMedia("(display-mode: standalone)").matches : isAndroid ? window.matchMedia("(display-mode: standalone)").matches : false;

        this.setState({ isIos, isAndroid, isInstalled });
    }

    componentDidMount() {
        this.checkInstallationStatus();

        // Verifica o suporte ao Service Worker
        if ("serviceWorker" in navigator) {
            // console.log("Service Worker está disponível", navigator.serviceWorker);
            if ("BeforeInstallPromptEvent" in window) {
                console.log("BeforeInstallPromptEvent está disponível no window");
                window.addEventListener("beforeinstallprompt", (e) => {
                    e.preventDefault(); // Previne o prompt automático
                    this.setState({ deferredPrompt: e, showInstallPrompt: true });
                });
            }
        } else {
            console.log("Service Worker não está disponível neste navegador.");
        }

        if (this.props.auth.usuario) {
            this.props.validateToken(this.props.auth.usuario.token);
        }
    }

    // Função para exibir o prompt de instalação no Android
    handleInstallClick = () => {
        const { deferredPrompt } = this.state;
        if (deferredPrompt) {
            deferredPrompt.prompt(); // Exibe o prompt
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === "accepted") {
                    console.log("Usuário aceitou o prompt de instalação");
                } else {
                    console.log("Usuário rejeitou o prompt de instalação");
                }
                this.setState({ deferredPrompt: null, showInstallPrompt: false });
            });
        }
    };

    // Função para fechar a mensagem no iOS
    handleCloseIosMessage = () => {
        this.setState({ showIosMessage: false });
    };

    render() {
        const { usuario, validToken } = this.props.auth;
        const { isIos, isAndroid, isInstalled, showInstallPrompt, showIosMessage } = this.state;
        if (usuario && validToken) axios.defaults.headers.common["authorization"] = usuario.token;

        return (
            <>
                {/* Exibe a mensagem de instrução no iOS apenas se o app não estiver instalado */}
                {isIos && !isInstalled && showIosMessage && (
                    <div className="install-banner">
                        <p>Para instalar, adicione à sua tela inicial no Safari!</p>
                        <p>Abra o menu de compartilhamento e selecione "Adicionar à Tela de Início".</p>
                        {/* Botão OK para fechar a mensagem */}
                        <button className="btn-install-ok" onClick={this.handleCloseIosMessage}>
                            Entendi
                        </button>
                    </div>
                )}

                {/* Exibe a mensagem de instrução no Android caso o app não esteja instalado */}
                {isAndroid && !isInstalled && showInstallPrompt && (
                    <div className="install-banner py-3">
                        <button className="btn-install-ok" onClick={this.handleInstallClick}>
                            Instalar Gradios
                        </button>
                        <button className="btn-install-cancel" onClick={() => this.setState({ showInstallPrompt: false })}>
                            Agora não
                        </button>
                    </div>
                )}

                {usuario && validToken ? <App>{this.props.children}</App> : <Auth />}
            </>
        );
    }
}

const mapStateToProps = (state) => ({ auth: state.auth });
const mapDispatchToProps = (dispatch) => bindActionCreators({ validateToken }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AuthOrApp);
