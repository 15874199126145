import React from "react";
export function ProgressBar({ valor }) {
	//se valor for menor que 100
	const tamanhoBarraPx = window.innerWidth < 450 ? 200 : 300;

	const tamanhoAreaColorida = valor < 100 ? (valor * tamanhoBarraPx) / 100 : tamanhoBarraPx;
	const posicaoIndicePx = valor < 100 ? tamanhoBarraPx : (tamanhoBarraPx / valor) * 100;
	const larguraIndice = 5;
	const height = window.innerWidth < 450 ? 20 : 40;
	const cor = valor <= 50 ? "#e24" : valor <= 90 ? "#ffdf67" : "#53ff86";
	return (
		<div
			style={{
				display: "flex",
				position: "relative",
				flexGrow: 0,
				width: tamanhoBarraPx,
				height,
				backgroundColor: "#ebebeb",
				borderRadius: 8,
			}}
		>
			<div style={{ display: "flex", flexGrow: 0, width: tamanhoAreaColorida, height, backgroundColor: cor, borderRadius: 8 }} />
			<div
				style={{
					position: "absolute",
					left: posicaoIndicePx - 5,
					height,
					width: larguraIndice,
					backgroundColor: "#ff4848",
					borderRadius: 5,
				}}
			/>
		</div>
	);
}
