import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";

import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";
import LabelAndInputNumber from "../common/form/labelAndInputNumber";
import LabelAndInputMask from "../common/form/labelAndInputMask";
import Select from "../common/form/select";
import FaturaItem from "./faturaItem/faturaItem";
import { filtrarUcPorDataDesligamento } from "../utils/filtrarUcPorDataDesligamento";
import { ModalForm } from "../common/dialog/modalForm";
import { setModoTela, initForm, setImportacaoValidada, setEngenhariaValidada, alterarIcmsImportado, getLista } from "./faturaActions";
import { getLista as getListaItem } from "./faturaItem/faturaItemActions";

class FaturaForm extends Component {
    state = {
        exibirModalIcms: false,
        recarregarListaAoSair: false,
    };

    componentWillMount() {
        if (this.props.formularioValues.id) {
            this.props.getListaItem(this.props.formularioValues.id);
        }
    }

    componentWillUnmount() {
        this.props.setModoTela("lista");
    }

    render() {
        let usuarioFaturaEdicao = this.props.usuarioLogado && (this.props.usuarioLogado.acesso_fatura_editar || this.props.usuarioLogado.acesso_fatura_editar_fechado);
        let usuarioMultaEdicao = this.props.usuarioLogado && (this.props.usuarioLogado.acesso_multa_editar || this.props.usuarioLogado.acesso_multa_editar_fechado);

        let readOnly = this.props.visualizar || this.props.excluir || this.props.formularioValues.importacao_validada || this.props.engenharia || !usuarioFaturaEdicao;

        return (
            <div>
                {this.state.exibirModalIcms ? (
                    <ModalForm
                        titulo="Alterar ICMS(%) importado"
                        item={{ ...this.props.formularioValues, icms_importado: String(this.props.formularioValues.icms_importado | 0).replace(".", ",") }}
                        handleSubmit={(fatura) => {
                            this.props.alterarIcmsImportado(fatura, () => this.setState({ exibirModalIcms: false, recarregarListaAoSair: true }));
                        }}
                        handleClose={() => this.setState({ exibirModalIcms: false })}
                    >
                        <LabelAndInputNumber name="icms_importado" label="ICMS importado(%)" placeholder="Informe o valor do ICMS (%)" cols="12 12 12 12" casas={2} allowNegative={false} />
                    </ModalForm>
                ) : null}
                <ContentCard>
                    {/*ALERTAS*/}
                    {(this.props.formularioValues.erros_importacao || []).map((erro) => (
                        <div className={`alert alert-${this.props.formularioValues.importacao_validada ? "warning" : "danger"} alert-dismissible`}>
                            <h6>Importação</h6>
                            {erro.mensagem}
                        </div>
                    ))}
                    {this.props.engenharia
                        ? (this.props.formularioValues.erros_engenharia || []).map((erro) => (
                              <div className={`alert alert-${this.props.formularioValues.engenharia_validada ? "warning" : "danger"} alert-dismissible`}>
                                  <h6>Engenharia</h6>
                                  {erro.mensagem}
                              </div>
                          ))
                        : null}

                    {this.props.formularioValues && this.props.formularioValues.id ? (
                        <ContentCardHeader>
                            <Row>
                                {/*
							<Grid cols='6 6 4 2'>
								<Button
									text='Imprimir'
									type={'primary'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.setModoTela('relatorio', {
											...this.props.formularioValues,
											cliente: this.props.listaCliente.filter(item => item.id == this.props.formularioValues.id_cliente)[0],
											unidadeConsumidora: this.props.listaUnidadeConsumidora.filter(item => item.id == this.props.formularioValues.id_unidade_consumidora)[0]
										});
									}} />
							</Grid>
							*/}
                                {/*Validar Importação*/}
                                {!this.props.engenharia && !this.props.formularioValues.importacao_validada ? (
                                    <Grid cols="6 6 4 2">
                                        <Button
                                            text="Validadar e Fechar"
                                            type={"success"}
                                            icon={"fa fa-check"}
                                            disabled={!usuarioFaturaEdicao}
                                            event={() => {
                                                this.props.setImportacaoValidada({
                                                    ...this.props.formularioValues,
                                                    importacao_validada: true,
                                                });
                                            }}
                                        />
                                    </Grid>
                                ) : !this.props.engenharia && this.props.formularioValues.importacao_validada ? (
                                    <Grid cols="6 6 4 2">
                                        <Button
                                            text="Reabrir"
                                            type={"danger"}
                                            icon={"fa fa-unlock"}
                                            //só editar fechado pode reabrir
                                            disabled={!this.props.usuarioLogado || !this.props.usuarioLogado.acesso_fatura_editar_fechado}
                                            event={() => {
                                                this.props.setImportacaoValidada({
                                                    ...this.props.formularioValues,
                                                    importacao_validada: false,
                                                });
                                            }}
                                        />
                                    </Grid>
                                ) : null}

                                {/*Validar Engenharia*/}
                                {this.props.engenharia && this.props.formularioValues.importacao_validada && !this.props.formularioValues.engenharia_validada ? (
                                    <Grid cols="6 6 4 2">
                                        <Button
                                            text="Validadar e Fechar"
                                            type={"success"}
                                            icon={"fa fa-check"}
                                            disabled={!usuarioMultaEdicao}
                                            event={() => {
                                                this.props.setEngenhariaValidada({
                                                    ...this.props.formularioValues,
                                                    engenharia_validada: true,
                                                });
                                            }}
                                        />
                                    </Grid>
                                ) : this.props.engenharia && this.props.formularioValues.importacao_validada && this.props.formularioValues.engenharia_validada ? (
                                    <Grid cols="6 6 4 2">
                                        <Button
                                            text="Reabrir"
                                            type={"danger"}
                                            icon={"fa fa-unlock"}
                                            //só editar fechado pode reabrir
                                            disabled={!this.props.usuarioLogado || !this.props.usuarioLogado.acesso_multa_editar_fechado}
                                            event={() => {
                                                this.props.setEngenhariaValidada({
                                                    ...this.props.formularioValues,
                                                    engenharia_validada: false,
                                                });
                                            }}
                                        />
                                    </Grid>
                                ) : null}
                            </Row>
                        </ContentCardHeader>
                    ) : null}
                    <Form event={this.props.handleSubmit}>
                        <ContentCardBody>
                            <Row>
                                <Field
                                    name="id_cliente"
                                    component={Select}
                                    //se usuario implantacao estiver logado
                                    //filtra para poder incluir apenas itens na UC
                                    //que ele é o implantador
                                    options={
                                        this.props.usuarioLogado.usuario_implantacao
                                            ? this.props.listaCliente.filter((cliente) => cliente.itens.find((uc) => uc.id_usuario_implantacao == this.props.usuarioLogado.id))
                                            : this.props.listaCliente
                                    }
                                    label="Cliente"
                                    cols="12 12 12 12"
                                    placeholder="Selecione o cliente"
                                    readOnly={readOnly}
                                />
                            </Row>
                            <Row>
                                <Field
                                    name="id_unidade_consumidora"
                                    component={Select}
                                    //se usuario implantacao estiver logado
                                    //filtra para poder incluir apenas itens na UC
                                    //que ele é o implantador
                                    options={this.props.listaUnidadeConsumidora.filter((item) => {
                                        const implantacaoLogado = this.props.usuarioLogado.usuario_implantacao;
                                        const ucClienteSelecionado = item.id_cliente == this.props.formularioValues.id_cliente;
                                        if (implantacaoLogado) {
                                            const usuarioImplantacaoVinculadoUc = item.id_usuario_implantacao == this.props.usuarioLogado.id;
                                            return usuarioImplantacaoVinculadoUc && ucClienteSelecionado;
                                        }
                                        return ucClienteSelecionado;
                                    })}
                                    label="Unidade Consumidora"
                                    cols="12 12 12 12"
                                    placeholder="Selecione a unidade consumidora"
                                    readOnly={readOnly}
                                />
                            </Row>
                            <Row>
                                <Field name="competencia" component={LabelAndInputMask} label="Competência" placeholder="Informe a competência" cols="12 12 12 12" mask="99/9999" readOnly={readOnly} />
                            </Row>
                            {/*}<Row>
							<Field
								name='observacao'
								component={LabelAndInput}
								label='Observação' placeholder='Informe a observação'
								cols='12 12 12 12'
								readOnly={readOnly} />
						</Row>*/}
                            <Row>
                                <Field
                                    name="observacao_importacao"
                                    component={LabelAndInput}
                                    label="Observação da análise de importação"
                                    placeholder="Informe a observação da análise de importação"
                                    cols="12 12 12 12"
                                    readOnly={readOnly}
                                />
                            </Row>
                            <Row>
                                <LabelAndInputNumber
                                    name="valor_fatura"
                                    label="Valor Importado da Fatura"
                                    placeholder="Informe o valor importado da fatura"
                                    cols="6 4 3 2"
                                    casas={2}
                                    readOnly={!this.props.formularioValues.id || readOnly}
                                    value={this.props.formularioValues.valor_fatura}
                                    onChange={(data) => {
                                        this.props.initForm({
                                            ...this.props.formularioValues,
                                            valor_fatura: data.target.value,
                                        });
                                    }}
                                />
                            </Row>
                            {this.props.formularioValues.importacao_validada ? (
                                <Row>
                                    <Field
                                        name="observacao_engenharia"
                                        component={LabelAndInput}
                                        label="Observação da análise de engenharia"
                                        placeholder="Informe a observação da análise de engenharia"
                                        cols="12 12 12 12"
                                        readOnly={!this.props.engenharia || this.props.formularioValues.engenharia_validada}
                                    />
                                </Row>
                            ) : null}
                        </ContentCardBody>
                        {this.props.modoTelaFaturaItem === "lista" ? (
                            <ContentCardFooter style={{ backgroundColor: "#FFFFFF" }}>
                                <Row alignCenter>
                                    {this.props.excluir ? (
                                        <Grid cols="6 6 4 3">
                                            <Button
                                                text="Excluir"
                                                submit
                                                type={"danger"}
                                                disabled={this.props.formularioValues.importacao_validada || this.props.engenharia || !usuarioFaturaEdicao}
                                                icon={"fa fa-trash"}
                                            />
                                        </Grid>
                                    ) : !this.props.engenharia ? (
                                        <Grid cols="6 6 4 3">
                                            <Button
                                                text="Salvar"
                                                submit
                                                disabled={this.props.visualizar || this.props.formularioValues.importacao_validada || !usuarioFaturaEdicao}
                                                type={"success"}
                                                icon={"fa fa-check"}
                                            />
                                        </Grid>
                                    ) : null}

                                    <Grid cols="6 6 4 3">
                                        <Button
                                            text="Voltar"
                                            type={"warning"}
                                            icon={"fa fa-chevron-left"}
                                            event={() => {
                                                this.props.setModoTela("lista");
                                                if (this.state.recarregarListaAoSair) {
                                                    this.props.getLista();
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Row>
                            </ContentCardFooter>
                        ) : null}
                    </Form>

                    {this.props.formularioValues.id ? <FaturaItem exibirModalIcms={() => this.setState({ exibirModalIcms: true })} engenharia={this.props.engenharia} /> : null}
                </ContentCard>
            </div>
        );
    }
}

FaturaForm = reduxForm({ form: "faturaForm", destroyOnUnmount: false })(FaturaForm);
const mapStateToProps = (state) => ({
    sessao: state.auth.usuario,
    usuarioLogado: state.auth.usuarioLogado,
    modoTelaFaturaItem: state.faturaItem.modoTela,
    formularioValues: getFormValues("faturaForm")(state),
    registro: state.fatura.registro,
    listaCliente: state.fatura.listaCliente,
    listaUnidadeConsumidora: filtrarUcPorDataDesligamento(state.fatura.listaUnidadeConsumidora),
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setModoTela,
            initForm,
            getLista,
            getListaItem,
            setImportacaoValidada,
            setEngenhariaValidada,
            alterarIcmsImportado,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(FaturaForm);
