import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logout } from "../../auth/authActions";
import DateFormat from "../../common/dateFormat/DateFormat";

import {
	getLista as getListaNotificacao,
	setVisto as setNotificacaoVista,
	setLida as setNotificacaoLida,
} from "../../notificacao/notificacaoActions";
import {
	setFiltro as setFiltroFatura,
	getLista as getListaFatura,
	getListaCliente as getListaClienteFatura,
	getListaUnidadeConsumidora as getListaUnidadeConsumidoraFatura,
} from "../../fatura/faturaActions";

class Navbar extends Component {
	constructor(props) {
		super(props);
		this.state = { open: false, notificacaoOpen: false };

		this.props.getListaNotificacao();
		this.props.getListaClienteFatura();
		this.props.getListaUnidadeConsumidoraFatura();

		setInterval(() => {
			this.props.getListaNotificacao();
		}, 600000);
	}

	changeOpen() {
		this.setState({ open: !this.state.open });
	}

	render() {
		const { nome, login } = this.props.usuario;

		let dataNotificacao = null;
		let diasSemana = ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"];

		return (
			<ul className="navbar-nav ml-auto">
				<li className="nav-item dropdown">
					<div
						style={{
							marginTop: 6,
							fontSize: window.innerWidth <= 575 ? "0.7rem" : "0.9rem",
						}}
					>
						{nome ? `Olá ${nome}, seja Bem-Vindo ao Gradios` : null}
					</div>
				</li>
				<li className="nav-item dropdown" style={{ color: "#BA0000" }}>
					<a className="nav-link" data-toggle="dropdown" href="#">
						<i className="fas fa-bell" style={{ color: "#BA0000" }}></i>
						<span className="badge badge-warning navbar-badge">
							{this.props.lista_notificacao.filter((item) => !item.visto).length > 0
								? this.props.lista_notificacao.filter((item) => !item.visto).length
								: ""}
						</span>
					</a>
					<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{ backgroundColor: "#BA0000" }}>
						<div id="toastsContainerTopRight" className="toasts-top-right fixed" style={{ width: 330, marginTop: 33, marginRight: 10 }}>
							<div className="toast fade show" role="alert" aria-live="assertive" aria-atomic="true">
								<div className="toast-header">
									<strong className="mr-auto" style={{ paddingLeft: 110 }}>
										Notificações
									</strong>
									<button data-dismiss="toast" type="button" className="ml-2 mb-1 close" aria-label="Close">
										<span aria-hidden="true" onClick={() => this.setState({ ...this.state, notificacaoOpen: false })}>
											×
										</span>
									</button>
								</div>
								<div className="toast-body">
									<div
										className={``}
										style={{
											maxHeight: (window.innerHeight || 0) - 200,
											overflow: "auto",
										}}
									>
										<div style={{ textAlign: "right" }}>
											<a
												onClick={() => {
													this.props.lista_notificacao
														.filter((item) => item.visto)
														.forEach((item) => {
															this.props.setNotificacaoLida(item);
														});
												}}
											>
												<i
													className="fas fa-check"
													style={{
														fontSize: 14,
														fontWeight: "bold",
														color: "#757575",
														marginRight: 16,
														marginBottom: 16,
														cursor: "pointer",
													}}
												>
													Limpar Vistas
												</i>
											</a>

											<a
												onClick={() => {
													this.props.lista_notificacao.forEach((item) => {
														this.props.setNotificacaoLida(item);
													});
												}}
											>
												<i
													className="fas fa-check-double"
													style={{
														fontSize: 14,
														fontWeight: "bold",
														color: "#757575",
														marginRight: 16,
														marginBottom: 16,
														cursor: "pointer",
													}}
												>
													Limpar Todas
												</i>
											</a>
										</div>
										<div className="dropdown-divider"></div>
										{this.props.lista_notificacao.map((item) => {
											let dataNotificacaoAtual = `${diasSemana[new Date(item.datahora_inclusao).getDay()]}, ${new Date(
												item.datahora_inclusao
											).getDate()}`;
											let dataAlterada = false;
											if (!dataNotificacao || dataNotificacao != dataNotificacaoAtual) {
												dataNotificacao = dataNotificacaoAtual;
												dataAlterada = true;
											}

											let icone = "";

											switch (item.tipo) {
												case "fatura_importada":
													icone = "fas fa-bolt";
													break;
												case "erro_engenharia":
													icone = "fas fa-tasks";
													break;
												case "engenharia_validada":
													icone = "fas fa-tasks";
													break;
												default:
													break;
											}

											return (
												<div key={item.id}>
													{dataAlterada ? (
														<div
															style={{
																padding: 4,
																textAlign: "center",
																fontWeight: "bold",
																backgroundColor: "#e0e0e0",
																color: "#424242",
															}}
														>
															{dataNotificacao}
														</div>
													) : null}
													<div
														className=""
														style={{ cursor: "pointer" }}
														onClick={() => {
															window.location.href = `#${item.link}`;
															this.props.setNotificacaoVista(item);
															this.props.setFiltroFatura({
																...this.props.filtroFatura,
																id_cliente: item.id_cliente,
																id_unidade_consumidora: item.id_unidade_consumidora,
															});
															this.props.getListaFatura();
														}}
													>
														<div
															style={{
																fontSize: 14,
																fontWeight: item.visto ? null : "bold",
															}}
														>
															{item.titulo}
														</div>
														<div
															style={{
																fontSize: 13,
																fontWeight: item.visto ? null : "bold",
															}}
														>
															{item.mensagem}
														</div>
														<div
															style={{
																fontSize: 13,
																fontWeight: item.visto ? null : "bold",
															}}
														>
															{item.nome_cliente}
														</div>
														<div
															style={{
																fontSize: 13,
																fontWeight: item.visto ? null : "bold",
															}}
														>
															{item.nome_unidade_consumidora}
														</div>
														<a
															style={{
																display: "flex",
																flexDirection: "row",
																justifyContent: "space-between",
															}}
														>
															<div
																style={{
																	fontSize: 12,
																	fontWeight: "bold",
																	color: "#424242",
																}}
															>
																{DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao).split(" ")[1]}
															</div>
														</a>
													</div>
													<div className="dropdown-divider"></div>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					</div>
				</li>

				<li className="nav-item dropdown" style={{ color: "#BA0000" }}>
					<a className="nav-link" data-toggle="dropdown" href="#">
						<i className="fas fa-user" style={{ color: "#BA0000" }}></i>
					</a>
					<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{ backgroundColor: "#BA0000" }}>
						<div
							style={{
								color: "#fff",
								padding: 12,
								fontWeight: "bold",
								textAlign: "center",
							}}
						>
							<span className="">Perfil</span>
						</div>
						<div className="dropdown-divider"></div>
						<div style={{ color: "#fff", padding: 12 }}>
							<i className="fas fa-user mr-2"></i> Nome
							<span className="float-right text-sm" style={{ color: "#fff" }}>
								{nome}
							</span>
						</div>
						<div className="dropdown-divider"></div>
						<div style={{ color: "#fff", padding: 12 }}>
							<i className="fas fa-envelope mr-2"></i> Usuário
							<span className="float-right text-sm" style={{ color: "#fff" }}>
								{login}
							</span>
						</div>
						<div className="dropdown-divider"></div>
						<a
							href="#"
							onClick={this.props.logout}
							style={{ color: "#BA0000", backgroundColor: "#fff" }}
							className="btn btn-block btn-primary btn-sm"
						>
							Sair
						</a>
					</div>
				</li>
			</ul>
		);
	}
}

const mapStateToProps = (state) => ({
	usuario: state.auth.usuario,
	lista_notificacao: state.notificacao.lista,
	filtroFatura: state.fatura.filtro,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			logout,
			getListaNotificacao,
			setNotificacaoVista,
			setNotificacaoLida,
			setFiltroFatura,
			getListaFatura,
			getListaClienteFatura,
			getListaUnidadeConsumidoraFatura,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

/*
<div className='navbar-custom-menu'>
	<ul className='nav navbar-nav'>
		<li onMouseLeave={() => this.changeOpen()}
			className={`dropdown user user-menu ${this.state.open ? 'open' :''}`}>
			<a href='javascript:;' onClick={() => this.changeOpen()}
				aria-expanded={this.state.open ? 'true' : 'false'}
				className='dropdown-toggle'
				data-toggle='dropdown'>
				<i className='fa fa-user'/>
				<span className='hidden-xs'>{nome}</span>
			</a>
			<ul className='dropdown-menu'>
				<li className='user-header'>
					<p>{nome}<small>{usuario}</small></p>
				</li>
				<li className='user-footer'>
					<div className='pull-right'>
						<a href='#' onClick={this.props.logout}
							className='btn btn-default btn-flat'>Sair</a>
					</div>
				</li>
			</ul>
		</li>
	</ul>
</div>
*/
