const INITIAL_STATE = {
    registro: {},
    aguardando: false,
    modoTela: "lista",
    lista: [],
    listaCliente: [],
    listaConcessionaria: [],
    listaGestaoProcessoTipo: [],
    listaUnidadeConsumidora: [],
    listaStatus: [
        { id: "false", valor: "EM ANDAMENTO" },
        { id: "true", valor: "CONCLUÍDO" },
    ],
    listaSolucao: [
        { id: "false", valor: "AÇÃO PARCIAL" },
        { id: "true", valor: "SOLUÇÃO FINAL" },
    ],
    filtro: {
        id_tipo: null,
        status: "false",
        id_cliente: null,
        numero_protocolo: null,
        id_concessionaria: null,
        id_unidade_consumidora: null,
        id_usuario_responsavel: null,
    },
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
        case "GESTAO_PROCESSO_MODO_TELA":
            return {
                ...state,
                modoTela: action.payload.modo,
                registro: action.payload.registro,
            };

        case "GESTAO_PROCESSO_SET_AGUARDANDO":
            return { ...state, aguardando: action.payload };

        case "GESTAO_PROCESSO_FILTRO":
            return { ...state, filtro: action.payload };

        case "GESTAO_PROCESSO_LISTADO":
            return {
                ...state,
                lista: (action.payload.data || []).sort((a, b) => b.estagio_andamento - a.estagio_andamento),
            };

        case "GESTAO_PROCESSO_CLIENTE_SELECT_LISTADO":
            return {
                ...state,
                listaCliente: action.payload.data,
            };

        case "GESTAO_PROCESSO_UNIDADE_CONSUMIDORA_SELECT_LISTADO":
            return {
                ...state,
                listaUnidadeConsumidora: action.payload.data,
            };

        case "GESTAO_PROCESSO_CONCESSIONARIA_SELECT_LISTADO":
            return {
                ...state,
                listaConcessionaria: action.payload.data,
            };

        case "GESTAO_PROCESSO_TIPO_SELECT_LISTADO":
            return {
                ...state,
                listaGestaoProcessoTipo: action.payload.data,
            };

        default:
            return state;
    }
};
