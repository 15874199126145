const INITIAL_STATE = {
	registro: {},
	aguardando: false,
	modoTela: 'lista',
	lista: [],
	listaCliente: [],
	listaUnidadeConsumidora: [],
	filtro: {
		ano: new Date().getFullYear(),
		id_cliente: null,
		id_unidade_consumidora: null
	},
	listaAmpliacao: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'GERACAO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'GERACAO_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

		case 'GERACAO_FILTRO':
			return { ...state, filtro: action.payload };

        case 'GERACAO_LISTADO':
            return {
				...state,
				lista: action.payload.data.map(item => ({
					...item,
					itens: (item.itens || []).filter(uc => uc)
				}))
			};

        case 'GERACAO_CLIENTE_SELECT_LISTADO':
			return {
				...state,
				listaCliente: action.payload.data
			};

        case 'GERACAO_UNIDADE_CONSUMIDORA_SELECT_LISTADO':
			return {
				...state,
				listaUnidadeConsumidora: action.payload.data
			};

        case 'GERACAO_AMPLIACAO_LISTADO':
            return {
				...state,
				listaAmpliacao: action.payload.data
			};

        default:
            return state;
    }
}
