const INITIAL_STATE = {
	registro: {},
	aguardando: false,
	modoTela: "lista",
	lista: [],
	listaCliente: [],
	listaUnidadeConsumidora: [],
	filtro: {
		id_cliente: null,
	},
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case "EMPENHO_MODO_TELA":
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro,
			};

		case "EMPENHO_SET_AGUARDANDO":
			return { ...state, aguardando: action.payload };

		case "EMPENHO_FILTRO":
			return { ...state, filtro: action.payload };

		case "EMPENHO_LISTADO":
			return {
				...state,
				lista: action.payload.data,
			};

		case "EMPENHO_CLIENTE_SELECT_LISTADO":
			return {
				...state,
				listaCliente: action.payload.data,
			};

		case "EMPENHO_UNIDADE_CONSUMIDORA_SELECT_LISTADO":
			return {
				...state,
				listaUnidadeConsumidora: action.payload.data,
			};

		default:
			return state;
	}
};
