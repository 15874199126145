import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";
import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInputNumber from "../common/form/labelAndInputNumber";
import Select from "../common/form/select";

import { setModoTela, initForm } from "./tributacaoActions";

class TributacaoForm extends Component {
	render() {
		let readOnly = this.props.excluir || this.props.visualizar ? "readOnly" : "";

		return (
			<>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<Row>
							<Grid cols="12 12 6 6">
								<ContentCardBody>
									<Row>
										<Select
											cols="12 12 12 12"
											name="id_estado"
											options={this.props.listaEstado}
											label="UF"
											placeholder="Selecione a UF"
											value={this.props.formularioValues.id_estado}
											onChange={(value) => {
												this.props.initForm({
													...this.props.formularioValues,
													id_estado: value,
												});
											}}
											readOnly={readOnly}
										/>
									</Row>
									<Row>
										<Select
											cols="12 12 12 12"
											name="id_atividade"
											options={this.props.listaAtividade.filter(
												(atividade) =>
													!this.props.lista
														.filter((tributacao) => tributacao.id_estado == this.props.formularioValues.id_estado)
														.find((tributacao) => tributacao.id_atividade == atividade.id)
											)}
											label="Tipo atividade ICMS"
											placeholder="Selecione o tipo de atividade ICMS"
											value={this.props.formularioValues.id_atividade}
											onChange={(value) => {
												this.props.initForm({
													...this.props.formularioValues,
													id_atividade: value,
												});
											}}
											readOnly={readOnly || !this.props.formularioValues.id_estado}
										/>
									</Row>
									<Row>
										<Field
											cols="12 12 12 12"
											name="aliquota_icms"
											component={LabelAndInputNumber}
											label="Alíquota ICMS (%)"
											placeholder="Informe a alíquota de ICMS"
											casas={2}
											readOnly={readOnly}
										/>
									</Row>
								</ContentCardBody>
							</Grid>
						</Row>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols="6 6 4 3">
									{this.props.excluir ? (
										<Button
											text="Excluir"
											submit
											type={"danger"}
											icon={"fa fa-trash"}
											disabled={!this.props.usuarioLogado.acesso_tributacao_excluir}
										/>
									) : (
										<Button
											text={"Salvar"}
											submit
											type={"success"}
											icon={"fa fa-check"}
											disabled={
												!this.props.usuarioLogado.acesso_tributacao_adicionar ||
												!this.props.usuarioLogado.acesso_tributacao_editar ||
												this.props.visualizar
											}
										/>
									)}
								</Grid>
								<Grid cols="6 6 4 3">
									<Button text="Voltar" type={"warning"} icon={"fa fa-chevron-left"} event={() => this.props.setModoTela("lista")} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</Form>
				</ContentCard>
			</>
		);
	}
}

TributacaoForm = reduxForm({
	form: "tributacaoForm",
	destroyOnUnmount: false,
})(TributacaoForm);
const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues("tributacaoForm")(state),
	registro: state.tributacao.registro,
	filtro: state.tributacao.filtro,
	lista: state.tributacao.lista,
	listaEstado: state.tributacao.listaEstado,
	listaAtividade: state.tributacao.listaAtividade,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setModoTela,
			initForm,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(TributacaoForm);
