const INITIAL_STATE = {
	registro: {},
	aguardando: false,
	modoTela: "lista",
	lista: [],
	listaEstado: [],
	listaAtividade: [],
	filtro: {
		id_estado: null,
		id_atividade: null,
	},
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case "TRIBUTACAO_MODO_TELA":
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro,
			};

		case "TRIBUTACAO_SET_AGUARDANDO":
			return { ...state, aguardando: action.payload };

		case "TRIBUTACAO_FILTRO":
			return { ...state, filtro: action.payload };

		case "TRIBUTACAO_LISTADO":
			return {
				...state,
				lista: action.payload.data,
			};

		case "TRIBUTACAO_UF_SELECT_LISTADO":
			return {
				...state,
				listaEstado: action.payload.data,
			};

		case "TRIBUTACAO_ATIVIDADE_SELECT_LISTADO":
			return {
				...state,
				listaAtividade: action.payload.data,
			};

		default:
			return state;
	}
};
