import React from "react";
import Grid from "../layout/grid";

export default (props) => {
	const titleMarcado = props.title && (props.title.marcado || "");
	const titleDesmarcado = props.title && (props.title.desmarcado || "");
	const propsInput = props.input || {};
    const valueRecebido = props.value || propsInput.value;
    const onChange = props.onChange || propsInput.onChange;
    const modoCard = !!props.modoCard;
    return (
        <Grid cols={props.cols} style={{ display: props.gridDisplay, justifyContent: props.gridJustifyContent, alignItems: props.gridAlignItems }}>
            <div
                htmlFor={props.id}
                className="form-group checkbox"
                style={{
                    display: "flex",
                    justifyContent: props.alignCenter || modoCard ? "center" : "start",
                    alignItems: "center",
                    cursor: "pointer",
                    marginBottom: 0,
                }}
                title={props.value || (props.input && props.input.value === true) ? titleMarcado : titleDesmarcado}
            >
                <label
                    htmlFor={props.id}
                    style={{
                        ...props.style,
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: props.style && props.style.justifyContent,
                        alignItems: (props.style && props.style.alignItems) || "center",
                        cursor: "pointer",
                    }}
                >
                    <div style={{ marginRight: 10, color: props.colorIcon }}>{modoCard ? !!valueRecebido ? <i className="fas fa-check"></i> : <i className="fas fa-square"></i> : null}</div>
                    <input
                        id={props.id}
                        {...propsInput}
                        style={{
                            cursor: "pointer",
                            marginRight: 10,
                            display: modoCard ? "none" : null,
                        }}
                        placeholder={props.placeholder}
                        type="checkbox"
                        checked={!!valueRecebido}
                        disabled={props.readOnly}
                        onChange={onChange}
                    />
                    {props.label}
                </label>
            </div>
        </Grid>
    );
};
