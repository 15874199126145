import "./auth.css";
import React, { Component } from "react";
import { reduxForm, Field, getFormValues } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { login, setFinalizarAnimacaoLogo, initForm } from "./authActions";
import Row from "../common/layout/row";
import If from "../common/operator/if";
import Messages from "../common/msg/messages";
import Input from "../common/form/inputAuth";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { createMuiTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import { ThemeProvider } from "@material-ui/styles";
import imagemLogo from "../assets/images/login_gif_extended.gif";
import imagemLogoImagem from "../assets/images/login_imagem.png";
import imagemLogoEficience from "../assets/images/by_eficience.png";
import imagemFundo from "../assets/images/login_fundo.jpg";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import sleep from "../utils/sleep";

class Auth extends Component {
	state = {
		imagemLogo: { src: null },
		classeAnimacaoForm: "",
		loginMode: true,
		verSenha: false,
	};

	componentDidMount() {
		this.mounted = true;

		this.executarAnimacaoLogo();
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	getStyles() {
		return makeStyles((theme) => ({
			paper: {
				marginTop: theme.spacing(8),
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			},
			avatar: {
				margin: theme.spacing(1),
				backgroundColor: theme.palette.secondary.main,
			},
			form: {
				width: "100%",
				marginTop: theme.spacing(1),
			},
			submit: {
				margin: theme.spacing(3, 0, 2),
			},
		}));
	}

	getTheme() {
		return createMuiTheme({
			palette: {
				primary: {
					// light: '#fff',
					main: "#fff",
					// dark: '#fff',
					contrastText: "#b80000",
				},
				secondary: {
					// light: '#fff',
					main: "#fff",
					// dark: '#fff',
					// contrastText: '#fff',
				},
				// Usado por `getContrastText()` para maximizar o contraste entre
				// o plano de fundo e o texto.
				contrastThreshold: 3,
				// Usado pelas funções abaixo para mudança de uma cor de luminância por aproximadamente
				// dois índices dentro de sua paleta tonal.
				// Por exemplo, mude de Red 500 para Red 300 ou Red 700.
				tonalOffset: 0.2,
			},
		});
	}
	async executarAnimacaoLogo() {
		await sleep(800);
		if (this.mounted) this.setState({ ...this.state, imagemLogo: { src: imagemLogo } });
		await sleep(1010);
		if (this.mounted) this.setState({ ...this.state, imagemLogo: { src: imagemLogoImagem } });
		await sleep(900);
		if (this.mounted) this.setState({ ...this.state, classeAnimacaoForm: " animacao-form" });
	}

	render() {
		const classes = this.getStyles();
		return (
			<ThemeProvider theme={this.getTheme()}>
				<div style={{ backgroundColor: "#b80000", height: "100%" }}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							height: "90vh",
							backgroundSize: "cover",
						}}
					>
						<Container component="main" maxWidth="xs">
							<CssBaseline />
							<div
								className={classes.paper}
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "end",
									alignItems: "center",
									marginTop: -60,
								}}
							>
								{this.state.imagemLogo.src ? (
									<img
										src={this.state.imagemLogo.src}
										style={{
											maxWidth: 280,
											marginLeft: 65,
											marginRight: 65,
											marginBottom: 20,
										}}
									/>
								) : (
									<img
										src={imagemLogo}
										style={{
											height: "100%",
											maxWidth: 280,
											marginLeft: 65,
											marginRight: 65,
											marginBottom: 20,
											visibility: "hidden",
										}}
									/>
								)}

								<form
									className={classes.form + this.state.classeAnimacaoForm}
									noValidate
									style={{ opacity: this.state.classeAnimacaoForm ? "" : "0%" }}
									onSubmit={this.props.handleSubmit((v) => {
										this.props.login(v);
									})}
								>
									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										color="primary"
										id="login"
										label="Usuário"
										name="login"
										autoFocus
										focused
										inputProps={{
											style: { color: "#fff", border: "#fff" },
										}}
										InputLabelProps={{
											style: { color: "#fff", backgroundColor: "#b80000" },
										}}
										onChange={(e) => {
											this.props.initForm({
												...this.props.formularioValues,
												login: e.target.value,
											});
										}}
									/>

									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										color="primary"
										name="senha"
										label="Senha"
										type={this.state.verSenha ? "text" : "password"}
										id="senha"
										password="true"
										focused
										InputProps={{
											style: { color: "#fff", border: "#fff" },
											endAdornment: (
												<InputAdornment position="end" style={{ cursor: "pointer" }}>
													{this.state.verSenha ? (
														<VisibilityOff
															onClick={() =>
																this.setState({
																	...this.state,
																	verSenha: !this.state.verSenha,
																})
															}
														/>
													) : (
														<Visibility
															onClick={() =>
																this.setState({
																	...this.state,
																	verSenha: !this.state.verSenha,
																})
															}
														/>
													)}
												</InputAdornment>
											),
										}}
										InputLabelProps={{
											style: { color: "#fff", backgroundColor: "#b80000" },
										}}
										onChange={(e) => {
											this.props.initForm({
												...this.props.formularioValues,
												senha: e.target.value,
											});
										}}
									/>

									<Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} style={{ marginTop: 16 }}>
										Entrar
									</Button>
								</form>
							</div>
							<Snackbar open={this.props.message.open}>
								<MuiAlert severity={this.props.message.type} variant="filled">
									{this.props.message.text}
								</MuiAlert>
							</Snackbar>
						</Container>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							marginTop: -60,
						}}
					>
						<img
							src={imagemLogoEficience}
							style={{
								width: "20%",
								maxWidth: 100,
								// opacity: this.state.carregamento,
							}}
						/>
					</div>
				</div>
			</ThemeProvider>
		);
	}
}

Auth = reduxForm({ form: "authForm" })(Auth);
const mapStateToProps = (state) => ({
	message: state.auth.message,
	formularioValues: getFormValues("authForm")(state),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ login, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Auth);
