import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import locale from "@amcharts/amcharts4/lang/pt_BR.js";

function montarGraficoColumnLine({
	state,
	component,
	nome,
	dados = [],
	category,
	valueLine,
	valueColumn,
	nameLine = "",
	nameColumn = "",
	titulo,
	prefixo,
	sufixo,
	negativo = false,
	fontColor = "#ffffff",
	positionBullet = -7,
	dimensions = "3D",
	fontSizeTitle = 16,
	fontWeightTitle = "normal",
	mostrarLegenda = false,
	chartBackground = null,
}) {
	if (component) {
		component.dispose();
	}

	let chart = dimensions == "2D" ? am4core.create(nome, am4charts.XYChart) : am4core.create(nome, am4charts.XYChart3D);
	chart.colors.step = 1;

	if (state) {
		chart.colors.list = state.cores;
	}

	chart.language.locale = locale;

	let title = chart.titles.create();
	title.text = titulo;
	title.fontSize = fontSizeTitle;
	title.fontWeight = fontWeightTitle;
	title.marginBottom = 30;

	// chart.exporting.menu = new am4core.ExportMenu();
	// chart.exporting.filePrefix = "Gráfico";
	// chart.exporting.menu.align = "right";
	// chart.exporting.menu.verticalAlign = "top";
	// chart.exporting.menu.items = [
	// 	{
	// 		label: "...",
	// 		menu: [
	// 			{
	// 				type: "png",
	// 				label: "PNG",
	// 			},
	// 		],
	// 	},
	// ];

	if (mostrarLegenda) {
		chart.legend = new am4charts.Legend();
		chart.legend.position = "top";
		// chart.legend.paddingBottom = 20;
		// chart.legend.labels.template.maxWidth = 95;
	}

	chart.language.locale["_decimalSeparator"] = ",";
	chart.language.locale["_thousandSeparator"] = ".";
	chart.numberFormatter.numberFormat = "###,###,###,###.00";
	chart.background.fill = chartBackground;
	chart.maskBullets = false;
	// Add cursor
	chart.cursor = new am4charts.XYCursor();

	let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
	xAxis.dataFields.category = category;
	// xAxis.renderer.cellStartLocation = 0.1;
	// xAxis.renderer.cellEndLocation = 0.9;
	// xAxis.renderer.grid.template.location = 0;
	// xAxis.paddingBottom = 15;
	// xAxis.renderer.minGridDistance = 5;
	// xAxis.renderer.labels.template.maxWidth = 120;
	// xAxis.renderer.labels.template.wrap = true;
	// xAxis.renderer.labels.template.truncate = false;

	let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
	// yAxis.min = negativo ? null : 0;

	// yAxis.renderer.line.strokeOpacity = 1;
	// yAxis.renderer.line.strokeWidth = 2;

	// if (chart.yAxes.indexOf(yAxis) != 0) {
	// 	yAxis.syncWithAxis = chart.yAxes.getIndex(0);
	// }

	const createLineSeries = (valueLine, nameLine) => {
		let lineSeries = chart.series.push(new am4charts.LineSeries());
		lineSeries.dataFields.valueY = valueLine;
		lineSeries.dataFields.categoryX = category;
		lineSeries.strokeWidth = 2;
		lineSeries.yAxis = yAxis;
		lineSeries.name = nameLine;
		lineSeries.tooltipText = `{name}: [bold]${prefixo} {valueY.formatNumber("###,###,###,###.00")} ${sufixo}[/]`;
		lineSeries.tensionX = 0.8;
		lineSeries.showOnInit = true;

		let bullet = lineSeries.bullets.push(new am4charts.LabelBullet());
		bullet.interactionsEnabled = false;
		bullet.label.dy = positionBullet;
		bullet.label.text = `${prefixo} {valueY.formatNumber("###,###,###,###.00")}${sufixo}`;
		bullet.label.fill = am4core.color(fontColor);
		bullet.label.truncate = false;

		// bullet.label.hideOversized = true;

		return lineSeries;
	};
	const createColumnSeries = (valueColumn, nameColumn) => {
		let columnSeries = chart.series.push(new am4charts.ColumnSeries());
		columnSeries.dataFields.valueY = valueColumn;
		columnSeries.dataFields.categoryX = category;
		columnSeries.strokeWidth = 2;
		columnSeries.yAxis = yAxis;
		columnSeries.name = nameColumn;
		columnSeries.tooltipText = `{name}: [bold]${prefixo} {valueY.formatNumber("###,###,###,###.00")} ${sufixo}[/]`;
		columnSeries.showOnInit = true;
		columnSeries.stroke = am4core.color("#ff3355");
		columnSeries.fill = am4core.color("#ff5577");
		columnSeries.fillOpacity = 0.2;

		let bullet = columnSeries.bullets.push(new am4charts.LabelBullet());
		bullet.interactionsEnabled = false;
		bullet.label.dy = positionBullet;
		bullet.label.text = `${prefixo} {valueY.formatNumber("###,###,###,###.00")}${sufixo}`;
		bullet.label.fill = am4core.color(fontColor);
		bullet.label.truncate = false;

		// bullet.label.hideOversized = true;

		return columnSeries;
	};

	chart.data = [...dados];

	createLineSeries(valueLine, nameLine);
	createColumnSeries(valueColumn, nameColumn);

	return chart;
}

export { montarGraficoColumnLine };
