export function listarMesesAnosEntreCompetencias(
  competenciaInicioComBarra,
  competenciaFimComBarra
) {
  //exemplo de parâmetro = listarMesesAnosEntreCompetencias("02/2023", "01/2025"),
  //exemplo retorno:
  //[
  //  { mes: '02', ano: '2023' },
  //  { mes: ... , ano:   ...  },
  //  { mes: '01', ano: '2025' }
  //]
  const mes_inicio_teste = competenciaInicioComBarra.split("/")[0];
  const ano_inicio_teste = competenciaInicioComBarra.split("/")[1];
  const mes_fim_teste = competenciaFimComBarra.split("/")[0];
  const ano_fim_teste = competenciaFimComBarra.split("/")[1];
  let competenciaInicio = parseInt(ano_inicio_teste + mes_inicio_teste);
  const competenciaFim = parseInt(ano_fim_teste + mes_fim_teste);

  const periodo_teste = [];
  while (competenciaInicio <= competenciaFim) {
    const mes = String(competenciaInicio).substring(4, 6);
    const ano = String(competenciaInicio).substring(0, 4);
    periodo_teste.push({ mes, ano });
    if (parseInt(mes) < 12) {
      const mesIncrementado = parseInt(mes) + 1;
      competenciaInicio =
        ano + (mesIncrementado < 10 ? "0" + mesIncrementado : mesIncrementado);
    } else {
      const anoIncrementado = parseInt(ano) + 1;
      competenciaInicio = anoIncrementado + "01";
    }
  }
  return periodo_teste;
}
