import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";
import { MapLeaflet } from "../common/mapLeaflet/MapLeaflet";
import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";
import Select from "../common/form/select";
import { filtrarUcPorDataDesligamento } from "../utils/filtrarUcPorDataDesligamento";

import { setModoTela, initForm } from "./consumoIntegracaoActions";

class ConsumoIntegracaoForm extends Component {
    render() {
        let readOnly = this.props.excluir || this.props.visualizar ? "readOnly" : "";

        return (
            <>
                <ContentCard>
                    <Form event={this.props.handleSubmit}>
                        <Row>
                            <Grid cols="12 12 6 6">
                                <ContentCardBody>
                                    <Row>
                                        <Select
                                            cols="12 12 12 12"
                                            name="id_cliente"
                                            //se usuario implantacao estiver logado
                                            //filtra para poder incluir apenas itens na UC
                                            //que ele é o implantador
                                            options={
                                                this.props.usuarioLogado.usuario_implantacao
                                                    ? this.props.listaCliente.filter((cliente) => cliente.itens.find((uc) => uc.id_usuario_implantacao == this.props.usuarioLogado.id))
                                                    : this.props.listaCliente
                                            }
                                            label="Cliente"
                                            placeholder="Selecione o Cliente"
                                            value={this.props.formularioValues.id_cliente}
                                            onChange={(value) => {
                                                this.props.initForm({
                                                    ...this.props.formularioValues,
                                                    id_cliente: value,
                                                });
                                            }}
                                            readOnly={readOnly}
                                        />
                                    </Row>
                                    <Row>
                                        <Select
                                            cols="12 12 12 12"
                                            name="id_unidade_consumidora"
                                            //se usuario implantacao estiver logado
                                            //filtra para poder incluir apenas itens na UC
                                            //que ele é o implantador
                                            options={this.props.listaUnidadeConsumidora.filter((item) => {
                                                const implantacaoLogado = this.props.usuarioLogado.usuario_implantacao;
                                                const ucClienteSelecionado = item.id_cliente == this.props.formularioValues.id_cliente;
                                                if (implantacaoLogado) {
                                                    const usuarioImplantacaoVinculadoUc = item.id_usuario_implantacao == this.props.usuarioLogado.id;
                                                    return usuarioImplantacaoVinculadoUc && ucClienteSelecionado;
                                                }
                                                return ucClienteSelecionado;
                                            })}
                                            label="Unidade Consumidora"
                                            placeholder="Selecione a UC"
                                            value={this.props.formularioValues.id_unidade_consumidora}
                                            onChange={(value) => {
                                                this.props.initForm({
                                                    ...this.props.formularioValues,
                                                    id_unidade_consumidora: value,
                                                });
                                            }}
                                            readOnly={readOnly}
                                        />
                                    </Row>
                                    <Row>
                                        <Field
                                            name="id_consumo_integracao_tipo"
                                            cols="12 12 12 12"
                                            component={Select}
                                            options={this.props.listaConsumoIntegracaoTipos}
                                            label="Tipo"
                                            placeholder="Informe o tipo da integração"
                                            readOnly={readOnly}
                                        />
                                    </Row>
                                    <Row>
                                        <Field cols="12 12 12 12" name="usuario" component={LabelAndInput} label="Usuário Integração" placeholder="Informe o nome de usuário da integração" readOnly={readOnly} />
                                    </Row>
                                    <Row>
                                        <Field cols="12 12 12 12" name="senha" component={LabelAndInput} label="Senha Integração" placeholder="Informe a senha do usuário da integração" readOnly={readOnly} />
                                    </Row>
                                </ContentCardBody>
                            </Grid>
                            <Grid cols="12 12 6 6">
                                <div style={{ padding: "13px 5px 5px 0px" }}>
                                    <b>Coordenadas</b>
                                </div>
                                <div style={{ width: "98%", height: 450 }}>
                                    <MapLeaflet
                                        getCurrentPosition={!this.props.formularioValues.id}
                                        latitude={this.props.formularioValues.latitude}
                                        longitude={this.props.formularioValues.longitude}
                                        onChange={(coordenadas) => {
                                            this.props.initForm({
                                                ...this.props.formularioValues,
                                                latitude: String(coordenadas.latitude).substring(0, 15),
                                                longitude: String(coordenadas.longitude).substring(0, 15),
                                            });
                                        }}
                                    />
                                </div>
                            </Grid>
                        </Row>
                        <ContentCardFooter>
                            <Row alignCenter>
                                <Grid cols="6 6 4 3">
                                    {this.props.excluir ? (
                                        <Button text="Excluir" submit type={"danger"} icon={"fa fa-trash"} disabled={this.props.visualizar} />
                                    ) : (
                                        <Button text={"Salvar"} submit type={"success"} icon={"fa fa-check"} disabled={this.props.visualizar} />
                                    )}
                                </Grid>
                                <Grid cols="6 6 4 3">
                                    <Button text="Voltar" type={"warning"} icon={"fa fa-chevron-left"} event={() => this.props.setModoTela("lista")} />
                                </Grid>
                            </Row>
                        </ContentCardFooter>
                    </Form>
                </ContentCard>
            </>
        );
    }
}

ConsumoIntegracaoForm = reduxForm({
    form: "consumoIntegracaoForm",
    destroyOnUnmount: false,
})(ConsumoIntegracaoForm);
const mapStateToProps = (state) => ({
    sessao: state.auth.usuario,
    usuarioLogado: state.auth.usuarioLogado,
    formularioValues: getFormValues("consumoIntegracaoForm")(state),
    registro: state.consumoIntegracao.registro,
    filtro: state.consumoIntegracao.filtro,
    lista: state.consumoIntegracao.lista,
    listaConsumoIntegracaoTipos: state.consumoIntegracao.listaConsumoIntegracaoTipos,
    listaCliente: state.consumoIntegracao.listaCliente,
    listaUnidadeConsumidora: filtrarUcPorDataDesligamento(state.consumoIntegracao.listaUnidadeConsumidora),
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setModoTela,
            initForm,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(ConsumoIntegracaoForm);
