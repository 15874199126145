import React, { useState } from "react";

export default (props) => {
	const [hover, setHovered] = useState(false);

	return props.type ? (
		<button
			title={props.title}
			type={"button"}
			className={`btn btn-flat btn-${props.solid ? "" : "outline-"}${props.type}`}
			onClick={() => (props.disabled ? {} : props.event())}
			disabled={props.disabled}
			style={{
				...props.style,
				width: 40,
				height: 40,
				margin: 1,
				visibility: props.visible ? "visible" : "collapse",
			}}
		>
			{props.children || <i className={props.icon}></i>}
		</button>
	) : (
		<button
			title={props.title}
			type={"button"}
			className={`btn btn-flat btn-${props.solid ? "" : "outline-"}`}
			onClick={() => (props.disabled ? {} : props.event())}
			disabled={props.disabled}
			style={{
				...props.style,
				width: 40,
				height: 40,
				margin: 1,
				visibility: props.visible ? "visible" : "collapse",
				backgroundColor: hover && props.backgroundColorHover ? props.backgroundColorHover : props.style.backgroundColor,
				color: hover && props.colorHover ? props.colorHover : props.style.color,
				border: hover && props.borderHover ? props.borderHover : props.style.border,
			}}
			onMouseEnter={() => {
				setHovered(true);
			}}
			onMouseLeave={() => setHovered(false)}
		>
			{props.children || <i className={hover && props.iconHover ? props.iconHover : props.icon}></i>}
		</button>
	);
};

/*
primary
secondary
success
danger
warning
info
light
dark
link
*/
