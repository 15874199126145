import React from "react";
import Menu from "./menu";
import imagemLogo from "../../assets/images/logo_sem_fundo.png";
import imagemLogoNome from "../../assets/images/logo_nome_sem_fundo.png";
import imagemLogoEficience from "../../assets/images/by_eficience.png";

export default (props) => (
  <aside
    className="main-sidebar elevation-4 sidebar-light-indigo"
    style={{ backgroundColor: "#BA0000" }}
  >
    <a
      href="../../"
      id="logo-gradios-sidebar"
      className="brand-link navbar-light"
      style={{ backgroundColor: "#ffffff" }}
    >
      <img src={imagemLogo} alt="Gradios Logo" className="brand-image" />
      <span className="brand-text font-weight-light">
        <img
          src={imagemLogoNome}
          className="brand-image"
          style={{ marginLeft: 0 }}
        />
      </span>
    </a>
    <div className="sidebar">
      <Menu sessao={props.sessao} />
    </div>
    <div style={{ marginTop: -80, textAlign: "center" }}>
      <img
        src={imagemLogoEficience}
        alt="Gradios Logo"
        className="brand-image"
        style={{ width: "85%", maxWidth: 80 }}
      />
    </div>
  </aside>
);
