import axios from "axios";
import { showTabs, selectTab } from "../common/tab/tabActions";
import consts from "../consts";
import { setErroAPI, setSuccess } from "../common/toast/toast";
import { reset as resetForm, initialize } from "redux-form";

export function getLista() {
  return (dispatch, getState) => {
    axios
      .get(
        `${consts.API_URL}/notificacao?
			id_usuario=${getState().auth.usuario.id}`
      )
      .then((resp) => {
        dispatch({
          type: "NOTIFICACAO_LISTADO",
          payload: resp,
        });
      })
      .catch((ex) => {});
  };
}

export function setLida(registro) {
  return (dispatch, getState) => {
    axios
      .put(`${consts.API_URL}/notificacao`, {
        ...registro,
        lida: true,
        visto: true,
      })
      .then((resp) => {
        dispatch(getLista());
      })
      .catch((e) => {
        setErroAPI(e);
      });
  };
}

export function setVisto(registro) {
  return (dispatch, getState) => {
    axios
      .put(`${consts.API_URL}/notificacao`, {
        ...registro,
        lida: false,
        visto: true,
      })
      .then((resp) => {
        dispatch(getLista());
      })
      .catch((e) => {
        setErroAPI(e);
      });
  };
}
