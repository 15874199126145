import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";

import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import NewTr from "../common/table/newTr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import Button from "../common/button/button";
import Form from "./gestaoProcessoForm";
import Select from "../common/form/select";
import LabelAndInput from "../common/form/labelAndInput";
import LabelAndTextarea from "../common/form/labelAndTextarea";

import { setModoTela, initForm, salvar, excluir, setFiltro, getLista, getListaCliente, getListaUnidadeConsumidora, getListaConcessionaria, salvarAndamento, getListaGestaoProcessoTipo } from "./gestaoProcessoActions";
import { getListaUsuario } from "../usuario/usuarioActions";
import DateFormat from "../common/dateFormat/DateFormat";
import { adicionarReticencias } from "../utils/adicionarReticencias";
import { Loading } from "../common/loading/loading";
import { ModalForm } from "../common/dialog/modalForm";

class GestaoProcesso extends Component {
    state = { exibirModalAndamento: false, gestao_processo: null };

    componentWillMount() {
        this.props.getListaUsuario();
        this.props.getListaCliente();
        this.props.getListaUnidadeConsumidora();
        this.props.getListaConcessionaria();
        this.props.getListaGestaoProcessoTipo();
        this.props.getLista();
    }

    fecharModalAndamento(referencia) {
        referencia.setState({ exibirModalAndamento: false, gestao_processo: null });
    }

    render() {
        //Sem acesso
        if (!this.props.usuarioLogado || this.props.usuarioLogado.acesso_cliente) {
            return <div />;
        }

        return (
            <div>
                {this.props.aguardando ? <Loading /> : null}
                {this.state.exibirModalAndamento ? (
                    <ModalForm
                        titulo="Adicionar registro de andamento"
                        handleSubmit={(andamento) => {
                            andamento.gestao_processo = JSON.parse(JSON.stringify(this.state.gestao_processo));
                            this.props.salvarAndamento(andamento, () => this.fecharModalAndamento(this));
                        }}
                        handleClose={() => this.fecharModalAndamento(this)}
                    >
                        <LabelAndTextarea name="descricao" label="Descrição" cols="12 12 12 12" placeholder="Informe a descrição do andamento" />
                        <Select name="solucao_final" options={this.props.listaSolucao} label="Tipo de ação" cols="12 12 12 12" placeholder="Selecione o tipo de ação" isClearable={false} />
                    </ModalForm>
                ) : null}
                <Content>
                    {this.props.modoTela === "visualizacao" ? <Form visualizar onSubmit={() => {}} /> : null}
                    {this.props.modoTela === "cadastro" ? <Form onSubmit={this.props.salvar} /> : null}
                    {this.props.modoTela === "exclusao" ? <Form excluir onSubmit={this.props.excluir} /> : null}
                    {this.props.modoTela === "lista" ? this.lista() : null}
                </Content>
            </div>
        );
    }

    lista() {
        return (
            <ContentCard>
                <ContentCardHeader>
                    <Row>
                        <Grid cols="6 6 4 2">
                            <Button
                                text="Adicionar"
                                type={"success"}
                                icon={"fa fa-plus"}
                                disabled={this.props.usuarioLogado.acesso_cliente || !this.props.usuarioLogado.acesso_gestao_processo_adicionar}
                                event={() => {
                                    this.props.setModoTela("cadastro", {});
                                    this.props.initForm({});
                                }}
                            />
                        </Grid>
                    </Row>
                </ContentCardHeader>
                <ContentCardBody>
                    <Row>
                        <Select
                            options={this.props.listaCliente}
                            label="Cliente"
                            cols="12 12 6 4"
                            placeholder="Selecione o Cliente"
                            value={this.props.filtro.id_cliente}
                            onChange={(value) => {
                                this.props.setFiltro({
                                    ...this.props.filtro,
                                    id_cliente: value,
                                });
                                this.props.getLista();
                            }}
                        />

                        <Select
                            options={this.props.listaUnidadeConsumidora.filter((item) => (this.props.filtro.id_cliente ? item.id_cliente == this.props.filtro.id_cliente : true))}
                            label="Unidade Consumidora"
                            cols="12 12 6 4"
                            placeholder="Selecione a UC"
                            value={this.props.filtro.id_unidade_consumidora}
                            onChange={(value) => {
                                this.props.setFiltro({
                                    ...this.props.filtro,
                                    id_unidade_consumidora: value,
                                });
                                this.props.getLista();
                            }}
                        />
                        <Select
                            options={this.props.listaConcessionaria}
                            label="Concessionária"
                            cols="12 12 6 4"
                            placeholder="Selecione a Concessionária"
                            value={this.props.filtro.id_concessionaria}
                            onChange={(value) => {
                                this.props.setFiltro({
                                    ...this.props.filtro,
                                    id_concessionaria: value,
                                });
                                this.props.getLista();
                            }}
                        />
                        <Select
                            options={this.props.listaGestaoProcessoTipo}
                            label="Tipo"
                            cols="12 12 6 3"
                            placeholder="Selecione o tipo"
                            value={this.props.filtro.id_tipo}
                            onChange={(value) => {
                                this.props.setFiltro({
                                    ...this.props.filtro,
                                    id_tipo: value,
                                });
                                this.props.getLista();
                            }}
                        />
                        <LabelAndInput
                            label="Nº do Protocolo"
                            cols="12 12 6 3"
                            placeholder="Buscar pelo Nº do Protocolo"
                            value={this.props.filtro.numero_protocolo}
                            onChange={(e) => {
                                this.props.setFiltro({
                                    ...this.props.filtro,
                                    numero_protocolo: e.target.value,
                                });
                                this.props.getLista();
                            }}
                        />
                        <Select
                            options={this.props.listaUsuarioAdministradores}
                            label="Usuário Responsável"
                            cols="12 12 6 3"
                            placeholder="Selecione o Usuário Responsável"
                            value={this.props.filtro.id_usuario_responsavel}
                            onChange={(value) => {
                                this.props.setFiltro({
                                    ...this.props.filtro,
                                    id_usuario_responsavel: value,
                                });
                                this.props.getLista();
                            }}
                        />
                        <Select
                            options={this.props.listaStatus}
                            label="Status"
                            cols="12 12 6 3"
                            placeholder="Selecione o Status"
                            value={this.props.filtro.status}
                            onChange={(value) => {
                                this.props.setFiltro({
                                    ...this.props.filtro,
                                    status: value,
                                });
                                this.props.getLista();
                            }}
                        />
                    </Row>
                    <Table responsive>
                        <THead>
                            <NewTr backgroundColorTr={"#FFFFFF"} backgroundColorTd={"#F5F5F5"}>
                                <Th sticky>Contagem de Tempo</Th>
                                <Th sticky>Tipo</Th>
                                <Th sticky>Cliente</Th>
                                <Th sticky>UC</Th>
                                <Th sticky>Concessionária</Th>
                                <Th alignCenter sticky>
                                    Usuário de Criação
                                </Th>
                                <Th alignCenter sticky>
                                    Descrição
                                </Th>
                                <Th alignCenter sticky>
                                    Usuário Responsável
                                </Th>
                                <Th alignCenter sticky>
                                    Nº Protocolo
                                </Th>
                                <Th alignCenter sticky>
                                    Data de Início
                                </Th>
                                <Th alignCenter sticky>
                                    Prazo de Conclusão
                                </Th>
                                <Th sticky>Descrever Ação parcial ou Solução Final</Th>
                                <Th sticky>Descrição da Solução Parcial ou Final</Th>
                                <Th sticky>Usuário de Encerramento</Th>
                                <Th alignCenter sticky>
                                    Status
                                </Th>
                            </NewTr>
                        </THead>
                        <TBody>
                            {this.props.lista.map((item) => {
                                const dataAtual = new Date();
                                dataAtual.setHours(0, 0, 0, 0);
                                const foraDoPrazo = new Date(item.prazo_conclusao).getTime() < dataAtual.getTime() && !item.status;
                                const nomeUsuarioCriacaoFormatado = adicionarReticencias(item.nome_usuario_criacao);
                                const nomeUsuarioResponsavelFormatado = adicionarReticencias(item.nome_usuario_responsavel);
                                const nomeUsuarioEncerramentoFormatado = item.nome_usuario_alteracao && item.status && adicionarReticencias(item.nome_usuario_alteracao);
                                const datahoraConclusao = DateFormat.formatarDataHoraSqlParaTela(item.datahora_conclusao);
                                const usuarioHorarioConclusao = (
                                    <div>
                                        <p style={{ margin: 0 }}>{nomeUsuarioEncerramentoFormatado}</p>
                                        <p style={{ margin: 0 }}>{datahoraConclusao}</p>
                                    </div>
                                );
                                const numeroProtocoloFormatado = item.numero_protocolo != null && item.numero_protocolo;
                                const dataInicioFormatada = DateFormat.formatarDataSqlParaTela(item.data_inicio);
                                const prazoConclusaoFormatado = DateFormat.formatarDataSqlParaTela(item.prazo_conclusao);
                                const descricaoFormatada = adicionarReticencias(item.descricao, 70);
                                const ultimoRegistroAndamento = item.andamento.sort((a, b) => a.id - b.id)[item.andamento.length - 1];
                                const descricaoParcialFormatada = ultimoRegistroAndamento ? (
                                    <>
                                        <div>
                                            <b>Atualização: </b>
                                            {ultimoRegistroAndamento.nome_usuario_alteracao}
                                        </div>

                                        <div>{DateFormat.formatarDataHoraSqlParaTela(ultimoRegistroAndamento.datahora_alteracao)}</div>
                                        <div>{adicionarReticencias(ultimoRegistroAndamento.descricao, 70)}</div>
                                    </>
                                ) : (
                                    ""
                                );

                                const statusFormatado = item.status ? "CONCLUÍDO" : "EM ANDAMENTO";
                                const corStatusFormatado = statusFormatado == "CONCLUÍDO" ? "#29C175" : "#08A2FF";
                                const backgroundColorTr = item.status ? "#DDFFDD" : foraDoPrazo ? "#FBCACA" : "#FFFFFF";
                                const backgroundColorTd = item.status ? "#44FFAA" : foraDoPrazo ? "#FF6569" : "#F9F9F9";
                                const contagemTempo = (
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        {new Array(4).fill().map((el, i) => (
                                            <div
                                                style={{
                                                    height: 20,
                                                    width: 20,
                                                    borderRadius: "100%",
                                                    margin: 2,
                                                    backgroundColor:
                                                        item.estagio_andamento == 3 && i < 3
                                                            ? "#FFE95C"
                                                            : item.estagio_andamento == 4
                                                            ? "#FF1040"
                                                            : item.estagio_andamento >= 1 && item.estagio_andamento < 3 && i <= item.estagio_andamento - 1
                                                            ? "#4AE30E"
                                                            : "#DCDCDC",
                                                }}
                                            />
                                        ))}
                                    </div>
                                );

                                return (
                                    <NewTr
                                        key={item.id}
                                        backgroundColorTr={backgroundColorTr}
                                        backgroundColorTd={backgroundColorTd}
                                        botoes={[
                                            {
                                                type: "primary",
                                                icon: "fas fa-eye",
                                                disabled:
                                                    this.props.usuarioLogado.acesso_cliente ||
                                                    !(
                                                        this.props.usuarioLogado.acesso_gestao_processo_visualizar ||
                                                        this.props.usuarioLogado.acesso_gestao_processo_editar ||
                                                        this.props.usuarioLogado.acesso_gestao_processo_excluir
                                                    ),
                                                event: () => {
                                                    this.props.setModoTela("visualizacao", { ...item });
                                                    this.props.initForm({ ...item });
                                                },
                                            },
                                            {
                                                type: "warning",
                                                icon: "fas fa-pencil-alt",
                                                disabled: this.props.usuarioLogado.acesso_cliente || item.bloquearEdicaoUsuarioImplantacao || item.status || !this.props.usuarioLogado.acesso_gestao_processo_editar,
                                                event: () => {
                                                    this.props.setModoTela("cadastro", { ...item });
                                                    this.props.initForm({ ...item });
                                                },
                                            },
                                            {
                                                type: "danger",
                                                icon: "fas fa-trash-alt",
                                                disabled: this.props.usuarioLogado.acesso_cliente || item.bloquearEdicaoUsuarioImplantacao || item.status || !this.props.usuarioLogado.acesso_gestao_processo_excluir,
                                                event: () => {
                                                    this.props.setModoTela("exclusao", { ...item });
                                                    this.props.initForm({ ...item });
                                                },
                                            },
                                        ]}
                                    >
                                        <Td>{contagemTempo}</Td>
                                        <Td fontSize="0.75rem" maxWidth={200} paddingLeft={4}>
                                            {item.nome_tipo}
                                        </Td>
                                        <Td fontSize="0.75rem" maxWidth={200} paddingLeft={4}>
                                            {item.nome_cliente}
                                        </Td>
                                        <Td fontSize="0.75rem" maxWidth={230} paddingInline={4}>
                                            {item.nome_unidade_consumidora}
                                        </Td>
                                        <Td alignCenter fontSize="0.75rem">
                                            {item.nome_concessionaria}
                                        </Td>
                                        <Td alignCenter fontSize="0.75rem" title={item.nome_usuario_criacao}>
                                            {nomeUsuarioCriacaoFormatado}
                                        </Td>
                                        <Td fontSize="0.75rem" maxWidth={250} title={item.descricao} paddingLeft={4}>
                                            {descricaoFormatada}
                                        </Td>

                                        <Td alignCenter fontSize="0.75rem" title={item.nome_usuario_responsavel}>
                                            {nomeUsuarioResponsavelFormatado}
                                        </Td>
                                        <Td alignCenter fontSize="0.75rem">
                                            {numeroProtocoloFormatado}
                                        </Td>
                                        <Td alignCenter fontSize="0.75rem" maxWidth={200}>
                                            {dataInicioFormatada}
                                        </Td>
                                        <Td alignCenter fontSize="0.75rem" maxWidth={200}>
                                            {prazoConclusaoFormatado}
                                        </Td>
                                        <Td alignCenter fontSize="0.75rem">
                                            <Button
                                                text="Adicionar Descrição de andamento"
                                                solid={true}
                                                flex={true}
                                                hoverStyle={{ backgroundColor: "#11D996" }}
                                                style={{
                                                    backgroundColor: "#00BF7F",
                                                    border: "2px solid #FFFFFF",
                                                    borderRadius: "0.3rem",
                                                    padding: "5px 3px",
                                                }}
                                                type={"success"}
                                                disabled={this.props.usuarioLogado.acesso_cliente || !this.props.usuarioLogado.acesso_gestao_processo_andamento_adicionar || item.status}
                                                event={() => this.setState({ exibirModalAndamento: true, gestao_processo: JSON.parse(JSON.stringify(item)) })}
                                            />
                                        </Td>
                                        <Td fontSize="0.75rem" maxWidth={250} title={(ultimoRegistroAndamento || {}).descricao} paddingLeft={4}>
                                            {descricaoParcialFormatada}
                                        </Td>
                                        <Td backgroundColor={backgroundColorTd} alignCenter fontSize="0.75rem" title={item.nome_usuario_alteracao}>
                                            {usuarioHorarioConclusao}
                                        </Td>
                                        <Td backgroundColor={corStatusFormatado} fontSize="0.65rem" minWidth={82} alignCenter>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    color: "#FFFFFF",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {statusFormatado}
                                            </div>
                                        </Td>
                                    </NewTr>
                                );
                            })}
                        </TBody>
                    </Table>
                </ContentCardBody>
            </ContentCard>
        );
    }
}

function usuariosAdminAutorizadosVisualizar(lista) {
    if (!lista) {
        return [];
    }
    return lista.filter(
        (usuario) => !usuario.acesso_cliente && (usuario.acesso_gestao_processo_visualizar || usuario.acesso_gestao_processo_adicionar || usuario.acesso_gestao_processo_editar || usuario.acesso_gestao_processo_excluir)
    );
}

const mapStateToProps = (state) => ({
    sessao: state.auth.usuario,
    lista: state.gestaoProcesso.lista,
    filtro: state.gestaoProcesso.filtro,
    usuarioLogado: state.auth.usuarioLogado,
    modoTela: state.gestaoProcesso.modoTela,
    aguardando: state.gestaoProcesso.aguardando,
    listaStatus: state.gestaoProcesso.listaStatus,
    listaSolucao: state.gestaoProcesso.listaSolucao,
    listaCliente: state.gestaoProcesso.listaCliente,
    formularioValues: getFormValues("gestaoProcessoForm")(state),
    listaConcessionaria: state.gestaoProcesso.listaConcessionaria,
    listaGestaoProcessoTipo: state.gestaoProcesso.listaGestaoProcessoTipo,
    listaUnidadeConsumidora: state.gestaoProcesso.listaUnidadeConsumidora,
    listaUsuarioAdministradores: usuariosAdminAutorizadosVisualizar(state.usuario.listaUsuario),
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            salvar,
            excluir,
            getLista,
            initForm,
            setFiltro,
            setModoTela,
            getListaUsuario,
            getListaCliente,
            salvarAndamento,
            getListaConcessionaria,
            getListaGestaoProcessoTipo,
            getListaUnidadeConsumidora,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(GestaoProcesso);
