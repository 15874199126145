// Função para converter Base64 para um blob
export const base64ToBlob = (base64Data, contentType = "application/pdf") => {
	// Remova o prefixo "data:..." se houver
	const base64String = base64Data.split(",").pop();
	const byteCharacters = atob(base64String);
	const byteNumbers = new Array(byteCharacters.length);

	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}

	const byteArray = new Uint8Array(byteNumbers);
	return new Blob([byteArray], { type: contentType });
};
