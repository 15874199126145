import DateFormat from "../common/dateFormat/DateFormat";
const INITIAL_STATE = {
    registro: {},
    aguardando: false,
    modoTela: "lista",
    lista: [],
    listaCliente: [],
    listaUnidadeConsumidora: [],
    listaDemandaContratoTipo: [],
    listaDemandaContagem: [],
    dataInicialGrafico: DateFormat.getDataAtual().replace(/\//g, "-"),
    dataFinalGrafico: DateFormat.getDataAtual().replace(/\//g, "-"),
    listaGraficoDia: [],
    filtro: {
        id_cliente: null,
        unidades_consumidoras: [],
        id_tipo_contrato_demanda: 1,
        intervalo_tempo: 15,
    },
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "MONITORAMENTO_CONSUMO_MODO_TELA":
            return {
                ...state,
                modoTela: action.payload.modo,
                registro: action.payload.registro,
            };

        case "MONITORAMENTO_CONSUMO_SET_AGUARDANDO":
            return { ...state, aguardando: action.payload };

        case "MONITORAMENTO_CONSUMO_SET_DATA_INICIAL_GRAFICO":
            return { ...state, dataInicialGrafico: action.payload };

        case "MONITORAMENTO_CONSUMO_SET_DATA_FINAL_GRAFICO":
            return { ...state, dataFinalGrafico: action.payload };

        case "MONITORAMENTO_CONSUMO_FILTRO":
            return { ...state, filtro: action.payload };

        case "MONITORAMENTO_CONSUMO_CLIENTE_SELECT_LISTADO":
            return {
                ...state,
                listaCliente: action.payload.data,
            };

        case "MONITORAMENTO_CONSUMO_UNIDADE_CONSUMIDORA_SELECT_LISTADO":
            return {
                ...state,
                listaUnidadeConsumidora: action.payload.data,
            };

        case "MONITORAMENTO_CONSUMO_DEMANDA_CONTAGEM_LISTADO":
            return {
                ...state,
                listaDemandaContagem: action.payload.data,
            };

        case "MONITORAMENTO_CONSUMO_CONTRATO_DEMANDA_TIPO_SELECT_LISTADO":
            return {
                ...state,
                listaDemandaContratoTipo: action.payload.data,
            };

            case "MONITORAMENTO_CONSUMO_LISTA_GRAFICO_TODAS_INTEGRACOES_AGRUPADAS_POR_HORA":
                return {
                    ...state,
                    listaGraficoTodasIntegracoesAgrupadasPorHora:action.payload.data,
                };

            case "MONITORAMENTO_CONSUMO_LISTA_GRAFICO_DIA":
                return {
                    ...state,
                    listaGraficoDia:inserirLeiturasZeradasGraficos({
                        dataInicialGrafico: state.dataInicialGrafico,
                        dataFinalGrafico: state.dataFinalGrafico,
                        leituras: action.payload.data || [],
                        listaNomesAtributos: ["p","q"],
                        minutosIntervalo: 15,
                    }),
                };
    
        
        default:
            return state;
    }
};

function gerarLeituraZerada({listaNomesAtributos,data_hora}){
    const leitura = {data_hora};

    for(const nomeAtributo of listaNomesAtributos){
        leitura[nomeAtributo] = '0'
    }
    return leitura;
}

function inserirLeiturasZeradasGraficos({ dataInicialGrafico,dataFinalGrafico, leituras, listaNomesAtributos, minutosIntervalo }) {
    const tempoMs = 1000 * 60 * minutosIntervalo
    const horarioInicial = new Date(dataInicialGrafico+" 00:00:00")
    const horarioFinal = new Date(dataFinalGrafico+" 23:59:59")


    //Se a lista vier vazia cria uma nova lista com leituras fake de 15 em 15 min e potencia_instantanea zerada
    if (!leituras.length) {
        const primeiraLeitura = gerarLeituraZerada({
            listaNomesAtributos,
            data_hora: horarioInicial.toISOString(),
        });
        const listaTodosVazios = [primeiraLeitura];

        //enquanto estiver maior que inicial e menor q final vai incluindo
        while ((new Date(listaTodosVazios[listaTodosVazios.length - 1].data_hora).getTime() + tempoMs) < horarioFinal.getTime()) {
            const msNovaData = new Date(listaTodosVazios[listaTodosVazios.length - 1].data_hora).getTime() + tempoMs;
            const dataNovaLeitura = new Date(msNovaData);

            const novaLeitura = gerarLeituraZerada({
                listaNomesAtributos,
                data_hora: dataNovaLeitura.toISOString(),
            });
            
            listaTodosVazios.push(novaLeitura);
        }
        return listaTodosVazios;
    } else {
        //se a lista não vier vazia, adiciona leituras zeradas (fake) antes e depois da lista
        const listaComVazios = [...leituras];

        //enquanto for maior que 00:00h e menor q o primeira leitura válida adiciona leituras zeradas (fake)
        while (new Date(listaComVazios[0].data_hora).getTime() - tempoMs > horarioInicial.getTime()) {
            const novaLeitura = gerarLeituraZerada({
                listaNomesAtributos,
                data_hora: new Date(new Date(listaComVazios[0].data_hora).getTime() - tempoMs).toISOString(),
            });
            listaComVazios.unshift(novaLeitura);
        }
        //enquanto for maior que a última leitura válida da lista e menor q 23:59h adiciona leituras zeradas (fake)
        while ((new Date(listaComVazios[listaComVazios.length - 1].data_hora).getTime() + tempoMs) < horarioFinal.getTime()) {
            const novaLeitura =gerarLeituraZerada({
                listaNomesAtributos,
                data_hora: new Date(new Date(listaComVazios[listaComVazios.length - 1].data_hora).getTime() + tempoMs).toISOString(),
            });
            
            listaComVazios.push(novaLeitura);
        }
        return listaComVazios;
    }

}