import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";
import ContentCard from "../../common/template/contentCard";
import ContentCardBody from "../../common/template/contentCardBody";
import ContentCardFooter from "../../common/template/contentCardFooter";
import Row from "../../common/layout/row";
import Grid from "../../common/layout/grid";
import Button from "../../common/button/button";
import Form from "../../common/form/form";
// import LabelAndInputNumber from "../../common/form/labelAndInputNumber";
import Select from "../../common/form/select";
import FileChooser from "../../common/form/fileChooser/fileChooser";
import { setModoTela, initFormFaturaAdicional } from "./faturaItemActions";

class FaturaAdicionalForm extends Component {
    render() {
        const produto = this.props.listaProduto.find((item) => item.id == this.props.formularioValues.id_produto);
        const readOnly = this.props.excluir || this.props.visualizar ? "readOnly" : "";

        console.log({ lista: this.props.listaProduto, produto, selecionado: this.props.listaProduto.filter((item) => item.id == this.props.formularioValues.id_produto) });
        return (
            <>
                <ContentCard>
                    <Form event={this.props.handleSubmit}>
                        <ContentCardBody>
                            <Grid cols="12 12 6 6" style={{ paddingLeft: 0, paddingRight: 6 }}>
                                <FileChooser
                                    readOnly={readOnly}
                                    nome={this.props.formularioValues.nomePdf}
                                    base64={this.props.formularioValues.pdfBase64}
                                    label={produto.valor}
                                    onFileSelect={(base64ComNomeJSON) => this.props.initFormFaturaAdicional({ ...this.props.formularioValues, pdfBase64: base64ComNomeJSON.base64, nomePdf: base64ComNomeJSON.nome, valor_total:base64ComNomeJSON.base64?this.props.formularioValues.valor_total:0 })}
                                />
                            </Grid>
                            <Row>
                                <Field
                                    name="id_produto"
                                    component={Select}
                                    options={this.props.listaProduto.filter((item) => item.id == this.props.formularioValues.id_produto)}
                                    isClearable={false}
                                    isSearchable={false}
                                    readOnly={readOnly}
                                    label="Produto"
                                    cols="12 12 6 6"
                                    placeholder="Selecione o produto"
                                />
                            </Row>
                            {/* <Row>
                                <Field name="valor_total" component={LabelAndInputNumber} label="Valor" placeholder="Informe o valor" cols="12 12 6 6" casas={2} readOnly={readOnly} />
                            </Row> */}
                        </ContentCardBody>
                        <ContentCardFooter>
                            <Row alignCenter>
                                <Grid cols="6 6 4 3">{this.props.excluir ? <Button text="Excluir" submit type={"danger"} /> : <Button text={"Salvar"} submit type={"success"} disabled={this.props.visualizar} />}</Grid>
                                <Grid cols="6 6 4 3">
                                    <Button text="Voltar" type={"warning"} event={() => this.props.setModoTela("lista")} />
                                </Grid>
                            </Row>
                        </ContentCardFooter>
                    </Form>
                </ContentCard>
            </>
        );
    }
}

FaturaAdicionalForm = reduxForm({
    form: "faturaAdicionalForm",
    destroyOnUnmount: false,
})(FaturaAdicionalForm);
const mapStateToProps = (state) => ({
    sessao: state.auth.usuario,
    usuarioLogado: state.auth.usuarioLogado,
    formularioValues: getFormValues("faturaAdicionalForm")(state),
    registro: state.faturaItem.registro,
    listaProduto: state.faturaItem.listaProduto,
    aguardando: state.fatura.aguardando,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setModoTela,
            initFormFaturaAdicional,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(FaturaAdicionalForm);
