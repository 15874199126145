import axios from "axios";
import consts from "../consts";
import { reset as resetForm, initialize } from "redux-form";
import { setErroAPI, setError, setSuccess } from "../common/toast/toast";
import confirmDialog from "../common/dialog/confirmDialog";
import { adicionarBloqueioUsuarioImplantacao } from "../utils/adicionarBloqueioUsuarioImplantacao";
import { getUcValidarUsuarioImplantacao } from "../cliente/clienteActions";
import sleep from "../utils/sleep";

export function setModoTela(modo, registro = {}) {
	return {
		type: "TRIBUTACAO_MODO_TELA",
		payload: {
			modo: modo,
			registro: registro,
		},
	};
}

export function setAguardando(aguardando) {
	return {
		type: "TRIBUTACAO_SET_AGUARDANDO",
		payload: aguardando,
	};
}

export function initForm(registro = {}) {
	return [initialize("tributacaoForm", registro)];
}

export function setFiltro(filtro) {
	return {
		type: "TRIBUTACAO_FILTRO",
		payload: filtro,
	};
}

export function salvar(registro) {
	return async (dispatch, getState) => {
		try {
			const aliquotaIcms = registro.aliquota_icms ? String(registro.aliquota_icms).replace(",", ".") : null;
			if (!registro.id) {
				const idUsuarioInclusao = getState().auth.usuarioLogado.id;
				dispatch(setAguardando(true));
				await axios.post(`${consts.API_URL}/tributacao`, { ...registro, aliquota_icms: aliquotaIcms, id_usuario_inclusao: idUsuarioInclusao });
			} else {
				if (
					window.confirm(
						"Alterar a UF, Atividade ou a Alíquota da tributação causará alteração na UF, na Atividade e na Alíquota de todas UCs vinculadas a esta tributação, deseja realmente alterar?"
					)
				) {
					dispatch(setAguardando(true));
					const idUsuarioAlteracao = getState().auth.usuarioLogado.id;
					await axios.put(`${consts.API_URL}/tributacao`, { ...registro, aliquota_icms: aliquotaIcms, id_usuario_alteracao: idUsuarioAlteracao });
				} else {
					return {};
				}
			}
			setSuccess("Operação Realizada com sucesso.");
			dispatch(getLista());
			dispatch(setModoTela("lista"));
		} catch (erro) {
			setErroAPI(erro);
			dispatch(
				initForm({
					...registro,
				})
			);
		} finally {
			dispatch(setAguardando(false));
		}
	};
}

export function excluir(registro) {
	return async (dispatch, getState) => {
		try {
			if (window.confirm("Deseja realmente excluir?")) {
				dispatch(setAguardando(true));
				const idUsuarioAlteracao = getState().auth.usuarioLogado.id;
				await axios.delete(`${consts.API_URL}/tributacao?id=${registro.id}&id_usuario_alteracao=${idUsuarioAlteracao}`);
			}
			setSuccess("Operação Realizada com sucesso.");
			dispatch(getLista());
			dispatch(setModoTela("lista"));
		} catch (erro) {
			setErroAPI(erro);
		} finally {
			dispatch(setAguardando(false));
		}
	};
}

export function getLista() {
	return async (dispatch, getState) => {
		try {
			dispatch(setAguardando(true));
			const idEstado = getState().tributacao.filtro.id_estado || null;
			const idAtividade = getState().tributacao.filtro.id_atividade || null;

			const resp = await axios.get(
				`${consts.API_URL}/tributacao?
				id_estado=${idEstado}
				&id_atividade=${idAtividade}`
			);

			dispatch({
				type: "TRIBUTACAO_LISTADO",
				payload: resp,
			});
		} catch (erro) {
			setErroAPI(erro);
		} finally {
			dispatch(setAguardando(false));
		}
	};
}

export function getlistaEstado() {
	return async (dispatch, getState) => {
		try {
			dispatch(setAguardando(true));
			const resp = await axios.get(`${consts.API_URL}/estado/listarSelect`);
			dispatch({
				type: "TRIBUTACAO_UF_SELECT_LISTADO",
				payload: resp,
			});
		} catch (erro) {
			setErroAPI(erro);
		} finally {
			dispatch(setAguardando(false));
		}
	};
}

export function getListaAtividade() {
	return async (dispatch, getState) => {
		try {
			dispatch(setAguardando(true));
			const resp = await axios.get(`${consts.API_URL}/atividade/listarSelect`);
			dispatch({
				type: "TRIBUTACAO_ATIVIDADE_SELECT_LISTADO",
				payload: resp,
			});
		} catch (erro) {
			setErroAPI(erro);
		} finally {
			dispatch(setAguardando(false));
		}
	};
}
