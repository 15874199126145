import React, { useState } from "react";

export default ({ lista }) => {
    const [linhaHover, setLinhaHover] = useState(null);
    return (
        <table
            style={{
                fontSize: "0.7rem",
                lineHeight: 0,
                width: "fit-content",
                textWrap: "nowrap",
                height: 100,
            }}
        >
            {lista.map((item, i) => {
                return (
                    <tr
                        key={i}
                        onMouseEnter={() => setLinhaHover(i)}
                        onMouseLeave={() => setLinhaHover(null)}
                        style={{ color: linhaHover == i ? "#FFFFFF" : "", backgroundColor: linhaHover == i ? "#0000006e" : "" }}
                    >
                        <td style={{ border: "1px solid #222222", padding: "0px 2px", height: 100 / lista.length,  whiteSpace:'nowrap' }}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    height: "100%",
                                }}
                            >
                                {item.cabecalho}
                            </div>
                        </td>

                        {item.valores ? (
                            item.valores.map((valor) => (
                                <td
                                    style={{
                                        border: "1px solid #222",
                                        width: 75,
                                        maxWidth: 75,
                                        padding: "0px 2px",
                                        height: 100 / lista.length,
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "100%",
                                            width: "100%",
                                            minWidth: 65,
                                        }}
                                    >
                                        {valor}
                                    </div>
                                </td>
                            ))
                        ) : (
                            <td
                                style={{
                                    border: "1px solid #222",
                                    width: 75,
                                    maxWidth: 75,
                                    padding: "0px 2px",
                                    height: 100 / lista.length,
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "100%",
                                        width: "100%",
                                        minWidth: 65,
                                    }}
                                >
                                    {item.valor}
                                </div>
                            </td>
                        )}
                    </tr>
                );
            })}
        </table>
    );
};
