import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";
import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";
import LabelAndInputNumber from "../common/form/labelAndInputNumber";
import Select from "../common/form/select";
import { filtrarUcPorDataDesligamento } from "../utils/filtrarUcPorDataDesligamento";

import { setModoTela, initForm } from "./empenhoActions";

class EmpenhoForm extends Component {
	render() {
		const anoAtual = new Date().getFullYear();
		const listaAnos = [
			{ id: anoAtual, valor: anoAtual },
			{ id: anoAtual + 1, valor: anoAtual + 1 },
			{ id: anoAtual + 2, valor: anoAtual + 2 },
			{ id: anoAtual + 3, valor: anoAtual + 3 },
			{ id: anoAtual + 4, valor: anoAtual + 4 },
		];

		let readOnly = this.props.excluir || this.props.visualizar ? "readOnly" : "";

		return (
			<>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<ContentCardBody>
							<Row>
								<Select
									cols="12 12 6 6"
									name="id_cliente"
									options={this.props.listaCliente}
									label="Cliente"
									placeholder="Selecione o Cliente"
									value={this.props.formularioValues.id_cliente}
									onChange={(value) => {
										this.props.initForm({
											...this.props.formularioValues,
											id_cliente: value,
										});
									}}
									readOnly={readOnly}
								/>
							</Row>

							<Row>
								<Field
									cols="12 12 6 6"
									name="numero"
									component={LabelAndInput}
									label="Nº do Empenho"
									placeholder="Informe o número do empenho"
									readOnly={readOnly}
								/>
							</Row>
							<Row>
								<Select
									cols="12 12 6 6"
									name="ano_referencia"
									// VERIFICAR QUAIS ANOS PODEM SER
									options={listaAnos}
									label="Ano de referência do Empenho"
									placeholder="Selecione o ano de referência do empenho"
									value={this.props.formularioValues.ano_referencia}
									onChange={(value) => {
										this.props.initForm({
											...this.props.formularioValues,
											ano_referencia: value,
										});
									}}
									readOnly={readOnly}
								/>
							</Row>
							<Row>
								<Field
									name="valor_previsto"
									component={LabelAndInputNumber}
									label="Valor previsto para o Empenho"
									placeholder="Informe o valor previsto para o empenho"
									cols="12 12 6 6"
									casas={2}
									readOnly={readOnly}
								/>
							</Row>
							<Row>
								<Select
									options={this.props.listaUnidadeConsumidora.filter((uc) => uc.id_cliente == this.props.formularioValues.id_cliente)}
									label="Unidades que pertencem a este Empenho"
									cols="12 12 12 12"
									readOnly={readOnly || !this.props.formularioValues.id_cliente}
									placeholder="Selecione as UCs"
									isMulti={true}
									value={this.props.formularioValues.unidades_consumidoras}
									onChange={(ucsSelecionadas) => {
										this.props.initForm({
											...this.props.formularioValues,
											unidades_consumidoras: [...ucsSelecionadas],
										});
									}}
								/>
							</Row>
						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols="6 6 4 3">
									{this.props.excluir ? (
										<Button text="Excluir" submit type={"danger"} />
									) : (
										<Button text={"Salvar"} submit type={"success"} disabled={this.props.visualizar} />
									)}
								</Grid>
								<Grid cols="6 6 4 3">
									<Button text="Voltar" type={"warning"} event={() => this.props.setModoTela("lista")} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</Form>
				</ContentCard>
			</>
		);
	}
}

EmpenhoForm = reduxForm({
	form: "empenhoForm",
	destroyOnUnmount: false,
})(EmpenhoForm);
const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues("empenhoForm")(state),
	registro: state.empenho.registro,
	filtro: state.empenho.filtro,
	lista: state.empenho.lista,
	listaCliente: state.empenho.listaCliente,
	listaUnidadeConsumidora: filtrarUcPorDataDesligamento(state.empenho.listaUnidadeConsumidora),
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setModoTela,
			initForm,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(EmpenhoForm);
