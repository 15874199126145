import React, { useState } from "react";

export default (props) => {
	const [menuOpen, setMenuOpen] = useState(false);
	return (
		<li className={`nav-item has-treeview ${menuOpen ? "menu-open" : ""}`} title={props.title} onClick={() => setMenuOpen(!menuOpen)}>
			<a href={props.path} className="nav-link" style={{ color: "#FFFFFF" }}>
				<i className={`nav-icon ${props.icon}`}></i> <p>{props.label}</p>
			</a>
			<ul className="nav nav-treeview">{props.children}</ul>
		</li>
	);
};
