import React from "react";

export default (props) => (
	<div
		className={`${props.responsive ? "table-responsive" : ""}`}
		style={{
			display: "flex",
			overflowX: "auto",
			maxHeight: props.maxHeight || "75vh",
			height: props.height,
			minWidth: props.minWidth,
			overflowY: "scroll",
		}}
	>
		<table id={props.id} className="table table-hover">
			{props.children}
		</table>
	</div>
);
