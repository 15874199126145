import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, getFormValues } from "redux-form";

import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";
import LabelAndInputNumber from "../common/form/labelAndInputNumber";
import LabelAndInputMask from "../common/form/labelAndInputMask";
import LabelAndInputDateTime from "../common/form/labelAndInputDateTime";
import Select from "../common/form/select";
import SelectAlteracao from "../common/form/selectAlteracao";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import ButtonTable from "../common/table/buttonTable";
import FormatUtils from "../common/formatUtils/FormatUtils";
import DateFormat from "../common/dateFormat/DateFormat";
import fileAdd from "../assets/images/icon_add.png";
import { setError } from "../common/toast/toast";
import { filtrarUcPorDataDesligamento } from "../utils/filtrarUcPorDataDesligamento";
import { setModoTela, initForm, setFiltro, getLista } from "./redeActions";

class RedeForm extends Component {
	state = {};

	componentWillMount() {
		this.props.setFiltro({
			...this.props.filtro,
			id_cliente: null,
			id_unidade_consumidora: null,
		});
		this.props.getLista();
	}

	componentDidMount() {
		const fileSelectorArquivo1 = document.createElement("input");
		fileSelectorArquivo1.setAttribute("type", "file");
		fileSelectorArquivo1.addEventListener("change", (data) => {
			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {
				if (
					!file.type.includes("application/pdf") &&
					!file.type.includes("image/png") &&
					!file.type.includes("image/jpg") &&
					!file.type.includes("image/jpeg")
				) {
					setError("Selecione apenas (.PDF, .PNG, .JPG e .JPEG)");
					return;
				}

				reader.onloadend = () => {
					this.props.initForm({
						...this.props.formularioValues,
						arquivo1: reader.result,
					});
				};

				reader.readAsDataURL(file);
			}
		});

		this.fileSelectorArquivo1 = fileSelectorArquivo1;

		const fileSelectorArquivo2 = document.createElement("input");
		fileSelectorArquivo2.setAttribute("type", "file");
		fileSelectorArquivo2.addEventListener("change", (data) => {
			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {
				if (
					!file.type.includes("application/pdf") &&
					!file.type.includes("image/png") &&
					!file.type.includes("image/jpg") &&
					!file.type.includes("image/jpeg") &&
					!file.type.includes("application/vnd.google-earth.kml+xml") &&
					!file.type.includes("application/vnd.google-earth.kmz")
				) {
					setError("Selecione apenas (.KML, .KMZ, .PDF, .PNG, .JPG e .JPEG)");
					return;
				}

				reader.onloadend = () => {
					this.props.initForm({
						...this.props.formularioValues,
						arquivo2: reader.result,
					});
				};

				reader.readAsDataURL(file);
			}
		});

		this.fileSelectorArquivo2 = fileSelectorArquivo2;

		const fileSelectorArquivo3 = document.createElement("input");
		fileSelectorArquivo3.setAttribute("type", "file");
		fileSelectorArquivo3.addEventListener("change", (data) => {
			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {
				if (
					!file.type.includes("application/pdf") &&
					!file.type.includes("image/png") &&
					!file.type.includes("image/jpg") &&
					!file.type.includes("image/jpeg")
				) {
					setError("Selecione apenas (.PDF, .PNG, .JPG e .JPEG)");
					return;
				}

				reader.onloadend = () => {
					this.props.initForm({
						...this.props.formularioValues,
						arquivo3: reader.result,
					});
				};

				reader.readAsDataURL(file);
			}
		});

		this.fileSelectorArquivo3 = fileSelectorArquivo3;
	}

	render() {
		let readOnly = this.props.excluir || this.props.visualizacao ? "readOnly" : "";

		let listaUnidadeConsumidora = this.props.listaUnidadeConsumidora;
		if (!this.props.formularioValues.id) {
			listaUnidadeConsumidora = listaUnidadeConsumidora.filter(
				(uc) => this.props.lista.filter((item) => item.id_unidade_consumidora == uc.id).length == 0
			);
		}

		return (
			<>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<ContentCardBody>
							<Row>
								<Field
									name="id_cliente"
									component={Select}
									//se usuario implantacao estiver logado
									//filtra para poder incluir apenas itens na UC
									//que ele é o implantador
									options={
										this.props.usuarioLogado.usuario_implantacao
											? this.props.listaCliente.filter((cliente) =>
													cliente.itens.find((uc) => uc.id_usuario_implantacao == this.props.usuarioLogado.id)
											  )
											: this.props.listaCliente
									}
									label="Cliente"
									cols="12 12 12 12"
									placeholder="Selecione o cliente"
									readOnly={this.props.formularioValues.id ? "readOnly" : ""}
								/>
							</Row>

							<Row>
								<Field
									name="id_unidade_consumidora"
									component={Select}
									//se usuario implantacao estiver logado
									//filtra para poder incluir apenas itens na UC
									//que ele é o implantador
									options={this.props.listaUnidadeConsumidora.filter((item) => {
										const implantacaoLogado = this.props.usuarioLogado.usuario_implantacao;
										const ucClienteSelecionado = item.id_cliente == this.props.formularioValues.id_cliente;
										if (implantacaoLogado) {
											const usuarioImplantacaoVinculadoUc = item.id_usuario_implantacao == this.props.usuarioLogado.id;
											return usuarioImplantacaoVinculadoUc && ucClienteSelecionado;
										}
										return ucClienteSelecionado;
									})}
									label="Unidade Consumidora"
									cols="12 12 12 12"
									placeholder="Selecione a unidade consumidora"
									readOnly={this.props.formularioValues.id ? "readOnly" : ""}
								/>
							</Row>
							<Row>
								<Grid cols="12 6 4 3">
									<label>Unifilar de Rede Interna PDF</label>
									<br />
									{!this.props.formularioValues.arquivo3 ? (
										<span className="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorArquivo3.click()}>
											<img src={fileAdd} style={{ height: 30 }} />
										</span>
									) : (
										<div style={{ display: "flex", flexDirection: "column" }}>
											<span className="btn btn-outline-primary fileinput-button dz-clickable">
												<img src={fileAdd} style={{ height: 100 }} />
											</span>
											<div
												style={{
													display: "flex",
													flexDirection: "row",
													justifyContent: "space-between",
													paddingRight: 2,
													marginTop: 4,
												}}
											>
												<button
													type="button"
													className="btn btn-flat btn-outline-primary"
													style={{ width: "48%" }}
													onClick={() => {
														const clearURL = this.props.formularioValues.arquivo3.split(",")[1];
														const linkSource = "data:application/octet-stream;base64," + encodeURI(clearURL);
														const downloadLink = document.createElement("a");
														const nomeClienteUC = `${this.props.formularioValues.nome_cliente}_${this.props.formularioValues.nome_unidade_consumidora}`;
														const fileName = `Unifilar_Rede_Interna_${nomeClienteUC}.${
															this.props.formularioValues.arquivo3.split("/")[1].split(";")[0]
														}`;
														downloadLink.href = linkSource;
														downloadLink.download = fileName;
														downloadLink.click();
													}}
												>
													<i className="fas fa-download"></i>
												</button>

												<button
													type="button"
													className="btn btn-flat btn-outline-danger"
													style={{ width: "48%" }}
													onClick={() => {
														this.props.initForm({
															...this.props.formularioValues,
															arquivo3: null,
														});
													}}
												>
													<i className="fas fa-trash-alt"></i>
												</button>
											</div>
										</div>
									)}
								</Grid>
							</Row>
							<Row>
								<Grid cols="12 6 4 3">
									<label style={{ marginTop: 18 }}>Projeto Rede Interna PDF</label>
									<br />
									{!this.props.formularioValues.arquivo1 ? (
										<span className="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorArquivo1.click()}>
											<img src={fileAdd} style={{ height: 30 }} />
										</span>
									) : (
										<div style={{ display: "flex", flexDirection: "column" }}>
											<span className="btn btn-outline-primary fileinput-button dz-clickable">
												<img src={fileAdd} style={{ height: 100 }} />
											</span>
											<div
												style={{
													display: "flex",
													flexDirection: "row",
													justifyContent: "space-between",
													paddingRight: 2,
													marginTop: 4,
												}}
											>
												<button
													type="button"
													className="btn btn-flat btn-outline-primary"
													style={{ width: "48%" }}
													onClick={() => {
														const clearURL = this.props.formularioValues.arquivo1.split(",")[1];
														const linkSource = "data:application/octet-stream;base64," + encodeURI(clearURL);
														const downloadLink = document.createElement("a");
														const nomeClienteUC = `${this.props.formularioValues.nome_cliente}_${this.props.formularioValues.nome_unidade_consumidora}`;
														const fileName = `Projeto_Rede_Interna_${nomeClienteUC}.${
															this.props.formularioValues.arquivo1.split("/")[1].split(";")[0]
														}`;
														downloadLink.href = linkSource;
														downloadLink.download = fileName;
														downloadLink.click();
													}}
												>
													<i className="fas fa-download"></i>
												</button>

												<button
													type="button"
													className="btn btn-flat btn-outline-danger"
													style={{ width: "48%" }}
													onClick={() => {
														this.props.initForm({
															...this.props.formularioValues,
															arquivo1: null,
														});
													}}
												>
													<i className="fas fa-trash-alt"></i>
												</button>
											</div>
										</div>
									)}
								</Grid>
							</Row>
							<Row>
								<Grid cols="12 6 4 3">
									<label style={{ marginTop: 18 }}>Projeto Rede Interna Google Earth</label>
									<br />
									{!this.props.formularioValues.arquivo2 ? (
										<span className="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorArquivo2.click()}>
											<img src={fileAdd} style={{ height: 30 }} />
										</span>
									) : (
										<div style={{ display: "flex", flexDirection: "column" }}>
											<span className="btn btn-outline-primary fileinput-button dz-clickable">
												<img src={fileAdd} style={{ height: 100 }} />
											</span>
											<div
												style={{
													display: "flex",
													flexDirection: "row",
													justifyContent: "space-between",
													paddingRight: 2,
													marginTop: 4,
												}}
											>
												<button
													type="button"
													className="btn btn-flat btn-outline-primary"
													style={{ width: "48%" }}
													onClick={() => {
														const clearURL = this.props.formularioValues.arquivo2.split(",")[1];
														const linkSource = "data:application/octet-stream;base64," + encodeURI(clearURL);
														const downloadLink = document.createElement("a");
														const nomeClienteUC = `${this.props.formularioValues.nome_cliente}_${this.props.formularioValues.nome_unidade_consumidora}`;
														const fileName = `Google_Earth_Projeto_Rede_Interna_${nomeClienteUC}.${
															this.props.formularioValues.arquivo2.split("/")[1].split(";")[0]
														}`;
														downloadLink.href = linkSource;
														downloadLink.download = fileName;
														downloadLink.click();
													}}
												>
													<i className="fas fa-download"></i>
												</button>

												<button
													type="button"
													className="btn btn-flat btn-outline-danger"
													style={{ width: "48%" }}
													onClick={() => {
														this.props.initForm({
															...this.props.formularioValues,
															arquivo2: null,
														});
													}}
												>
													<i className="fas fa-trash-alt"></i>
												</button>
											</div>
										</div>
									)}
								</Grid>
							</Row>

							<Row style={{ marginTop: 18 }}>
								<Field
									name="observacao"
									component={LabelAndInput}
									label="Observação"
									placeholder="Informe a observação"
									cols="12 12 12 12"
									readOnly={readOnly}
								/>
							</Row>
						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols="6 6 4 3">
									{this.props.excluir ? (
										<Button text="Excluir" submit type={"danger"} icon={"fa fa-trash"} disabled={this.props.visualizacao} />
									) : (
										<Button text={"Salvar"} submit type={"success"} icon={"fa fa-check"} disabled={this.props.visualizacao} />
									)}
								</Grid>
								<Grid cols="6 6 4 3">
									<Button text="Voltar" type={"warning"} icon={"fa fa-chevron-left"} event={() => this.props.setModoTela("lista")} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</Form>
				</ContentCard>
			</>
		);
	}
}

RedeForm = reduxForm({ form: "redeForm", destroyOnUnmount: false })(RedeForm);
const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues("redeForm")(state),
	registro: state.rede.registro,
	lista: state.rede.lista,
	listaCliente: state.rede.listaCliente,
	listaUnidadeConsumidora: filtrarUcPorDataDesligamento(state.rede.listaUnidadeConsumidora),
	listaMeses: state.rede.listaMeses,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setModoTela,
			initForm,
			setFiltro,
			getLista,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(RedeForm);
