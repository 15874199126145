import React from "react";

export default (props) => (
  <section
    className="content"
    style={{ padding: 8, backgroundColor: "#f0f1f5" }}
  >
    {props.children}
  </section>
);
