import React from "react";

export default (props) => (
  <div
    className="card-body"
    style={{ ...props.style, padding: props.padding || 8 }}
  >
    {props.children}
  </div>
);
