import React from "react";

export default (props) => {
  return (
    <>
      <legend
        style={{
          display: "flex",
          alignItems: "end",
          width: "100%",
          fontSize: "0.875rem",
          margin: "10px 0 10px 0",
          height: "min-content",
        }}
      >
        <b>{props.nameField}</b>
      </legend>
      <div
        style={{
          display: "flex",
          justifyContent: props.justifyContent || "space-between",
          alignItems: "center",
        }}
      >
        {props.options.map((option) => (
          <div>
            <input
              type="radio"
              disabled={!!props.readOnly}
              id={option.id}
              name={option.name}
              value={option.value}
              style={{ width: 15, height: 15, margin: "0 5px 2px 0" }}
              checked={option.conditionChecked}
              onChange={option.event}
            />
            <label
              for={option.id}
              style={{
                cursor: "pointer",
                fontWeight: "normal",
                margin: "0 20px 0 0",
              }}
            >
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </>
  );
};
