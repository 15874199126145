import React from "react";

export default (props) => (
	<div
		className={props.style && props.style.display ? "" : "row"}
		style={{
			...props.style,
			justifyContent:
				props.style && props.style.justifyContent ? props.style.justifyContent : props.alignCenter ? "center" : props.alignRight ? "right" : "left",
		}}
	>
		{props.children}
	</div>
);
