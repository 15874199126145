import axios from "axios";
import consts from "../consts";
import { setErroAPI, setSuccess } from "../common/toast/toast";
import { reset as resetForm, initialize } from "redux-form";

export function setModoTela(modo, registro = {}) {
	return {
		type: "DOCUMENTO_ADM_MODO_TELA",
		payload: {
			modo: modo,
			registro: registro,
		},
	};
}

export function setAguardando(aguardando) {
	return {
		type: "DOCUMENTO_ADM_SET_AGUARDANDO",
		payload: aguardando,
	};
}

export function initForm(registro = {}) {
	return [initialize("documentoAdmForm", registro)];
}

export function setFiltro(filtro) {
	return {
		type: "DOCUMENTO_ADM_FILTRO",
		payload: filtro,
	};
}

export function getLista() {
	return async (dispatch, getState) => {
		try {
			dispatch(setAguardando(true));
			const id_documento_tipo = getState().documentoAdm.filtro.id_documento_tipo || null;
			const resp = await axios.get(
				`${consts.API_URL}/documentoAdm?
				id_documento_tipo=${id_documento_tipo}`
			);
			dispatch({
				type: "DOCUMENTO_ADM_LISTADO",
				payload: resp,
			});
		} catch (erro) {
			setErroAPI(erro);
		} finally {
			dispatch(setAguardando(false));
		}
	};
}

export function salvar(registro) {
	return async (dispatch, getState) => {
		try {
			dispatch(setAguardando(true));
			if (!registro.id) {
				await axios.post(`${consts.API_URL}/documentoAdm`, {
					...registro,
					id_usuario_inclusao: getState().auth.usuario.id,
					id_usuario_alteracao: getState().auth.usuario.id,
				});
				setSuccess("Operação Realizada com sucesso.");
				dispatch(getLista());
				dispatch(setModoTela("lista"));
			} else {
				if (window.confirm("Deseja realmente alterar?")) {
					await axios.put(`${consts.API_URL}/documentoAdm`, {
						...registro,
						id_usuario_alteracao: getState().auth.usuario.id,
					});
					setSuccess("Operação Realizada com sucesso.");
					dispatch(getLista());
					dispatch(setModoTela("lista"));
				}
			}
		} catch (erro) {
			setErroAPI(erro);
		} finally {
			dispatch(setAguardando(false));
		}
	};
}

export function excluir(registro) {
	return async (dispatch, getState) => {
		try {
			if ("Deseja realmente excluir?") {
				dispatch(setAguardando(true));
				await axios.delete(`${consts.API_URL}/documentoAdm?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`);
				setSuccess("Operação Realizada com sucesso.");
				dispatch(getLista());
				dispatch(setModoTela("lista"));
			}
		} catch (erro) {
			setErroAPI(erro);
		} finally {
			dispatch(setAguardando(false));
		}
	};
}

export async function getListaDocumentoTipo() {
	const request = await axios.get(`${consts.API_URL}/documentoTipo/listarSelect?id_segmento=1`); //id_segmento 1 é administrativo
	return {
		type: "DOCUMENTO_ADM_TIPO_SELECT_LISTADO",
		payload: request,
	};
}
