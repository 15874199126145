import axios from "axios";
import { showTabs, selectTab } from "../common/tab/tabActions";
import consts from "../consts";
import { setErroAPI, setSuccess } from "../common/toast/toast";
import { reset as resetForm, initialize } from "redux-form";
import DateFormat from "../common/dateFormat/DateFormat";
import FormatUtils from "../common/formatUtils/FormatUtils";
import confirmDialog from "../common/dialog/confirmDialog";

export function setModoTela(modo, registro = {}) {
  return {
    type: "REDE_MODO_TELA",
    payload: {
      modo: modo,
      registro: registro,
    },
  };
}

export function setAguardando(aguardando) {
  return {
    type: "REDE_SET_AGUARDANDO",
    payload: aguardando,
  };
}

export function initForm(registro = {}) {
  return [initialize("redeForm", registro)];
}

export function setFiltro(filtro) {
  return {
    type: "REDE_FILTRO",
    payload: filtro,
  };
}

export function getLista() {
  return (dispatch, getState) => {
    dispatch({
      type: "REDE_LISTADO",
      payload: { data: [] },
    });

    //se for acesso cliente o filtro id_cliente é setado ao renderizar a tela
    //por isso passa nulo quando o filtro.id_cliente não tem nada

    axios
      .get(
        `${consts.API_URL}/rede?
			id_cliente=${
        getState().rede.filtro.id_cliente ||
        (getState().auth.usuarioLogado &&
        getState().auth.usuarioLogado.administrador
          ? null
          : 0)
      }
			&id_unidade_consumidora=${
        getState().rede.filtro.id_unidade_consumidora || null
      }`
      )
      .then((resp) => {
        dispatch({
          type: "REDE_LISTADO",
          payload: resp,
        });
      })
      .catch((ex) => {});
  };
}

export function salvar(registro) {
  return (dispatch, getState) => {
    if (!registro.id) {
      dispatch(setAguardando(true));

      axios
        .post(`${consts.API_URL}/rede`, {
          ...registro,
          id_usuario_inclusao: getState().auth.usuario.id,
        })
        .then((resp) => {
          setSuccess("Operação Realizada com sucesso.");
          dispatch(getLista());
          dispatch(setModoTela("lista"));
          dispatch(
            initForm({
              ...registro,
              id: resp.data.id,
            })
          );
          dispatch(setAguardando(false));
        })
        .catch((e) => {
          setErroAPI(e);
          dispatch(setAguardando(false));
        });
    } else {
      confirmDialog("Deseja realmente alterar?", () => {
        dispatch(setAguardando(true));

        axios
          .put(`${consts.API_URL}/rede`, {
            ...registro,
            id_usuario_alteracao: getState().auth.usuario.id,
          })
          .then((resp) => {
            setSuccess("Operação Realizada com sucesso.");
            dispatch(getLista());
            dispatch(setModoTela("lista"));
            dispatch(setAguardando(false));
          })
          .catch((e) => {
            setErroAPI(e);
            dispatch(setAguardando(false));
          });
      });
    }
  };
}

export function excluir(registro) {
  return (dispatch, getState) => {
    confirmDialog("Deseja realmente excluir?", () => {
      axios
        .delete(
          `${consts.API_URL}/rede?id=${registro.id}&id_usuario_alteracao=${
            getState().auth.usuario.id
          }`
        )
        .then((resp) => {
          setSuccess("Operação Realizada com sucesso.");
          dispatch(getLista());
          dispatch(setModoTela("lista"));
        })
        .catch((e) => {
          setErroAPI(e);
        });
    });
  };
}

export function getListaCliente() {
  const request = axios.get(`${consts.API_URL}/cliente/listarSelect`);
  return {
    type: "REDE_CLIENTE_SELECT_LISTADO",
    payload: request,
  };
}

export function getListaUnidadeConsumidora() {
  const request = axios.get(
    `${consts.API_URL}/unidadeConsumidora/listarSelect`
  );
  return {
    type: "REDE_UNIDADE_CONSUMIDORA_SELECT_LISTADO",
    payload: request,
  };
}
