const INITIAL_STATE = {
	pdf: null,
	nomePdf: "",
	pdf_link: "",
	orientation: "portrait",
	id_pdf: 0,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case "DISPLAY_PDF_CARREGAR":
			return {
                ...state,
                pdf: !action.payload.pdf ? action.payload.pdf : action.payload.pdf.includes("data:") ? action.payload.pdf : "data:application/pdf;base64," + action.payload.pdf,
                nomePdf: action.payload.nomePdf,
                orientation: action.payload.orientation,
                id_pdf: action.payload.id_pdf,
            };

		case "DISPLAY_PDF_SET_LINK":
			return { ...state, pdf_link: action.payload };

		default:
			return state;
	}
};
