import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";

import consts from "../consts";
import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";
import { setError } from "../common/toast/toast";
import arquivoAdd from "../assets/images/file_circle_plus_solid.png";
import arquivoAdicionado from "../assets/images/file_solid.png";

import { setModoTela, initForm } from "./faturaActions";
import LabelAndInputDateCalendar from "../common/form/labelAndInputDateCalendar";

class FaturaFormDataPagamento extends Component {
    state = {};

    componentDidMount() {
        let initSeletorAnexo = () => {
            const fileSelectorAnexo = document.createElement("input");
            fileSelectorAnexo.setAttribute("type", "file");
            fileSelectorAnexo.addEventListener("change", (data) => {
                let reader = new FileReader();
                let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
                if (!file) {
                    file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
                }

                if (file) {
                    if (!file.type.includes("application/pdf") && !file.type.includes("image/png") && !file.type.includes("image/jpg") && !file.type.includes("image/jpeg")) {
                        setError("Selecione apenas (.PDF, .PNG, .JPG e .JPEG)");
                        return;
                    }

                    reader.onloadend = () => {
                        let lista = (this.props.formularioValues.comprovantes_pagamento || []).map((item) => item);
                        lista.push({
                            anexo_base64: reader.result,
                            legenda: "",
                        });

                        this.props.initForm({
                            ...this.props.formularioValues,
                            comprovantes_pagamento: lista,
                        });

                        initSeletorAnexo();
                    };

                    reader.readAsDataURL(file);
                }
            });

            this.fileSelectorAnexo = fileSelectorAnexo;
        };

        initSeletorAnexo();
    }

    render() {
        let readOnly = this.props.excluir || this.props.visualizar ? "readOnly" : "";

        return (
            <>
                <ContentCard>
                    <Form event={this.props.handleSubmit}>
                        <ContentCardBody>
                            <Row>
                                <LabelAndInput readOnly={true} cols="12 12 12 12" label="Nome do Cliente" title="Nome do Cliente" value={this.props.formularioValues.dados_fatura.nome_cliente} />
                            </Row>
                            <Row>
                                <LabelAndInput
                                    readOnly={true}
                                    cols="12 12 12 12"
                                    label="Nome do Unidade Consumidora"
                                    title="Nome do Unidade Consumidora"
                                    value={this.props.formularioValues.dados_fatura.nome_unidade_consumidora}
                                />
                            </Row>
                            <Row>
                                <LabelAndInput readOnly={true} cols="12 2 2 2" label="Competência" title="Competência" value={this.props.formularioValues.dados_fatura.competencia} />
                            </Row>
                            <Row>
                                <LabelAndInputDateCalendar label="Data de Vencimento" title="Data de Vencimento" cols="12 6 4 4" readOnly={true} value={this.props.formularioValues.dados_fatura.data_vencimento} />
                            </Row>
                            <Row>
                                <Field name="data_pagamento" component={LabelAndInputDateCalendar} label="Data de Pagamento" title="Informe a data de pagamento" cols="12 6 4 4" readOnly={readOnly} />
                            </Row>
                            <Row>
                                <Grid cols="12 12 12 12">
                                    <label>Comprovantes</label>
                                </Grid>
                                {(this.props.formularioValues.comprovantes_pagamento || []).length ? (this.props.formularioValues.comprovantes_pagamento || []).map((arquivo, i) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: 4,
                                        }}
                                        key={i}
                                    >
                                        <button className="btn btn-outline-primary fileinput-button dz-clickable" disabled={arquivo.anexo ? true : false}>
                                            <img alt="" src={arquivo.anexo_base64 ? arquivoAdicionado : arquivo.anexo ? arquivoAdicionado : arquivoAdd} style={{ height: 30 }} />
                                        </button>
                                        <input
                                            className="form-control"
                                            placeholder="Legenda"
                                            style={{ marginTop: 4 }}
                                            readOnly={readOnly}
                                            type="text"
                                            value={arquivo.legenda}
                                            onChange={(event) => {
                                                this.props.initForm({
                                                    ...this.props.formularioValues,
                                                    comprovantes_pagamento: (this.props.formularioValues.comprovantes_pagamento || []).map((arquivoAux, j) => {
                                                        if (i == j) {
                                                            return {
                                                                ...arquivoAux,
                                                                legenda: event.target.value,
                                                            };
                                                        } else {
                                                            return arquivoAux;
                                                        }
                                                    }),
                                                });
                                            }}
                                        />
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                paddingRight: 0,
                                                marginTop: 4,
                                            }}
                                        >
                                            <button
                                                type="button"
                                                className="btn btn-flat btn-outline-primary"
                                                style={{ width: "48%", marginLeft: 0 }}
                                                disabled={!!arquivo.anexo_base64}
                                                onClick={async () => {
                                                    try {
                                                        const response = await fetch(`${consts.ARQUIVOS_URL}/${arquivo.anexo}`);
                                                        if (!response.ok) {
                                                            throw new Error("Erro ao baixar o arquivo");
                                                        }
                                                        const blob = await response.blob(); // Cria o arquivo em formato blob
                                                        const url = window.URL.createObjectURL(blob); // Gera uma URL para o blob
                                                        const link = document.createElement("a");
                                                        link.href = url;
                                                        link.download = arquivo.anexo; // Nome sugerido para o arquivo baixado
                                                        document.body.appendChild(link);
                                                        link.click();
                                                        document.body.removeChild(link);
                                                        window.URL.revokeObjectURL(url); // Libera memória após o uso
                                                    } catch (error) {
                                                        console.error("Erro ao baixar o arquivo:", error);
                                                    }
                                                }}
                                            >
                                                <i className="fas fa-download"></i>
                                            </button>

                                            <button
                                                type="button"
                                                className="btn btn-flat btn-outline-danger"
                                                style={{ width: "48%" }}
                                                disabled={readOnly}
                                                onClick={() => {
                                                    this.props.initForm({
                                                        ...this.props.formularioValues,
                                                        comprovantes_pagamento: this.props.formularioValues.comprovantes_pagamento.filter((item) => item != arquivo),
                                                    });
                                                }}
                                            >
                                                <i className="fas fa-trash-alt"></i>
                                            </button>
                                        </div>
                                    </div>
                                )): this.props.visualizar ? <div className="pl-2">Não há comprovantes</div> :null}
                                {!readOnly ? (
                                    <span className="btn btn-outline-primary fileinput-button dz-clickable" style={{ marginLeft: 8 }} onClick={() => this.fileSelectorAnexo.click()}>
                                        <img alt="" src={arquivoAdd} style={{ height: 30 }} />
                                    </span>
                                ) : null}
                            </Row>
                        </ContentCardBody>
                        <ContentCardFooter>
                            <Row alignCenter>
                                {this.props.visualizar ? null : (
                                    <Grid cols="6 6 4 3">
                                        {this.props.excluir ? <Button text="Excluir" submit type={"danger"} icon={"fa fa-trash"} /> : <Button text={"Salvar"} submit type={"success"} icon={"fa fa-check"} />}
                                    </Grid>
                                )}
                                <Grid cols="6 6 4 3">
                                    <Button text="Voltar" type={"warning"} icon={"fa fa-chevron-left"} event={() => this.props.setModoTela("lista")} />
                                </Grid>
                            </Row>
                        </ContentCardFooter>
                    </Form>
                </ContentCard>
            </>
        );
    }
}

FaturaFormDataPagamento = reduxForm({
    form: "faturaForm",
    destroyOnUnmount: false,
})(FaturaFormDataPagamento);
const mapStateToProps = (state) => ({
    sessao: state.auth.usuario,
    formularioValues: getFormValues("faturaForm")(state),
    registro: state.fatura.registro,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setModoTela,
            initForm,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(FaturaFormDataPagamento);
